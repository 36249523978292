import React, { useState } from "react";
import { AppAPIService } from "../../../../services/AppAPIService";
import { Spinner, Col, Row, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles/";
import { Formik } from "formik";
import { injectIntl } from "react-intl";
import { IsValidPassword } from "../../../../utils/validators/IsValidPassword";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import * as auth from "../../../../store/ducks/auth.duck";
import { connect } from "react-redux";

import "./style.scss";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: fit-content;
    margin: auto;
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    margin: 0;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: 100%;
    margin: 0;
    outline: none;

    padding: 37px 24px 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }
  }
`;

function ModalAlterPassword(props) {
  const { open, setOpen, result } = props;
  const api = AppAPIService.getInstance();
  const [loading, setLoading] = useState("");
  const { intl } = props;

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const useStyles = makeStyles(() => ({
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    message: {
      fontSize: "1rem"
    },
    spinnerBorder: {
      width: "2rem",
      height: "2rem"
    }
  }));

  const classes = useStyles();

  return (
    <StyledModal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      centered
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Modal.Body>
        <div className="container-modal">
          <span className="title">Alterar Senha</span>
          {loading ? (
            <div className={classes.modalBody}>
              <Row className="show-grid">
                <Col xs={12} md={12}>
                  <h3>Alterando senha...'</h3>
                </Col>
              </Row>
              <Row className="show-grid">
                <Spinner
                  animation="border"
                  className={classes.spinnerBorder}
                  size="xs"
                />
              </Row>
            </div>
          ) : null}
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
              newPassword: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.newPassword) {
                errors.newPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (IsValidPassword(values.newPassword) !== true) {
                errors.newPassword = "Senha não possui os requisitos mínimos:";
                IsValidPassword(values.newPassword).ERROS_FOUND.map(item => {
                  errors.newPassword +=
                    " /" +
                    intl.formatMessage({
                      id: "AUTH.VALIDATION." + item
                    });
                  return errors.newPassword;
                });
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword =
                  "Senha e confirmação de senha não conferem.";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setLoading(true);
              const reqBase = { method: "PUT", url: "/user/update-password" };
              try {
                setLoading(true);
                const response = await api.makeHttpRequest({
                  ...reqBase,
                  data: {
                    currentPassword: values.password,
                    newPassword: values.newPassword
                  }
                });

                if (response) {
                  setLoading(false);
                  setSubmitting(false);
                  resetForm({});
                  result(true, "");
                }
              } catch (error) {
                debugger;
                setLoading(false);
                setSubmitting(false);
                if (error.response && error.response.data) {
                  result(false, error.response.data);
                } else {
                  result(false, "Falha ao alterar a senha");
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                className="col-12"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <div className="form-group mb-0">
                  <TextField
                    type={showCurrentPassword ? "text" : "password"}
                    margin="normal"
                    label="Senha Atual"
                    className="input-modal-update-password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da senha"
                            onClick={() =>
                              setShowCurrentPassword(!showCurrentPassword)
                            }
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {showCurrentPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showNewPassword ? "text" : "password"}
                    margin="normal"
                    label="Nova Senha"
                    className="kt-width-full input-modal-update-password"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da senha"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    margin="normal"
                    label="Confirme a Senha"
                    className="kt-width-full input-modal-update-password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da senha"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between pt-4 gap-3">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="cancel"
                  >
                    Cancelar
                  </button>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit primary"
                  >
                    Alterar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </StyledModal>
  );
}

export default injectIntl(connect(null, auth.actions)(ModalAlterPassword));
