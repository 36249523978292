import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { connect } from "react-redux";
import { AppAPIService } from "../../../../services/AppAPIService";
import { FaMoneyCheck } from "react-icons/fa";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { FilterContainer, SelectContainer } from "./styles";
import ReactSelect from "react-select";
import Swal from "sweetalert2";

const options = [
  { value: "active", label: "ATIVO" },
  { value: "refused", label: "RECUSADO" },
  { value: "paused", label: "PAUSADO" },
  { value: "canceled", label: "CANCELADO" },
  { value: "", label: "TODOS" }
];

const initialState = {
  RecurrencePayments: [],
  profile: "",
  reload: false,
  loading: false,
  selectedOptions: options.at(-1)
};

class RecurrencePaymentListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleLinkCampaign = this.handleLinkCampaign.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setRecurrencePayments = this.setRecurrencePayments.bind(this);
    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
  }

  componentDidMount = async () => {
    const profile = this.props.user.role?.name;
    this.setState({ profile });
  };

  setRecurrencePayments = RecurrencePayments => {
    this.setState({ RecurrencePayments });
  };

  handleLinkCampaign = obj => {
    this.props.router.history.push(
      `/admin/campanhas/${obj.campaign.idCampaign}/editar/`
    );
  };

  handleStatusRecurrenceDonation(status) {
    switch (status) {
      case 0: {
        return "PENDENTE";
      }
      case 1: {
        return "PAGO";
      }
      case 2: {
        return "FALHOU";
      }
      default:
        return "-";
    }
  }

  handleStatusRecurrence(status) {
    switch (status) {
      case 0: {
        return "Criado";
      }
      case 1: {
        return "Ativo";
      }
      case 2: {
        return "Recusado";
      }
      case 3: {
        return "Pausado";
      }
      case 4: {
        return "Cancelado";
      }
      default:
        return "-";
    }
  }

  handleConfirmChangeStatus = async function (id, action) {
    this.setState({ loading: true });

    let successMessage = "";
    let methodHttp = "";
    let urlHttp = "";

    switch (action) {
      case "resume":
        successMessage = "Assinatura reativada com sucesso!";
        methodHttp = "PUT";
        urlHttp = `/donation/recurrence/resume/${id}`;
        break;
      case "pause":
        successMessage = "Assinatura pausada com sucesso!";
        methodHttp = "PUT";
        urlHttp = `/donation/recurrence/pause/${id}`;
        break;
      default:
        break;
    }    


    try {
      await this.api.makeHttpRequest({
        method: methodHttp,
        url: urlHttp
      });

      Swal.fire({
        title: "Sucesso!",
        text: successMessage,
        icon: "success",
        cancelButtonText: "Sair"
      }).then(() => {
        this.setState({ reload: !this.state.reload });
      });
    } catch (e) {
      console.error(e);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao modificar assinatura",
        "error"
      );
    }

    
    this.setState({ loading: false });
  };

  formatRow = r => {
    let crudActions = ["edit"];

    if (r?.status === 1) {
      // Status ativo
      crudActions.push("pause-recurrence");
    } else if (r?.status === 3) {
      // status pausado
      // despausar a recorrencia
      crudActions.push("resume-recurrence");
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idRecurrence}>
        <StyledTableCell scope="row">
          <CrudActions
            actions={crudActions}
            module="assinaturas"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            id={r.idRecurrence}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row">{r?.campaign?.name}</StyledTableCell>
        <StyledTableCell scope="row">{r?.campaign?.accessKey}</StyledTableCell>
        <StyledTableCell scope="row">
          {this.handleStatusRecurrence(r.status)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">{r?.user?.email}</StyledTableCell>
        <StyledTableCell scope="row">
          {r?.creationDate ? Moment(r.creationDate).format("DD/MM/YYYY") : "-"}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {r?.lastPaymentAttempt?.billingDate
            ? Moment(r.lastPaymentAttempt.billingDate).format("DD/MM/YYYY")
            : "-"}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {this.handleStatusRecurrenceDonation(r?.lastPaymentAttempt?.status)}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  setSelectedOptions = e => {
    this.setState({
      selectedOptions: e,
      reload: !this.state.reload
    });
  };

  render() {
    window.setPageTitle("Listagem de Assinaturas - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "Transaction.CampaignName", label: "Campanha" },
      { column: "Transaction.CampaignHash", label: "Código" },
      { column: "Transaction.Status", label: "Status" },
      { column: "Transaction.Value", label: "Valor" },
      { column: "Transaction.User", label: "Doador" },
      { column: "Transaction.Starts", label: "Início" },
      {
        column: "Transaction.ActualBillingDate",
        label: "Última Cobrança"
      },
      { column: "Transaction.LastIsWithSuccess", label: "Status Cobrança" }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Assinaturas"
          icon={<FaMoneyCheck color="#4d4d4d" size="26px" />}
          endpoint="donation/recurrence/paged"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.RecurrencePayments}
          setRows={this.setRecurrencePayments}
          reload={this.state.reload}
          placeholderSearch="Busque por campanha, nome ou e-mail doador"
          width={this.props.width}
          hiddenReload
          paramsExtra={{
            status:
              this.state.selectedOptions.value &&
              this.state.selectedOptions.value.toUpperCase()
          }}
          filtersSection={
            <FilterContainer>
              <SelectContainer>
                <ReactSelect
                  options={options}
                  value={this.state.selectedOptions}
                  onChange={this.setSelectedOptions}
                  placeholder="Selecione um status"
                />
              </SelectContainer>
            </FilterContainer>
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(RecurrencePaymentListing);
