import styled from "styled-components";

export const AdminButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #343877ee;
  background: ${({ variant }) =>
    variant === "filled" ? "#343877ee" : "transparent"};

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ variant }) => (variant === "filled" ? "#fff" : "#343877")};

  box-shadow: ${({ variant }) =>
    variant === "filled"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  svg {
    color: #fff;
    font-size: 18px;
    margin-right: 12px;
  }

  ${({ variant }) =>
    variant === "filled" &&
    `
    &:not(:disabled):hover {
      color: #fff;
      background: #343877;
      border-color: #343877;
    }
  `}
`;
