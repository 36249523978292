import React from "react";
import { Link } from "react-router-dom";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { AppAPIService } from "../../../../services/AppAPIService";
import { AiOutlineFileDone } from "react-icons/ai";
// import { IoIosAdd } from "react-icons/io";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";

const initialState = {
  campaigns: [],
  profile: "",
  reload: false,
  loading: false
};

class CampaignListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setCampaigns = this.setCampaigns.bind(this);
  }

  componentDidMount = () => {
    const profile = this.props.user.role?.name;
    this.setState({ profile });
  };

  handleConfirmChangeStatus = async function(id) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/campaign/toggle/${id}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao alterar status.",
        "error"
      );
    }
  };

  setCampaigns = campaigns => {
    this.setState({ campaigns });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];

    if (r.status === "CREATED" || r.status === "ANALYZING") {
      crudActions = ["edit"];
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idCampaign}>
        <StyledTableCell scope="row">
          <CrudActions
            actions={crudActions}
            module="campanhas"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            id={r.idCampaign}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row">#{r.accessKey}</StyledTableCell>
        <StyledTableCell scope="row">
          <Link className="link" to={`/admin/usuarios/${r.idOwner}/editar`}>
            {r.owner.name}
          </Link>
        </StyledTableCell>
        <StyledTableCell scope="row">{r.name}</StyledTableCell>
        <StyledTableCell scope="row">
          {r.category && r.category?.name}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {r.hasGoal
            ? new Intl.NumberFormat("pt-br", {
                style: "currency",
                currency: "BRL"
              }).format(r.goal)
            : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.achieved)}
        </StyledTableCell>
        <StyledTableCell scope="row">{r.status}</StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">
            {r.subaccount && r.subaccount.status}
          </span>
        </StyledTableCell>
        <StyledTableCell>
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Campanha - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "AccessKey", label: "Código" },
      { column: "Owner.Name", label: "Criador" },
      { column: "Name", label: "Nome" },
      { column: "Category.Name", label: "Categoria" },
      { column: "Goal", label: "Meta" },
      { column: "Achieved", label: "Recebido" },
      { column: "Status", label: "Status" },
      { label: "Subconta" },
      { column: "ModificationDate", label: "Data de Modificação" }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Campanhas"
          icon={<AiOutlineFileDone color="#4d4d4d" size="26px" />}
          endpoint="/campaign"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.campaigns}
          setRows={this.setCampaigns}
          reload={this.state.reload}
          placeholderSearch="Busque por nome, descrição, meta ou recebimento"
          width={this.props.width}
          hiddenReload
          // buttons={
          //   this.state.profile === "ADMIN" && [
          //     {
          //       label: "Adicionar",
          //       onClick: null,
          //       icone: <IoIosAdd color="#fff" size="25px" />,
          //       path: "/admin/campanhas/adicionar"
          //     }
          //   ]
          // }
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(CampaignListing);
