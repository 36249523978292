import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles/";
import Icon from "@material-ui/core/Icon/";
import IconButton from "@material-ui/core/IconButton/";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import ConfirmChangeStatusDialog from "./ConfirmChangeStatusDialog";
import toggleOnIcon from "../../assets/icons/icon-toggle-on.svg";
import toggleOffIcon from "../../assets/icons/icon-toggle-off.svg";
import { Refresh, Backspace, PictureAsPdf } from "@material-ui/icons";
import { BiLinkExternal } from "react-icons/bi";
import { GiPauseButton } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import { MdMoneyOff } from "react-icons/md";
import {
  DeleteIcon,
  EditIcon,
  MailIcon,
  PlayIcon,
  StopIcon
} from "../../components/IconsSVG";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 2px",
    "& svg": {
      fill: "#807e80"
    },
    "&:hover": {
      "& svg": {
        fill: "#343877"
      }
    }
  },
  div: {
    display: "flex",
    flexDirection: "row"
  }
}));

export default function CrudActions({
  actions,
  onConfirmDelete,
  onConfirmClear,
  onConfirmRevert,
  onConfirmChangeStatus,
  module,
  id,
  obj,
  onLink,
  onViewDetails,
  onViewCertified,
  onConfirmResendEmail,
  onConfirmPlay,
  onConfirmStop,
  onConfirmRefund,
  handleEdit,
  pageOrigin
}) {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmClear, setConfirmClear] = React.useState(false);
  const [confirmRevert, setConfirmRevert] = React.useState(false);
  const [confirmChangeStatus, setConfirmChangeStatus] = React.useState(false);
  const [confirmResend, setConfirmResend] = React.useState(false);
  const [confirmPlay, setConfirmPlay] = React.useState(false);
  const [confirmRefund, setConfirmRefund] = React.useState(false);

  function toggleConfirmDialog(state) {
    setConfirmDelete(state);
  }

  function toggleConfirmRevertDialog(state) {
    setConfirmRevert(state);
  }

  function toggleConfirmClearDialog(state) {
    setConfirmClear(state);
  }

  function toggleChangeStatusDialog(state) {
    setConfirmChangeStatus(state);
  }

  function togglePlayDialog(state) {
    setConfirmPlay(state);
  }

  function toggleResendDialog(state) {
    setConfirmResend(state);
  }

  function toggleRefundDialog(state) {
    setConfirmRefund(state);
  }

  function handleConfirmDelete(id) {
    toggleConfirmDialog(false);
    onConfirmDelete(id);
  }

  function handleConfirmClear(id) {
    toggleConfirmClearDialog(false);
    onConfirmClear(id);
  }

  function handleConfirmRevert(id) {
    toggleConfirmRevertDialog(false);
    onConfirmRevert(id);
  }

  function handleChangeStatus(id, action = '') {
    toggleChangeStatusDialog(false);
    onConfirmChangeStatus(id, action);
  }

  function handleRefund(obj) {
    toggleRefundDialog(false);
    onConfirmRefund(obj);
  }

  function handlePlay(obj) {
    togglePlayDialog(false);
    onConfirmPlay(obj);
  }

  function handleViewDetails(obj) {
    onViewDetails(obj);
  }

  function handleEditObj(obj) {
    handleEdit(obj);
  }

  function handleViewCertified(obj) {
    onViewCertified(obj);
  }

  function handleLink(obj) {
    onLink(obj);
  }

  function handleResendMail(obj) {
    toggleResendDialog(false);
    onConfirmResendEmail(obj);
  }

  return (
    <div className={classes.div}>
      {actions && actions.includes("toggle") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleChangeStatus(id)}
            message={`Deseja ${obj && !obj.removalDate ? "inativar" : "ativar"
              } o registro selecionado?`}
            title="Confirmação"
          />

          {obj && !obj.removalDate ? (
            <IconButton
              size="small"
              color="primary"
              className={classes.button}
              title="Inativar"
              onClick={() => toggleChangeStatusDialog(true)}
            >
              <img src={toggleOnIcon} alt="Inativar" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              className={classes.button}
              title="Ativar"
              onClick={() => toggleChangeStatusDialog(true)}
            >
              <img src={toggleOffIcon} alt="Ativar" />
            </IconButton>
          )}
        </>
      )}

      {actions && actions.includes("edit") && (
        <Link
          to={`/admin/${module}/${id}/editar${pageOrigin ? "?origin=" + pageOrigin : ""
            }`}
        >
          <IconButton
            size="small"
            className={classes.button}
            title="Editar registro"
          >
            <EditIcon />
          </IconButton>
        </Link>
      )}

      {actions && actions.includes("resume-recurrence") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleChangeStatus(id, "resume")}
            message={`Deseja retomar assinatura atual?`}
            title="Recorrência"
          />

          <IconButton
            size="small"
            color="primary"
            className={classes.button}
            title="Retomar assinatura"
            onClick={() => toggleChangeStatusDialog(true)}
          >
            <FaPlay />
          </IconButton>
        </>
      )}

      {actions && actions.includes("pause-recurrence") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmChangeStatus}
            closeDialog={() => setConfirmChangeStatus(false)}
            confirm={() => handleChangeStatus(id, "pause")}
            message={`Deseja pausar a assinatura atual?`}
            title="Recorrência"
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Pausar assinatura"
            onClick={() => toggleChangeStatusDialog(true)}
          >
            <GiPauseButton style={{ fill: "#4e4b98" }} />
          </IconButton>
        </>
      )}

      {actions && actions.includes("revert") && (
        <>
          <ConfirmDeleteDialog
            open={confirmRevert}
            title="Confirmação"
            message="Deseja realmente reverter a linha de registro?"
            closeDialog={() => toggleConfirmRevertDialog(false)}
            confirm={() => handleConfirmRevert(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Reverter registro original da linha"
            onClick={() => toggleConfirmRevertDialog(true)}
          >
            <Refresh fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("clear") && (
        <>
          <ConfirmDeleteDialog
            open={confirmClear}
            title="Confirmação"
            message="Deseja realmente limpar a linha de registro?"
            closeDialog={() => toggleConfirmClearDialog(false)}
            confirm={() => handleConfirmClear(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Limpar registros da linha"
            onClick={() => toggleConfirmClearDialog(true)}
          >
            <Backspace fontSize="small" />
          </IconButton>
        </>
      )}

      {actions && actions.includes("play") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmPlay}
            confirm={() => handlePlay(obj)}
            closeDialog={() => togglePlayDialog(false)}
            message="Deseja iniciar um novo timer, contendo as mesmas informações de Cliente, Projeto, Atividade e Demanda?"
            hideTitle
          />
          <IconButton
            color="primary"
            size="small"
            title="Iniciar timer"
            className={classes.button}
            onClick={() => togglePlayDialog(true)}
          >
            <PlayIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("stop") && (
        <IconButton
          color="primary"
          size="small"
          title="Iniciar timer"
          className={classes.button}
          onClick={() => onConfirmStop(obj)}
        >
          <StopIcon />
        </IconButton>
      )}

      {actions && actions.includes("edit-form") && (
        <IconButton
          onClick={() => handleEditObj(obj)}
          size="small"
          className={classes.button}
          title="Editar registro"
        >
          <EditIcon />
        </IconButton>
      )}

      {actions && actions.includes("delete") && (
        <>
          <ConfirmDeleteDialog
            open={confirmDelete}
            closeDialog={() => toggleConfirmDialog(false)}
            confirm={() => handleConfirmDelete(id)}
          />

          <IconButton
            size="small"
            className={classes.button}
            title="Excluir registro"
            onClick={() => toggleConfirmDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("resend") && (
        <>
          <ConfirmChangeStatusDialog
            open={confirmResend}
            closeDialog={() => setConfirmResend(false)}
            confirm={() => handleResendMail(obj)}
            message={`Deseja reenviar um email ${obj.profileStatus === 3 ? "com a senha temporária" : "de ativação"
              } para o usuário selcionado?`}
            title="Confirmação"
          />

          <IconButton
            size="small"
            color="inherit"
            className={classes.button}
            title="Reenviar email de confirmação"
            onClick={() => toggleResendDialog(true)}
          >
            <MailIcon />
          </IconButton>
        </>
      )}

      {actions && actions.includes("show") && (
        <IconButton
          // color='primary'
          onClick={() => handleViewDetails(obj)}
          size="small"
          className={classes.button}
          title="Visualizar detalhes"
        >
          <Icon fontSize="small">visibility</Icon>
        </IconButton>
      )}

      {actions && actions.includes("certified") && (
        <IconButton
          onClick={() => handleViewCertified(obj)}
          size="small"
          className={classes.button}
          title="Baixar certificado em PDF"
        >
          <PictureAsPdf fontSize="small" />
        </IconButton>
      )}

      {actions && actions.includes("link-campaign") && (
        <IconButton
          onClick={() => handleLink(obj)}
          size="small"
          className={classes.button}
          title="Ir para campanha"
        >
          <BiLinkExternal size="20px" />
        </IconButton>
      )}

      {actions && actions.includes("refund") && (
        <>
          <ConfirmDeleteDialog
            open={confirmRefund}
            title="Confirmação"
            message="Deseja realmente realizar o estorno desta doação?"
            closeDialog={() => toggleRefundDialog(false)}
            confirm={() => handleRefund(obj)}
          />

          <IconButton
            onClick={() => toggleRefundDialog(true)}
            size="small"
            className={classes.button}
            title="Estornar doação"
          >
            <MdMoneyOff size="20px" />
          </IconButton>
        </>
      )}
    </div>
  );
}
