import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import queryString from "querystring";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import Loading from "../home/components/Loading";
//import * as routerHelpers from "../../router/RouterHelpers";
import { bindActionCreators } from "redux";
import ForgotPassword from "./ForgotPassword";
import ActivateAccount from "./ActivateAccount";
import ForceChangePassword from "./ForceChangePassword";

function Login(props) {
  const { intl, history } = props;

  const [email, setEmail] = useState("");
  const [idUser, setIdUser] = useState("");

  const [forgotModal, setForgotModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [forcePswChangeModal, setForcePswChangeModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error] = useState(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const user = useSelector(state => state?.auth);

  useEffect(() => {
    if (!!user?.user) {
      history.push({
        pathname: "/admin/usuarios"
      });
    }
  }, [user.user, history]);

  const enableLoading = isLogin => {
    setIsLoading(true);
    if (isLogin) {
      setLoading(true);
      setLoadingButtonStyle({ paddingRight: "3.5rem" });
    }
  };

  const disableLoading = () => {
    setLoading(false);
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLogpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

  const handleActivateAccount = email => {
    setEmail(email);
    setActivateModal(true);
  };

  const handleForceChangePassword = idUser => {
    setIdUser(idUser);
    setForcePswChangeModal(true);
  };

  window.setPageTitle("Login");

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading(true);
              login(values.email, values.password)
                .then(authData => {
                  disableLoading();
                  props.login(authData);
                })
                .catch(e => {
                  console.log(e?.response?.status)
                  if (e?.response?.status === 403) {
                    handleActivateAccount(values.email);
                    disableLoading();
                    setSubmitting(false);
                    return;
                  }

                  if (e?.response?.status === 406) {
                    handleForceChangePassword(e?.response?.data?.idUser);
                    disableLoading();
                    setSubmitting(false);
                    return;
                  }

                  disableLoading();
                  setSubmitting(false);
                  setStatus(
                    e?.response?.data && typeof e?.response?.data === "string"
                      ? e.response.data
                      : intl.formatMessage({
                          id: "AUTH.VALIDATION.INVALID_LOGIN"
                        })
                  );
                });

              setSubmitting(false);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {(status || isLogpired()) && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">
                      {status ||
                        "Sua sessão expirou, faça login para continuar"}
                    </div>
                  </div>
                )}

                {error && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{error}</div>
                  </div>
                )}

                <div className="form-group">
                  <TextField
                    type="email"
                    label="E-mail"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Senha"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>
                <div className="kt-login__actions mb-0 pb-0 ">
                  <li
                    id="kt_login_signin_submit"
                    type="button"
                    onClick={() => setForgotModal(true)}
                    className="justify-content-start forgot-password"
                  >
                    Esqueci minha senha
                  </li>
                  <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn btn-elevate kt-login__btn-primary justify-content-end ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Entrar
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ActivateAccount
        email={email}
        open={activateModal}
        setOpen={setActivateModal}
      />
      <ForgotPassword open={forgotModal} setOpen={setForgotModal} />
      <ForceChangePassword
        idUser={idUser}
        open={forcePswChangeModal}
        setOpen={setForcePswChangeModal}
      />
    </>
  );
}

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      login: auth.actions.login
    },
    dispatch
  );

export default injectIntl(connect(null, mapActionsToProps)(Login));
