/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import AdminPage from "../pages/admin/AdminPage";
// import HomePage from "../pages/home/Home";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import CreateNewPassword from "../pages/auth/CreateNewPassword";
import { PrivacyPolicyPage } from "../pages/TermsAndPolicy/PrivacyPolicyPage";
import { TermsConditionsPage } from "../pages/TermsAndPolicy/TermsConditionsPage";

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null && auth.user.role.name === "ADMIN",
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Redirect exact from="/" to="/auth/login" />

        <Route path="/auth" component={AuthPage} />
        <Route path="/auth/login" component={AuthPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/terms" component={TermsConditionsPage} />

        <Route path="/cadastrar-senha" component={CreateNewPassword} />

        {/* <Route exact path="/" component={HomePage} /> */}

        {isAuthorized ? (
          <Route path="/admin">
            <Layout>
              <AdminPage userLastLocation={userLastLocation} />
            </Layout>
          </Route>
        ) : (
          <Redirect from="/admin" to="/logout" />
        )}
      </Switch>
    </LayoutContextProvider>
  );
});
