import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import Swal from "sweetalert2";
import { AppAPIService } from "../../../../services/AppAPIService";
import { AiOutlineTeam } from "react-icons/ai";
import { IoIosAdd } from "react-icons/io";
import CrudActions from "../../../../partials/datatable/CrudActions";
import AdminDropdown from "../../../../partials/content/CustomDropdowns/AdminDropdown";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { connect } from "react-redux";

const initialState = {
  users: [],
  auth: null,
  profile: "",
  reload: false,
  loading: false
};

class UsersListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this);
    this.handleSendConfirmationMail = this.handleSendConfirmationMail.bind(
      this
    );
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setUsers = this.setUsers.bind(this);
  }

  componentDidMount = async () => {
    const profile = this.props.user.role?.name;
    this.setState({ profile });
  };

  handleConfirmDelete = async function(id) {
    try {
      this.setState({ loading: true });
      await this.api.makeHttpRequest({
        method: "DELETE",
        url: `/user/${id}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Usuário deletado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao deletar usuário.", "error");
    }

    this.setState({ loading: false });
  };

  handleConfirmChangeStatus = async function(id) {
    try {
      await this.api.makeHttpRequest({
        method: "PUT",
        url: `/user/toggle/${id}`
      });

      this.setState({ reload: !this.state.reload });

      Swal.fire({
        title: "Sucesso!",
        text: "Status alterado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire("Erro!", "Erro ao alterar status.", "error");
    }
  };

  handleSendConfirmationMail = async function(obj) {
    const req =
      obj.profileStatus === 3
        ? {
            url: "/user/resend-temporary-password",
            data: { idUser: obj.idUser }
          }
        : {
            url: "/user/resend-mail",
            data: { email: obj.email }
          };

    try {
      await this.api.makeHttpRequest({
        method: "POST",
        ...req
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Email de confirmação foi enviado com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data && typeof e?.response?.data === "string"
          ? e.response.data
          : "Ocorreu um erro ao reenviar o email.",
        "error"
      );
    }
  };

  setUsers = users => {
    this.setState({ users });
  };

  formatRow = r => {
    let crudActions = ["toggle", "edit"];
    const isAdmin = this.props.user && this.props.user.role?.name === "ADMIN";

    if (isAdmin) {
      if (r.profileStatus === 0) {
        crudActions = ["toggle", "edit" /* , "delete" */, "resend"];
      }

      if (r.profileStatus === 1) {
        crudActions = ["toggle", "edit" /* , "delete" */, "resend"];
      }

      if (r.profileStatus === 5) {
        crudActions = ["edit"];
      }
    } else {
      if (r.idUser === this.props.user.idUser) {
        crudActions = ["edit"];

        if (r.profileStatus === 0 || r.profileStatus === 1) {
          crudActions = ["resend"];
        }
      }
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idUser}>
        <StyledTableCell scope="row">
          <CrudActions
            actions={crudActions}
            module="usuarios"
            onConfirmChangeStatus={this.handleConfirmChangeStatus}
            onConfirmResendEmail={this.handleSendConfirmationMail}
            onConfirmDelete={this.handleConfirmDelete}
            id={r.idUser}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row">{r.name}</StyledTableCell>
        <StyledTableCell scope="row">{r.email}</StyledTableCell>
        <StyledTableCell scope="row">{r.role.name}</StyledTableCell>
        <StyledTableCell scope="row">
          {r.type === 1 ? "PF" : r.type === 2 ? "PJ" : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row">{r.profileStatusName}</StyledTableCell>
        <StyledTableCell scope="row">
          {r.subaccount ? r.subaccount : "--"}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {r.costumer ? r.costumer : "--"}
        </StyledTableCell>
        <StyledTableCell>
          {!!r.modificationDate &&
            Moment(r.modificationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Usuários - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "Name", label: "Nome" },
      { column: "Email", label: "Email" },
      { column: "IdType", label: "Tipo" },
      { column: "IdUserRole", label: "Perfil" },
      { column: "ProfileStatus", label: "Status" },
      { label: "Subconta" },
      { label: "Cliente" },
      { column: "ModificationDate", label: "Data de Modificação" }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Usuários"
          icon={<AiOutlineTeam color="#4d4d4d" size="26px" />}
          endpoint="/user"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.users}
          setRows={this.setUsers}
          reload={this.state.reload}
          placeholderSearch="Busque por nome, projeto ou cliente"
          mobile={AdminDropdown}
          width={this.props.width}
          hiddenReload
          buttons={
            this.state.profile === "ADMIN" && [
              {
                label: "Adicionar",
                onClick: null,
                icone: <IoIosAdd color="#fff" size="25px" />,
                path: "/admin/usuarios/adicionar"
              }
            ]
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(UsersListing);
