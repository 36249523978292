import { Card, Modal } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p.description {
    display: flex;

    text-align: center;
    font-family: "Quicksand", sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: #000000;

    width: 80%;

    margin: 0 auto 24px;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    > div {
      margin: 0 auto;
    }
  }
`;

export const DocumentCard = styled(Card)`
  width: 220px;
  border-radius: 4px !important;

  .card-img-top {
    width: 100%;
    height: 160px;
    object-fit: cover;
    background: #161a20;

    border-radius: 4px 4px 0 0;
  }

  .card-body {
    padding-top: 16px !important;

    p.card-text {
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      /* text-transform: lowercase; */
    }
  }
`;

export const DocumentModal = styled(Modal)`
  .modal-body {
    width: 100%;
  }

  .modal-image {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
`;

export const OpenButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #343877ee;
  background: #343877ee;

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);

  :hover {
    color: #fff;
    background: #343877;
    border-color: #343877;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  > button {
    max-width: 120px;

    &:first-of-type {
      margin-right: 8px;
    }
  }
`;
