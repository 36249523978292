import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FiSettings } from "react-icons/fi";

export const AdminTitle = props => {
  const useStyles = makeStyles(() => ({
    headerWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    header: {
      display: "flex",
      color: "#4d4d4d"
    },
    titleIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 5,
      marginRight: 8
    },
    title: {
      fontFamily: "Quicksand",
      fontWeight: "500",
      fontSize: 20,
      lineHeight: "150%",
      marginBottom: 0
    },
    line: {
      display: "flex",
      height: 0.5,
      width: "100%",
      background: "#4d4d4d",
      opacity: "0.6"
    }
  }));

  const classes = useStyles();
  const Mobile = props.mobile;

  return (
    <header className={classes.headerWrapper}>
      <div className={classes.header}>
        <div className={classes.titleIcon}>
          {props.icon ? props.icon : <FiSettings size="22px" color="#4d4d4d" />}
        </div>
        <h1 className={classes.title}>{props.title}</h1>
      </div>
      {props.disableHeader ? null : props.width >= 768 ||
        !props.mobile ? null : (
        <div>
          <Mobile
            actions={props.actions || null}
            width={props.width}
            buttons={props.generateMobileButtons}
            refreshAction={() => props.refreshAction}
          />
        </div>
      )}
      <div className={classes.line} />
    </header>
  );
};
