/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";

const AdminDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <button
      ref={ref}
      id="kt_dashboard_daterangepicker"
      type="button"
      onClick={e => {
        e.preventDefault();
        props.onClick(e);
      }}
      className="btn btn-clean btn-sm btn-icon btn-icon-lg"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i className="flaticon-more-1"></i>
    </button>
  );
});

export default class AdminDropdown extends React.Component {
  render() {
    const Buttons = this.props.buttons;
    return (
      <Dropdown className="kt-portlet__head-toolbar" drop="down" alignRight>
        <Dropdown.Toggle
          as={AdminDropdownToggle}
          id="dropdown-toggle-top"
        >
          <i className="flaticon-more-1" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right">
          <ul className="kt-nav">

            <Buttons />

          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
