import styled from "styled-components";

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: max-content;

  .nav-tabs {
    display: flex;
    margin: 0;
    border: none;
    border-radius: 4px 4px 0px 0px;
    gap: 6px;

    a.nav-item {
      padding: 14px 40px;
      background: #ffffff;

      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px 4px 0px 0px;
      border: none;

      &.active {
        font-family: "Poppins", sans-serif;
      }

      &:not(.active) {
        background: #f3f2f2;
      }
    }
  }

  .tab-content {
    background: #ffffff;

    .tab-pane.active {
      background: #ffffff;
      transition: none;
    }

    .card {
      display: flex;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 4px 4px;
      border: none;

      .card-body {
        padding: 24px;
        box-shadow: none;

        .user-list {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }
`;
