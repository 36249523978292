import styled from 'styled-components';
import windowSize from '../../../../constants/WindowSize';

export const Container =styled.div`
  display: flex;
   justify-content: space-between;
  justify-content: ${({width}) => width >= windowSize.SM ? 'flex-end' : 'space-between'};
  align-items: center;
  margin:  ${({width}) => width >= windowSize.SM ? 0 : '0px 20px'};
`;
