import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #343877;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

class FormActions extends React.Component {
  render() {
    return (
      <div className="text-right" style={{ marginTop: "24px" }}>
        {!this.props.hideCancel ? (
          <Link to={`/admin/${this.props.module}`}>
            <Button variant="secondary" color="#343877" type="button">
              Cancelar
            </Button>
          </Link>
        ) : null}

        {this.props.backButton && this.props.hideCancel ? (
          <Button
            variant="secondary"
            color="#343877"
            type="button"
            onClick={this.props.backButton}
          >
            Voltar
          </Button>
        ) : null}

        {this.props.hideReload ? null : (
          <Button
            variant="secondary"
            color="#343877"
            onClick={this.props.onCleanForm}
          >
            {!this.props.isEdit ? "Limpar" : "Reverter"}
          </Button>
        )}

        <Button
          variant="primary"
          type="submit"
          color="#343877"
          onClick={this.props.onSubmit}
          disabled={!this.props.formIsValid}
          title={
            this.props.formIsValid ? "Salvar dados" : "Verifique os campos"
          }
        >
          <span className="text-light">
            {this.props.submitText ?? "Salvar"}
          </span>
        </Button>
      </div>
    );
  }
}

export default FormActions;
