import React, { Suspense, useEffect, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import ErrorPage from "../errors/ErrorPage";
import AdminRoute from "./components/AdminRoute";
import {
  UsersListing,
  UserForm,
  CampaignListing,
  CampaignForm,
  DonationListing,
  CashoutListing,
  CashoutForm,
  CategoriesPage,
  RecurrencePaymentListing,
  RecurrencePaymentForm
} from "./pages";

export default function AdminPage() {
  const [width, setWidth] = useState(window.innerWidth);

  const resizeEvent = () => {
    if (window.innerWidth !== width) {
      setWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeEvent);

    return () => window.removeEventListener("resize", resizeEvent);
  });

  return (
    <Suspense>
      <Switch>
        <Redirect exact from="/admin" to="/admin/usuarios" />

        {/* USUÁRIO */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/usuarios"
          component={() => <UsersListing width={width} />}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/usuarios/adicionar"
          component={UserForm}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/usuarios/:id/editar"
          component={UserForm}
        />

        {/* CAMPANHA */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/campanhas"
          component={() => <CampaignListing width={width} />}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/campanhas/adicionar"
          component={CampaignForm}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/campanhas/:id/editar"
          component={CampaignForm}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/categorias"
          component={CategoriesPage}
        />

        {/* DOAÇÔES */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/doacoes"
          component={router => (
            <DonationListing width={width} router={router} />
          )}
        />

        {/* SAQUES */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/saques"
          component={router => <CashoutListing width={width} router={router} />}
        />
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/saques/requisitar"
          component={CashoutForm}
        />

        {/* ASSINATURAS */}
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/assinaturas"
          component={router => (
            <RecurrencePaymentListing width={width} router={router} />
          )}
        />

        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/assinaturas/:id/editar"
          component={RecurrencePaymentForm}
        />

        {/* ERROR */}
        <AdminRoute
          permissionLevel="ANY"
          path="/admin/*"
          component={ErrorPage}
        />
        <AdminRoute
          permissionLevel="ANY"
          exact
          path="/admin/404"
          component={ErrorPage}
        />
      </Switch>
    </Suspense>
  );
}
