import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 48px 0px 140px;
  max-width: 1170px;
  margin: 90px auto 0;

  * {
    font-family: "Poppins", sans-serif;
  }

  .heading__title {
    letter-spacing: -0.07em;
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: 300;
    color: #343877;
  }

  p {
    color: #777;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: -.05em;
    color: #343877;
  }

  ul.letters {
    li {
      list-style: lower-latin;
    }
  }

  ul.numeric {
    li {
      list-style: numeric;
    }
  }

  table {
    font-family: "Poppins", sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 45px 0;
    box-shadow: 0 1px 1px #00000024, 0 2px 1px #0000001f, 0 1px 3px #00000033;

    td,
    th {
      font-family: "Poppins";
      text-align: left;
      padding: 8px;
      border: 1px solid #807e80;
    }

    th {
      font-size: 14px;
      font-weight: 600;
      color: #333;
    }

    td {
      font-weight: 400;
      font-size: 12px;
      color: #333;

      p {
        font-size: 14px;

        a {
          text-decoration: underline;
        }
      }
    }
  }
`;
