import React from 'react';
import { TableRow, TableCell, Icon, IconButton } from '@material-ui/core';
import { ProgressBar } from 'react-bootstrap';
import queryString from 'querystring';
import Datatable from '../../../../partials/datatable/Datatable';
import { AppAPIService } from '../../../../services/AppAPIService';
import UploadSpreadsheetModal from './UploadSpreadsheetModal';
import BulkImportTypes from '../../../../enums/BulkImportTypes';
import SpreadsheetRowErrorsModal from './SpreadsheetRowErrorsModal';
import AdminDropdown from '../../../../partials/content/CustomDropdowns/AdminDropdown';

import store from '../../../../store/store';

export default class BulkImportsListing extends React.Component {
  constructor(props) {
    super(props);

    const { auth } = store.getState();

    this.state = {
      bulkImports: [],
      type: this.getType(),
      reload: false,
      uploadModalIsOpen: false,
      showErrorsOf: null,
      profile: auth.user.perfil.nome
    };

    this.api = AppAPIService.getInstance();
    this.setBulkImports = this.setBulkImports.bind(this);
    this.toggleUploadModal = this.toggleUploadModal.bind(this);
    this.closeErrorsModal = this.closeErrorsModal.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
  }

  toggleUploadModal() {
    this.setState({ uploadModalIsOpen: !this.state.uploadModalIsOpen });
  }

  closeErrorsModal() {
    this.setState({ showErrorsOf: null });
  }

  setBulkImports(bulkImports) {
    this.setState({ bulkImports });
  }

  componentDidUpdate() {
    if (this.getType() && this.state.type !== this.getType()) {
      this.setState({ type: this.getType(), reload: !this.state.reload });
    }
  }

  getType() {
    const search = queryString.parse(window.location.search.replace('?', ''));
    return (
      search.type
        ? search.type
        : null
    );
  }

  getTitleByType() {
    switch (this.getType()) {
      case BulkImportTypes.USER:
        return 'Importação de usuários em lote'
      case BulkImportTypes.INITIATIVE:
        return 'Importação de iniciativas em lote'
      case BulkImportTypes.INITIATIVE_RESULT:
        return 'Importação de resultados das iniciativas em lote'
      default:
        return 'Importações em lote'
    }
  }
  getSpreadsheetByType() {
    const filesMap = {
      [BulkImportTypes.USER]: `usuarios${this.state.profile === 'P1' ? 'P1' : ''}.xlsx`,
      [BulkImportTypes.INITIATIVE]: `iniciativas${this.state.profile === 'P1' ? 'P1' : ''}.xlsx`,
      [BulkImportTypes.INITIATIVE_RESULT]: `resultados${this.state.profile === 'P1' ? 'P1' : ''}.xlsx`,
    }

    return filesMap[this.getType() || BulkImportTypes.USER];
  }

  formatRow(r) {
    const totalRows = (r.totalLinhas || 1);
    const percentSuccess = Math.round((r.linhasSucesso / totalRows) * 100);
    const percentError = Math.round((r.linhasErro / totalRows) * 100);

    return (
      <TableRow
        hover
        tabIndex={-1}
        key={r.id}
      >
        <TableCell>
          {r.linhasErro ? (
            <IconButton
              color='inherit'
              onClick={() => this.setState({ showErrorsOf: r.id })}
              size="small"
              title="Visualizar erros">
              <Icon fontSize="small">error</Icon>
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell scope="row">
          {r.nomeArquivo}
        </TableCell>

        <TableCell scope="row">
          {r.totalLinhas}
        </TableCell>

        <TableCell scope="row">
          {r.usuario.nome}
        </TableCell>

        <TableCell>
          {!r.totalLinhas
            ? (
              <ProgressBar variant="success" label='100%' now={100} key={1} />
            ) : (
              !r.linhasSucesso && !r.linhasErro
                ? (
                  <ProgressBar />
                ) : (
                  <ProgressBar>
                    <ProgressBar variant="success" label={`${percentSuccess}%`} now={percentSuccess} key={1} />
                    <ProgressBar variant="danger" label={`${percentError}%`} now={percentError} key={2} />
                  </ProgressBar>
                )
            )}
        </TableCell>
      </TableRow>
    );
  }

  onClickDownload() {
    const link = document.createElement('a');
    link.href = `/spreadsheets/${this.getSpreadsheetByType()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onClickUpload() {
    this.toggleUploadModal()
  }

  getDatatableButtons() {
    const buttons = [];

    if (BulkImportTypes.validate(this.getType())) {

      if (this.getType() === BulkImportTypes.INITIATIVE_RESULT) {
        buttons.push({
          label: 'Iniciativas sem Resultado',
          endpoint: 'iniciativa/export-sem-resultado',
          isEndpointFull: true,
          icone: this.props.width >= 768 ? 'archive' : 'download',
        });
      }

      if (this.getType() !== BulkImportTypes.INITIATIVE_RESULT) {
        buttons.push({
          label: 'Planilha modelo',
          onClick: this.onClickDownload,
          icone: this.props.width >= 768 ? 'archive' : 'download',
          path: null
        });
      }

      buttons.push({
        label: 'Enviar planilha',
        onClick: this.onClickUpload,
        icone: this.props.width >= 768 ? 'backup' : 'upload-1',
        path: null
      });
    }

    return buttons;
  }

  render() {
    window.setPageTitle('Importação em Lote - Admin');

    const headRows = [
      { label: 'Ações' },
      { column: 'NomeArquivo', label: 'Arquivo' },
      { column: 'TotalLinhas', label: 'Total de linhas' },
      { column: 'Usuario.Nome', label: 'Usuário' },
      { label: '% processado', width: '30%' }
    ];

    return (
      <>
        <UploadSpreadsheetModal
          isOpen={this.state.uploadModalIsOpen}
          toggleModal={this.toggleUploadModal}
          type={this.getType()}
          afterSubmit={() => this.setState({ reload: !this.state.reload })}
        />

        {this.state.showErrorsOf && (
          <SpreadsheetRowErrorsModal
            isOpen={true}
            id={this.state.showErrorsOf}
            handleClose={this.closeErrorsModal}
          />
        )}

        <Datatable
          title={this.getTitleByType()}
          endpoint='/importacao'
          headRows={headRows}
          formatRow={this.formatRow}
          setRows={this.setBulkImports}
          rows={this.state.bulkImports}
          search={this.state.type}
          reload={this.state.reload}
          buttons={this.getDatatableButtons()}
          width={this.props.width}
          mobile={AdminDropdown}
        />
      </>
    );
  }
}
