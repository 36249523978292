const types = {
  USER: 'USER',
  INITIATIVE: 'INITIATIVE',
  INITIATIVE_RESULT: 'INITIATIVE_RESULT'
};

export default Object.freeze({
  ...types,
  validate: type => {
    return types.hasOwnProperty(type);
  }
});
