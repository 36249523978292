import styled from "styled-components";

export const EditorContainer = styled.div`
  position: relative;
  width: 100%;

  .label {
    position: absolute;
    top: -8px;
    left: 12px;
    padding: 0 4px;
    background: #fff;
    color: #4d4c4d;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    z-index: 2;

    .required {
      color: #d93438;
    }
  }

  .ql-container {
    border: ${({ isInvalid }) =>
      isInvalid ? "1px solid #ff7563" : "1px solid #4d4c4d77"};
    border-radius: 4px;
  }

  .ql-editor,
  .ql-editor p {
    width: 100% !important;

    color: #4d4c4d;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
`;
