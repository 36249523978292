import axios from "axios";
import { AppAPIService } from "../services/AppAPIService";

export const REGISTER_URL = "api/user";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
const api = AppAPIService.getInstance();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: '/login',
    method: 'POST',
    data: {
      email,
      password
    }
  });
}

export async function resendEmail(email) {
  return api.makeHttpRequest({
    url: '/user/resend-mail/'+email,
    method: 'PUT',
  });
}

export async function loginGoogle(email) {
  return api.makeHttpRequest({
    url: `/login/login-with-social?login=${email}`,
    method: 'GET',
  });
}


export async function verificaEmail(email) {
  return api.makeHttpRequest({
    url: `/user/verificar-email-login?login=${email}`,
    method: 'GET',
  });
}

export async function updatePassword({
  currentPassword,
  password,
}) {

  const response = await api.makeHttpRequest({
    url: `/user/update-password`,
    method: 'PUT',
    data: {
      SenhaAtual: currentPassword,
      NovaSenha: password,
    },
  });
  return response;
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return api.makeHttpRequest({
    url: '/user/me'
  });
};
