import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { AppAPIService } from "../../../../services/AppAPIService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import formatCurrency from "../../../../utils/FormatCurrency";
import { AdminTitle } from "../../components/AdminTitle";
import { connect } from "react-redux";

const initialState = {
  id: 0,

  campaignAccessKey: "",
  selectCampaign: null,
  value: "",

  resetInput: false,
  reload: false,
  isLoading: false
};

class CashoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.userAvatar = React.createRef();
    this.state = {
      ...initialState,
      // statusOptions: [],
      campaignOptions: [],
      isEditing: false
    };

    this.api = AppAPIService.getInstance();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.loadCampaigns = this.loadCampaigns.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });

    try {
      const reqs = [this.loadCampaigns()];

      // if (this.props.match.params.id) {
      //   reqs.push(this.loadCampaign(this.props.match.params.id));
      // }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadCampaigns = async () => {
    const campaignList = await this.api.makeHttpRequest({
      url: "/campaign/all"
    });

    this.setState({
      campaignOptions: campaignList ?? []
    });
  };

  // loadSaque = async id => {
  //   const campaign = await this.api.makeHttpRequest({
  //     url: `/campaign/${id}`
  //   });

  //   this.setState({
  //     id: id,
  //     idOwner: campaign.idOwner,

  //     nome: campaign.name,
  //     status: campaign.status,
  //     newStatus: campaign.newStatus,
  //     categoria: campaign.idCampaignCategory,
  //     descricaoHTML: campaign.description,
  //     meta: campaign.goal ? formatCurrency(campaign.goal + "00") : "",
  //     temMeta: campaign.hasGoal,
  //     currentImagem: campaign.imageURL,
  //     alvo: `${campaign.idCampaignTarget}`,
  //     outroAlvo: campaign.otherTargetDescription
  //       ? campaign.otherTargetDescription
  //       : "",

  //     documentList: campaign.documents ? campaign.documents : [],

  //     resetInput: false,
  //     isLoading: false
  //   });
  // };

  // loadStatus = async () => {
  //   const statusList = await this.api.makeHttpRequest({
  //     url: "/campaign/status"
  //   });

  //   this.setState({
  //     statusOptions: statusList ?? []
  //   });
  // };

  submit = async e => {
    e.preventDefault();

    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/campaign/withdraw/${id}` }
        : { method: "POST", url: "/campaign/withdraw" };

      const value = this.state.value.replace(/\D/g, "");
      const decimal = value.replace(/(\d)(\d{2})$/, "$1.$2");
      const valueDecimal = parseFloat(decimal);

      const payload = {
        campaignAccessKey: this.state.campaignAccessKey,
        value: valueDecimal
      };

      await this.api.makeHttpRequest({
        ...reqBase,
        data: payload
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao requisitar saque, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/saque");
    }
  };

  handleChange = e => {
    if (e.target.name === "value") {
      this.setState({ [e.target.name]: formatCurrency(e.target.value) });
      return;
    }

    if (e.target.name === "campaignAccessKey") {
      const { campaignOptions } = this.state;

      const accessKey = e.target.value;
      const index = this.state.campaignOptions.findIndex(
        x => x.accessKey === accessKey
      );

      this.setState({ slectedCampaign: campaignOptions[index] });
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formIsValid = () => {
    return this.state.campaignAccessKey && this.state.value;
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      // try {
      //   await this.loadCampaign(this.props.match.params.id);
      // } catch (e) {
      //   Swal.fire(
      //     "Erro",
      //     "Problema ao reverter as alterações, tente mais tarde",
      //     "error"
      //   );
      // }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Saque - Admin");
    } else {
      window.setPageTitle("Requisitar Saque - Admin");
    }

    const {
      campaignAccessKey,
      slectedCampaign,
      value,
      campaignOptions,
      isLoading,
      isEditing
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Campanha" disableHeader />
        <Card>
          <StyledCardBody>
            <Form className="form-admin">
              <Form.Row>
                {/* Campanha */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select"
                    name="campaignAccessKey"
                    label="Campanha"
                    value={campaignAccessKey}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione a categoria</option>
                    {campaignOptions.length > 0 &&
                      campaignOptions.map(c => (
                        <option key={c.idCampaign} value={c.accessKey}>
                          {c.name}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {slectedCampaign && (
                  <>
                    {/* Valor Disponivel */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        label="Valor Disponível"
                        value={formatCurrency(
                          `${slectedCampaign?.balance?.totalAvailable}`
                        )}
                        prefix="R$"
                        readOnly
                      />
                    </Form.Group>

                    {/* Valor */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        name="value"
                        label="Valor"
                        value={value}
                        placeholder="Digite o valor do saque"
                        onChange={this.handleChange}
                        prefix="R$"
                        required
                      />
                    </Form.Group>
                  </>
                )}
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          module="saques"
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          // onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          submitText="Enviar"
          hideReload
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(CashoutForm);
