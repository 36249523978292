import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { connect } from "react-redux";
import { AppAPIService } from "../../../../services/AppAPIService";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
// import Swal from "sweetalert2";

const initialState = {
  cashouts: [],
  profile: "",
  reload: false,
  loading: false
};

class CashoutListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleLinkCampaign = this.handleLinkCampaign.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setCashouts = this.setCashouts.bind(this);
  }

  componentDidMount = async () => {
    const profile = this.props.user.role?.name;
    this.setState({ profile });
  };

  setCashouts = cashouts => {
    this.setState({ cashouts });
  };

  handleLinkCampaign = obj => {
    this.props.router.history.push(
      `/admin/campanhas/${obj.campaign.idCampaign}/editar/`
    );
  };

  // handleRefund = async obj => {
  //   try {
  //     await this.api.makeHttpRequest({
  //       method: "DELETE",
  //       url: `/donation/cancel-donation/${obj.transactionHash}`
  //     });

  //     Swal.fire({
  //       title: "Sucesso!",
  //       text: "Pedido de estorno realizado com sucesso.",
  //       icon: "success"
  //     });

  //     this.setState({ reload: true });
  //   } catch (e) {
  //     Swal.fire(
  //       "Erro!",
  //       e?.response?.data
  //         ? e.response.data
  //         : "Ocorreu um erro ao realizar estrorno.",
  //       "error"
  //     );
  //   }
  // };

  formatRow = r => {
    let crudActions = ["link-campaign"];

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idCashout}>
        <StyledTableCell scope="row">
          <CrudActions
            actions={crudActions}
            module="doacoes"
            onLink={this.handleLinkCampaign}
            id={r.idCashout}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row">{r.transactionHash}</StyledTableCell>
        <StyledTableCell scope="row">{r.campaign?.name}</StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.liquidValue)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">{r.status}</span>
        </StyledTableCell>
        <StyledTableCell>
          {Moment(r.creationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Saques - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "Transaction.TransactionHash", label: "Código" },
      { column: "Campaign.Name", label: "Campanha" },
      { column: "Transaction.Value", label: "Valor" },
      { column: "Transaction.LiquidValue", label: "Valor Liquido" },
      { column: "Transaction.Status", label: "Status" },
      { column: "Transaction.CreationDate", label: "Data de Doação" }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Saques"
          icon={<FaRegMoneyBillAlt color="#4d4d4d" size="26px" />}
          endpoint="/campaign/withdrawals"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.cashouts}
          setRows={this.setCashouts}
          reload={this.state.reload}
          placeholderSearch="Busque por camapanha"
          width={this.props.width}
          hiddenReload
          buttons={
            this.state.profile === "ADMIN" && [
              {
                label: "Requisitar",
                onClick: null,
                icone: <IoIosAdd color="#fff" size="25px" />,
                path: "/admin/saques/requisitar"
              }
            ]
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(CashoutListing);
