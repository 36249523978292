import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import { Form, Formik } from "formik";
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import queryString from "querystring";
import { RiLockPasswordLine } from "react-icons/ri";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import { AppAPIService } from "../../../services/AppAPIService";
import useGetWindowWidth from "../../../utils/useGetWindowWidth";
import { isValidNewPassword } from "../../../utils/validators/isValidYup";
import windowSize from "../../../constants/WindowSize";
import Header from "../Components/Header";
import Button from "../../../components/Button";
import useDocumentTitle from "../../../utils/useDocumentTitle";
import logoMeuCofrinho from "../../../assets/logo/meucofrinho-logo-cor.svg";

import {
  Container,
  Body,
  ContainerInputs,
  ContainerReCaptcha,
  ContainerWithIcon,
  StyledInput
} from "./styles";

const CreateNewPassword = ({ history }) => {
  useDocumentTitle("Meu Cofrinho - Cadastro de Senha");
  const [newPswVisibility, setNewPswVisibility] = useState(false);
  const [confirmPswVisibility, setConfirmPswVisibility] = useState(false);

  const [tokenReCaptcha, setTokenReCaptcha] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleForm, setIsVisibleForm] = useState(true);
  const hasToken = queryString.parse(window.location.search.replace("?", "")).t;
  const token = window.location.search.replace("?t=", "");
  const width = useGetWindowWidth();

  const handleSubmit = useCallback(
    async values => {
      if (tokenReCaptcha === null) {
        return;
      }

      try {
        const api = AppAPIService.getInstance();
        setIsLoading(true);

        await api.makeHttpRequest({
          url: "/requisicaotemporaria",
          method: "POST",
          data: { password: values.password, token }
        });

        setIsVisibleForm(false);
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data
            ? e.response.data
            : "Tivemos problemas na criação da sua nova senha. Por favor, tente novamente.",
          "error"
        );
      }

      setIsLoading(false);
    },
    [token, tokenReCaptcha]
  );

  return (
    <>
      {!hasToken && <Redirect to="/admin" />}
      <Container>
        {width < windowSize.SM && <Header />}
        <Body width={width}>
          {width > windowSize.SM && (
            <div style={{ width: "100%", marginBottom: 10 }}>
              <Header />
            </div>
          )}

          <ContainerWithIcon width={width}>
            {width >= windowSize.SM && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Link to="/">
                  <img
                    alt="logo Meu Cofrinho"
                    src={logoMeuCofrinho}
                    style={{ width: 280, marginBottom: 48 }}
                  />
                </Link>
              </div>
            )}

            <ContainerInputs>
              {isVisibleForm ? (
                <>
                  {width >= windowSize.SM ? (
                    <h3>Definir senha</h3>
                  ) : (
                    <h1>Definir senha</h1>
                  )}
                  <p className="subtitle">Agora, crie sua senha para acesso</p>
                  <Formik
                    initialValues={{ password: "", confirmePassword: "" }}
                    validationSchema={isValidNewPassword}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, resetForm);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur
                    }) => (
                      <Form>
                        <StyledInput
                          placeholder="Senha"
                          type={newPswVisibility ? "text" : "password"}
                          name="password"
                          value={values.password}
                          error={
                            touched.password && errors.password ? true : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.password && errors.password
                              ? errors.password
                              : ""
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RiLockPasswordLine size="18px" opacity=".70" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Alterar visibilidade da senha"
                                  onClick={() =>
                                    setNewPswVisibility(!newPswVisibility)
                                  }
                                  onMouseDown={e => e.preventDefault()}
                                  edge="end"
                                >
                                  {newPswVisibility ? (
                                    <VisibilityOffOutlined />
                                  ) : (
                                    <VisibilityOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <StyledInput
                          placeholder="Confirme sua senha"
                          type={confirmPswVisibility ? "text" : "password"}
                          name="confirmePassword"
                          value={values.confirmePassword}
                          error={
                            touched.confirmePassword && errors.confirmePassword
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.confirmePassword && errors.confirmePassword
                              ? errors.confirmePassword
                              : ""
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RiLockPasswordLine size="18px" opacity=".70" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Alterar visibilidade da confirmação da senha"
                                  onClick={() =>
                                    setConfirmPswVisibility(
                                      !confirmPswVisibility
                                    )
                                  }
                                  onMouseDown={e => e.preventDefault()}
                                  edge="end"
                                >
                                  {confirmPswVisibility ? (
                                    <VisibilityOffOutlined />
                                  ) : (
                                    <VisibilityOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <ContainerReCaptcha>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_SITE_KEY_RECAPTCHA}
                            onChange={value => {
                              setTokenReCaptcha(value);
                            }}
                          />
                        </ContainerReCaptcha>
                        <Button
                          text="Cadastrar senha"
                          disabled={
                            (!values.password ||
                              !values.confirmePassword ||
                              tokenReCaptcha === null) &&
                            true
                          }
                          opacity={
                            (!values.password ||
                              !values.confirmePassword ||
                              tokenReCaptcha === null) &&
                            0.5
                          }
                          cursor={
                            (!values.password ||
                              !values.confirmePassword ||
                              tokenReCaptcha === null) &&
                            "not-allowed"
                          }
                          loading={isLoading}
                          type="submit"
                        />
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  {width >= windowSize.SM ? (
                    <h3>Email confirmado!</h3>
                  ) : (
                    <h1>Email confirmado!</h1>
                  )}
                  <p className="subtitle success">
                    Sua conta está pronta para ser utilizada
                  </p>
                  <Button
                    text={"Login"}
                    opacity={1}
                    cursor={"pointer"}
                    loading={isLoading}
                    type="button"
                    onClick={() => {
                      history.push("/Logout");
                    }}
                  />
                  <Link to="/">Voltar para Home</Link>
                </>
              )}
            </ContainerInputs>
          </ContainerWithIcon>
        </Body>
      </Container>
    </>
  );
};

export default CreateNewPassword;
