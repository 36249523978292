import React from 'react';
import { EditorState, ContentState } from 'draft-js';
import { Icon } from '@material-ui/core';

class ButtonCode extends React.Component {

  state= {code: false}
  addContent = () => {
    const { editorState, toggleCode, content, onChange } = this.props;
    toggleCode()
    this.setState({code: !this.state.code})
    if (content &&  typeof content === 'string') {
      onChange(EditorState.push(editorState, ContentState.createFromText(content)));
    } else if (content) {
      onChange(EditorState.push(editorState, ContentState.createFromBlockArray(content)));
    }
  };

  render() {
    return (
      <div className='icon-toolbar mt-sm-1 mt-md-2' onClick={() => this.addContent()}><Icon className={this.state.code? 'icon-clicked': null}>code</Icon></div>
    );
  }
}

export default ButtonCode;
