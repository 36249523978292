import { TextField } from "@material-ui/core";
import styled from "styled-components";
import windowSize from "../../../constants/WindowSize";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  padding: 20px 0 0 0;
  background-size: cover;
  background-color: #ffffff;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: ${({ width }) => (width >= windowSize.SM ? "40px auto" : 0)};
  max-width: 450px;
`;

export const ContainerWithIcon = styled.div`
  width: 100%;
  padding: ${({ width }) => (width >= windowSize.SM ? `60px 60px` : `0 20px`)};
  border: ${({ width }) => (width >= windowSize.SM ? `1px solid #00A4B2` : 0)};
`;

export const ContainerInputs = styled.div`
  > h1 {
    color: #031d3d;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  > h3 {
    color: #031d3d;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .subtitle {
    text-align: center;
    color: #031d3d;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    margin-bottom: 15px;

    &.success {
      margin-bottom: 24px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    & > .MuiFormControl-root.MuiTextField-root:first-child {
      margin: 5px 0 15px;
    }
  }

  a {
    display: flex;
    flex: 1;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: none;
    color: #031d3d;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;

    :hover {
      transition: opacity 0.4s;
      opacity: 0.8;
      text-decoration: underline !important;
    }
  }
`;

export const ContainerReCaptcha = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px;
`;

export const StyledInput = styled(TextField)`
  margin-bottom: 5px;

  input {
    font-size: 18px;
    padding: 10px 5px;
  }
`;
