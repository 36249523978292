import React from 'react';
import { Form, Modal, Button, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AppAPIService } from '../../../../services/AppAPIService';
import Loading from '../../../home/components/Loading';
import UploadSpreadsheetErrorsModal from './UploadSpreadsheetErrorsModal';

class UploadSpreadsheetModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      submitting: false,
      checked: false,
      errors: [],
    }

    this.api = AppAPIService.getInstance();
    this.submit = this.submit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseErrorsModal = this.handleCloseErrorsModal.bind(this);
  }

  async submit() {
    this.setState({ submitting: true });
    this.props.toggleModal();

    try {
      const data = new FormData();
      data.append('planilha', this.state.file);
      data.append('tipo', this.props.type);

      await this.api.makeHttpRequest({
        method: 'post',
        url: '/importacao',
        data
      });

      this.setState({ submitting: false, file: null, checked: false });
      this.props.afterSubmit();

      Swal.fire({
        title: 'Sucesso!',
        text: 'Planilha atualizada com sucesso.',
        icon: 'success'
      });
    } catch (e) {
      const err = e.response
        ? e.response.data.split(/\n/)
        : 'Erro ao fazer upload da planilha para importação, tente novamente mais tarde';

      this.setState({ submitting: false, file: null, checked: false, errors: err });
    }
  }

  onFileChange(e) {
    this.setState({ file: e.target.files[0] });
  };

  handleClose() {
    this.setState({ file: null });
    this.props.toggleModal();
  }

  handleCloseErrorsModal() {
    this.setState({ errors: [] });
    this.props.toggleModal();
  }

  render() {
    return (
      <>
        <UploadSpreadsheetErrorsModal
          show={!!this.state.errors.length}
          errors={this.state.errors}
          handleClose={this.handleCloseErrorsModal}
        />

        <Loading isLoading={this.state.submitting} />
        {this.props.isOpen && (
          <Modal
            show={true}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Cadastro em lote</Modal.Title>
            </Modal.Header>

            <Form>
              <Modal.Body>
                <Form.Row>
                  <Form.Group className="mb-0 mt-4" as={Col} lg='12'>
                    <Form.File
                      id="custom-file-translate-html"
                      label={this.state.file ? this.state.file.name : "Planilha *"}
                      data-browse="Selecione o arquivo"
                      onChange={this.onFileChange}
                      accept=".xls, .xlsx"
                      custom
                    />
                  </Form.Group>
                </Form.Row>

              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>Cancelar</Button>
                <Button
                  variant="primary"
                  disabled={
                    !this.state.file
                    || (
                      this.props.type === "INITIATIVE"
                      && !this.state.checked
                    )
                  }
                  onClick={this.submit}
                >Enviar</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default UploadSpreadsheetModal;
