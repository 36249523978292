import React from "react";
import Footer from "../../components/Footer";
import { Container } from "./styles";

export const TermsConditionsPage = () => {
  return (
    <>
      <Container>
        <h1 className="heading__title">
          TERMOS E CONDIÇÕES DE USO MEUCOFRINHO
        </h1>

        <p>
          Estes Termos e Condições de Uso ("Termos") são celebrados entre o
          Participante e o MeuCofrinho, e regula os termos e condições
          aplicáveis aos serviços oferecidos pelo MeuCofrinho por meio da
          plataforma digital.
        </p>

        <p>
          Assim, neste instrumento constam as regras de utilização dos serviços
          e da plataforma MeuCofrinho, bem como as operações e procedimentos que
          poderão ser realizados pelo Participante.
        </p>

        <p>
          Para fins do presente instrumento, os termos estabelecidos, no
          singular ou no plural, terão os seguintes significados:
        </p>

        <p>
          “Campanha de Arrecadação”, campanha de responsabilidade do
          Participante Arrecadador que objetiva o recebimento de doações para
          uma finalidade específica.
        </p>

        <p>
          “Plataforma MeuCofrinho”, significa a plataforma de Campanha de
          Arrecadação e Doação que poderá ser acessada por meio do website [
          www.meucofrinho.com].
        </p>

        <p>
          “MeuCofrinho”, empresa estabelecida no presente instrumento,
          responsável pela gestão da plataforma MeuCofrinho.
        </p>

        <p>
          “Participante”, toda e qualquer pessoa física ou jurídica,
          independentemente da modalidade, que solicite o cadastro na plataforma
          MeuCofrinho.
        </p>

        <p>
          “Participante Arrecadador”, pessoa física ou jurídica, devidamente
          cadastrada na plataforma MeuCofrinho, responsável pela Campanha de
          Arrecadação.
        </p>

        <p>
          “Participante Doador”, pessoa física ou jurídica, devidamente
          cadastrada na plataforma MeuCofrinho, que realizada a doação de
          valores para Campanhas de Arrecadação.
        </p>

        <p>
          "Política de Privacidade": é a Política de Privacidade e de Tratamento
          de Dados aplicável, disponível na Plataforma MeuCofrinho.
        </p>

        <p>
          “Saldo”, quantidade em moeda corrente nacional arrecadada pelo
          Participante Arrecadador em uma Campanha de Arrecadação, já deduzidas
          as taxas aplicáveis.
        </p>

        <p>
          “Saque”, procedimento de liberação e transferência do Saldo disponível
          para a conta corrente de titularidade do Participante Arrecadador e de
          liberação e transferência de eventual saldo disponível no cadastro do
          Participante Doador, por eventual restituição.
        </p>

        <h3>1. Aceitação dos Termos e Condições de Uso</h3>

        <p>
          Fica desde já estabelecidos que o acesso à plataforma MeuCofrinho, o
          cadastro no website e a utilização dos Serviços, em conjunto ou
          individualmente, acarretará na concordância com estes Termos.
        </p>

        <p>
          Antes de acessar a plataforma MeuCofrinho, realizar seu cadastro e
          utilizar os serviços, o Participante deverá ler, certificar-se de
          haver entendido e aceitar todas as condições estabelecidas nestes
          Termos e na Política de Privacidade do MeuCofrinho.
        </p>

        <p>
          Caso o Participante não concorde com os termos previstos neste
          instrumento ou na Política de Privacidade, o Participante não deve se
          cadastrar, nem acessar, visualizar, baixar ou utilizar de qualquer
          forma nenhuma página, conteúdo, informação ou serviço da MeuCofrinho.
        </p>

        <p>
          Estes Termos poderão ser alterados a qualquer momento e a exclusivo
          critério da MeuCofrinho, por meio da publicação da versão mais recente
          na plataforma. Embora a MeuCofrinho possa notificar a respeito de
          qualquer alteração, é dever do Participante se manter atualizado
          quanto aos Termos, de modo que o acesso à plataforma ou utilização dos
          Serviços vincularão o Participante à versão atualizada dos Termos.
        </p>

        <h3>2. Funcionamento do MeuCofrinho e Serviços </h3>

        <p>
          A plataforma MeuCofrinho é um conjunto de soluções tecnológicas que
          viabiliza a realização de campanhas de arrecadação de valores em
          espécie por meio de doação de terceiros interessados, bem como o
          gerenciamento dos valores recebidos para a Campanha realizada.
        </p>

        <p>
          Nesse sentido, os serviços disponibilizados pela MeuCofrinho consistem
          na disponibilização de plataforma digital e demais procedimentos
          necessários para realização da Campanha para arrecadação ou doação de
          valores em espécie, bem como o gerenciamento e registro das doações
          recebidas e realizadas e das transferências entre os Participantes.
        </p>

        <h3>
          3. Cadastro de Participantes na Plataforma MeuCofrinho, Manutenção e
          Cancelamento
        </h3>

        <p>
          Para utilização da plataforma MeuCofrinho e suas funcionalidades e
          serviços, o Participante deverá se cadastrar previamente, por meio do
          fornecimento de alguns dados pessoais e informações necessárias para a
          prestação dos serviços.
        </p>

        <p>
          Poderão se cadastrar na plataforma MeuCofrinho pessoas físicas ou
          jurídicas que preencham os seguintes requisitos:
        </p>

        <ul className="letters">
          <li>
            <p>
              Pessoas Físicas: Ser absolutamente capaz, ter no mínimo 18
              (dezoito) anos ou estar legalmente representado se menor e possuir
              CPF ativo e regular;
            </p>
          </li>

          <li>
            <p>
              Pessoas Jurídicas: Estarem legalmente representadas na forma de
              seus atos constitutivos, estar ativa e regular perante a Receita
              Federal e estar estabelecida em território nacional.
            </p>
          </li>
        </ul>

        <p>
          É expressamente vedada a criação de mais de um cadastro por
          Participante.
        </p>

        <p>
          É facultado à MeuCofrinho realizar análise prévia das informações
          disponibilizadas pelo Participante antes da aprovação do cadastro e
          liberação de uso da plataforma MeuCofrinho, que poderá incluir, sem
          limitação, a realização de procedimentos de Know Your Costumer e de
          prevenção à lavagem de dinheiro.
        </p>

        <p>
          Nesse sentido, o Participante deverá ter ciência de que a MeuCofrinho
          poderá solicitar novas informações que sejam necessárias para o
          correto cadastro, realizar pesquisas em instituições financeiras e
          banco de dados, bem como negar o cadastro, de forma motivada ou não.
        </p>

        <p>
          O login na plataforma MeuCofrinho poderá ser realizado por meio de
          aplicativos de terceiros, como redes sociais, de acordo com as
          funcionalidades da plataforma, ou ainda por meio de login e senha
          estabelecidos no cadastro.
        </p>

        <p>
          O Participante é responsável pela segurança das informações de acesso
          à plataforma MeuCofrinho, que são pessoais e intransferíveis.
        </p>

        <p>
          Em caso de extravio ou verificação de utilização indevida de seu
          cadastro, o Participante deverá notificar imediatamente a MeuCofrinho
          por meio dos canais de comunicação estabelecidos nestes Termos.
        </p>

        <p>
          É de exclusiva responsabilidade dos Participantes fornecer, atualizar
          e garantir a veracidade dos dados cadastrais, não recaindo à
          MeuCofrinho qualquer tipo de responsabilidade civil e criminal
          resultante de dados inverídicos, incorretos ou incompletos fornecidos
          pelos Participantes.
        </p>

        <p>
          É reservado à MeuCofrinho o direito de utilizar todos os meios válidos
          e legais para, se entender necessário, a seu exclusivo critério,
          confirmar os dados e informações disponibilizadas pelo Participante e
          sua situação de crédito para fins de aprovação ou manutenção do
          cadastro.
        </p>

        <p>
          Em caso de informações incorretas ou inverídicas, inércia do
          Participante em disponibilizar os dados e informações necessárias,
          verificação de restrição ao crédito, descumprimento destes Termos ou
          duplicidade de cadastro, a MeuCofrinho poderá suspender, bloquear ou
          deletar o cadastro do Participante, sem qualquer tipo de indenização
          ou ressarcimento.
        </p>

        <p>
          Ainda, o Participante poderá cancelar seu cadastro a qualquer momento,
          sem qualquer ônus, a seu exclusivo critério, por meio da plataforma
          MeuCofrinho.
        </p>

        <p>
          O cancelamento do cadastro pelo Participante Arrecadador, por qualquer
          motivo, depende do Status de suas campanhas criadas. Arrecadadores com
          campanhas inativas poderão encerrar o Cadastro normalmente.
          Arrecadadores com campanhas ativas deverão sacar todo o Saldo
          disponivel em suas campanhas, e encerrar todas suas campanhas de
          arrecadação. O Cadastro do Participante Arrecadador poderá ser
          encerrado somente após o Saldo disponível em todas suas campanhas
          estiver zerado.
        </p>

        <p>
          A MeuCofrinho se reserva o direito de não aprovar novos cadastros de
          Participantes que tenham cadastro anterior encerrado em vista de
          irregularidades de qualquer natureza.
        </p>

        <h3>
          4. Utilização da Plataforma MeuCofrinho e Obrigações dos Participantes
        </h3>

        <p>
          A plataforma MeuCofrinho preza pela segurança, licitude e veracidade
          das campanhas de arrecadação e das doações realizadas por meio da
          plataforma.
        </p>

        <p>
          Nesse sentido, os Participantes, sejam eles Participantes
          Arrecadadores ou Participantes Doadores, devem seguir as regras
          estabelecidas no presente instrumento, inclusive:
        </p>

        <ul className="numeric">
          <li>
            <p>
              Os Participantes deverão utilizar a plataforma MeuCofrinho e os
              serviços disponibilizadas de forma adequada à legislação, para
              fins lícitos, com boa-fé e de acordo com a moral e os bons
              costumes, de modo que é vedada qualquer forma de utilização para
              fins ilegais, difamatórios, discriminatórios, abusivos, ofensivos,
              pornográficos, obscenos, agressivos, injuriosos, vexatórios,
              enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça
              ou uso de falsa identidade, ou seja, qualquer uso escuso que possa
              prejudicar a MeuCofrinho, outros Participantes ou terceiros.
            </p>
          </li>

          <li>
            <p>
              É vedada a utilização da plataforma MeuCofrinho para a criação de
              campanhas de arrecadação que se propõem a arrecadação de fundos
              para finalidades criminosas, comerciais, ou que, de qualquer
              forma, venham a expor direta ou indiretamente a MeuCofrinho,
              outros Participantes ou terceiros de forma negativa e violem
              direitos de terceiros, sob pena de cancelamento da campanha de
              arrecadação ou do cadastro do Participante.
            </p>
          </li>

          <li>
            <p>
              O Participante deve possuir sob exclusiva responsabilidade todos
              os softwares e hardwares necessários para acessar a plataforma
              MeuCofrinho e suas funcionalidades, incluindo, mas não se
              limitando, computador e/ou dispositivo móvel com acesso à
              Internet, com os requisitos técnicos adequados e necessários ao
              funcionamento da plataforma.
            </p>
          </li>

          <li>
            <p>
              Os Participantes devem manter todos os dados e informações
              necessárias atualizadas no que se refere ao cadastro na
              plataforma, bem como disponibilizar todos os esclarecimentos e
              informações adicionais solicitadas pela MeuCofrinho.
            </p>
          </li>

          <li>
            <p>
              Os Participantes devem se abster de utilizar quaisquer informações
              disponibilizadas por outros Participantes, ainda que de forma
              pública, para quaisquer finalidades que não sejam previamente
              autorizadas pelos titulares de referidas informações.
            </p>
          </li>
          <li>
            <p>
              Os Participantes Doadores deverão utilizar apenas recursos
              financeiros de origem lícita e declarada, sendo vedada a
              utilização de recursos que possam ser considerados ilícitos ou de
              terceiros não autorizados.
            </p>
          </li>
          <li>
            <p>
              Os Participantes Arrecadadores deverão utilizar o Saldo arrecadado
              exclusivamente para as finalidades informadas na campanha
              específica, devendo colaborar com informações e documentos que
              comprovem a utilização adequada do referido Saldo, sempre que
              solicitado pela MeuCofrinho, a seu exclusivo critério.
            </p>
          </li>
        </ul>

        <h3>5. Realização de Campanhas de Arrecadação</h3>

        <p>
          Os Participantes Arrecadadores poderão realizar Campanhas de
          Arrecadação na plataforma MeuCofrinho, objetivando o recebimento de
          recursos financeiros por meio de doação dos Participantes Doadores.
        </p>

        <p>
          As Campanhas de Arrecadação deverão seguir, além das demais regras
          estabelecidas no presente instrumento, as seguintes regras:
        </p>

        <ul className="numeric">
          <li>
            <p>
              Todas as Campanhas de Arrecadação deverão, sem exceção, conter uma
              foto que ilustre sua finalidade, desde que adequadas ao público de
              todas as idades, de acordo com as orientações gerais estabelecidas
              neste item.
            </p>
          </li>

          <li>
            <p>
              Toda Campanha de Arrecadação possuirá um ID e um link de acesso
              únicos, de forma a facilitar a identificação de campanhas e
              controle pelo Participante Arrecadador e a MeuCofrinho.
            </p>
          </li>

          <li>
            <p>
              A finalidade da Campanha de Arrecadação deverá estar de acordo com
              os princípios e obrigações estabelecidos nestes Termos.
            </p>
          </li>

          <li>
            <p>
              Caso a Campanha de Arrecadação viole quaisquer obrigações e regras
              do presente instrumento, a MeuCofrinho poderá suspender, bloquear
              ou desativar a Campanha de Arrecadação, a seu exclusivo critério e
              sem qualquer indenização ao Participante Arrecadador.
            </p>
          </li>

          <li>
            <p>
              As Campanhas de Arrecadação não poderão infringir os direitos
              autorais, de patente, de marca comercial, de segredo comercial nem
              outros direitos de propriedade intelectual, industrial ou de
              publicidade ou confidencialidade ou de personalidade de terceiros.
            </p>
          </li>

          <li>
            <p>
              Quando as Campanhas de Arrecadação forem vinculadas a obtenção de
              doações para terceiros, inclusive menores de idade, o Participante
              Arrecadador deverá garantir, sob sua exclusiva responsabilidade,
              que possui autorização prévia e expressa de referido terceiro ou
              de seus responsáveis legais para a realização da Campanha de
              Arrecadação.
            </p>
          </li>

          <li>
            <p>
              Todas as Campanhas de Doação serão previamente analisadas pela
              MeuCofrinho para verificação de atendimento às regras internas. A
              análise não concederá aprovação definitiva, de modo que havendo a
              constatação de irregularidades, ainda que existentes quando da
              análise, a MeuCofrinho poderá, a seu critério, suspender,
              bloquear, cancelar ou desativar a Campanha de Arrecadação, sem
              qualquer tipo de indenização ao Participante Arrecadador.
            </p>
          </li>

          <li>
            <p>
              A Campanha poderá ser alterada pelo Participante Arrecadador
              exclusivamente no que se refere às suas metas e prazos de
              arrecadação e informações gerais, de modo que a sua finalidade e
              identificação deverão permanecer da forma como cadastrados.
            </p>
          </li>

          <li>
            <p>
              Será permitida, exclusivamente, a arrecadação de valores em moeda
              corrente nacional.
            </p>
          </li>
        </ul>

        <p>
          Para a identificação da Campanha, seja pelo nome, imagem e informações
          gerais disponibilizadas na plataforma MeuCofrinho, sugere-se que
          utilizem nomes, imagens e informações que não sejam protegidos por
          direitos autorais, de propriedade intelectual ou que dependem de
          autorização de terceiros para serem utilizadas. Ainda, dados pessoais,
          imagens e informações em geral de crianças e adolescentes devem ser
          evitadas.
        </p>

        <p>
          Havendo a utilização de nome, imagem ou informações gerais que
          dependam de autorização prévia e expressa de terceiros, o Participante
          Arrecadador será o único e exclusivo responsável por obter referidas
          autorizações e deverá disponibilizar as autorizações à MeuCofrinho
          sempre que solicitado.
        </p>

        <p>
          O Participante Arrecadador poderá verificar o Saldo arrecadado e
          demais informações da Campanha de Arrecadação por meio da própria
          plataforma MeuCofrinho.
        </p>

        <h3>6. Realização de Aportes e Doação</h3>

        <p>
          Para que o Participante Doador faça doações à Campanhas de Arrecadação
          de seu interesse, deverá preencher e disponibilizar as informações
          solicitadas e deverá optar pelas formas de doação disponibilizadas
          pela plataforma MeuCofrinho no ato da doação, como cartões de crédito,
          PIX e boleto bancário.
        </p>

        <p>
          O valor mínimo para doação, por qualquer meio, é de R$ 25,00 (vinte e
          cinco reais) . A doação deverá ser aprovada pela MeuCofrinho caso seja
          superior a R$ 20.000,00 (vinte mil reais).
        </p>

        <p>
          Todas as transações serão devidamente registradas na plataforma
          MeuCofrinho por meio de um Código de Identificação para acompanhamento
          pelo Participante Doador.
        </p>

        <p>
          Em caso de arrependimento, o Participante Doador poderá requerer o
          cancelamento de uma doação no prazo máximo de 5 (cinco) dias após a
          referida doação. Após referido prazo o Participante Doador não poderá
          cancelar a doação e não terá direito a qualquer restituição.
        </p>

        <p>
          A efetivação da doação estará condicionada à liberação e aprovação
          pela instituição financeira responsável, bem como a eventual análise
          prévia da MeuCofrinho, em caso de suspeita de fraude.
        </p>

        <p>
          O Participante Doador deverá observar todas as demais regras e
          observações estabelecidas no presente instrumento.
        </p>

        <h3>7. Regras de Pagamento</h3>

        <p>
          Os métodos de realização da doação pelo Participante Doador são: i)
          cartão de crédito; e ii) pix.
        </p>

        <p>
          O processamento dos pagamentos será de responsabilidade de uma
          instituição de pagamento terceirizada, a ser contratada diretamente
          pela MeuCofrinho. O Participante Arrecadador declara ter ciência de
          que, para que possa receber seus recursos arrecadados,estará abrindo
          uma subconta do MeuCofrinho junto à Instituição de Pagamento.
        </p>

        <p>
          A MeuCofrinho trabalhará em conjunto com a instituição financeira para
          registrar na plataforma MeuCofrinho todos os valores doados e
          arrecadados pelos Participantes.
        </p>

        <p>
          Qualquer contestação de doações efetuadas que acarrete suspensão ou
          cancelamento da doação deverá ser resolvida somente entre os
          Participantes e a respectiva instituição financeira, não recaindo
          sobre a MeuCofrinho qualquer responsabilidade pelo processamento dos
          pagamentos efetuados junto à instituição de meios de pagamento ou
          instituição financeira.
        </p>

        <p>
          O Participante desde já autoriza a MeuCofrinho a realizar o estorno e
          cancelamento de doações realizadas de forma irregular, ilícita ou em
          descumprimento às condições previstas nestes Termos.
        </p>

        <p>
          Fica estabelecido que eventuais estornos realizados ao Participante
          Doador serão creditados no meio de pagamento cadastrado na Plataforma
          para a devida doação.
        </p>

        <p>
          O Participante Arrecadador declara ter ciência de que o Participante
          Doador poderá cancelar a doação no prazo máximo de 5 (cinco) dias,
          contados da realização da doação, de modo que os valores serão
          automaticamente restituídos, sem qualquer indenização ao Participante
          Arrecadador nesse sentido.
        </p>

        <p>
          O valor bruto, o Saldo Total, o valor disponível para Saque e o valor
          sacado referentes a cada Campanha de Arrecadação serão
          disponibilizados pela MeuCofrinho ao Participante Arrecadador na
          plataforma MeuCofrinho, os quais poderão ser consultados enquanto a
          Campanha estiver ativa.
        </p>

        <h3>
          8. Liquidação e Regras de Liberação de Recursos Aportados no
          MeuCofrinho
        </h3>

        <p>
          Para que a liquidação dos valores doados ou arrecadados seja
          realizada, o Participante deverá possuir uma conta bancária apta e
          regular em instituição financeira nacional e adequada para receber ou
          transferir os valores.
        </p>

        <p>
          Os valores arrecadados serão disponibilizados para Saque pelo
          Participante Arrecadador nos seguintes prazos:
        </p>

        <ul className="numeric">
          <li>
            <p>
              Doação Com Origem Nacional: Prazo mínimo de 05 (cinco) dias úteis;
              e
            </p>
          </li>
          <li>
            <p>
              Doação com Origem Internacional: Prazo de até 35 (trinta e cinco)
              dias úteis.
            </p>
          </li>
        </ul>

        <p>
          Todos os Saques solicitados pelo Participante Arrecadador serão
          confirmados por e-mail e, se necessário, autenticados por duplo fator,
          inclusive mediante requisição de cópia do RG e CPF, a critério da
          MeuCofrinho, sempre que entender necessário em vista do valor ou forma
          do Saque.
        </p>

        <p>
          Para a realização do Saque, o Participante Arrecadador deverá ter
          cadastrado seus dados bancários, que deverão estar em nome do
          Participante Arrecadador.
        </p>

        <p>
          Todos os Saques a serem realizados pelo Participante Arrecadador serão
          realizados de acordo com o Saldo disponível, já descontadas todas as
          taxas aplicáveis.
        </p>

        <p>
          O Saque relativo a uma Campanha de Arrecadação poderá ser realizado de
          forma fracionada, de modo que não será necessário atingir a meta para
          realizar o Saque.
        </p>

        <p>
          O Saque não afetará o desempenho da Campanha em termos de porcentagem
          arrecadada, de modo que mesmo com o Saque do valor total arrecadado ou
          fracionado, a porcentagem de arrecadação não será modificada.
        </p>

        <p>
          Os valores serão disponibilizados na conta informada pelo Participante
          Arrecadador em ate 03 (três) dias úteis, contados da data de
          solicitação do Saque.
        </p>

        <p>
          Em caso de suspeita de fraude, o Saque poderá ser suspendido
          temporariamente, até que seja concluída a análise da licitude do
          Saque, por motivos de segurança.
        </p>

        <h3>9. Tarifas e Demais Encargos </h3>

        <p>
          A utilização da plataforma MeuCofrinho está condicionada ao pagamento,
          por parte do Participante Arrecadador, das seguintes taxas:
        </p>

        <ul className="numeric">
          <li>
            <p>
              Taxa Administrativa: cobrada dos Participantes Arrecadadores pela
              disponibilização da plataforma MeuCofrinho e dos serviços, é a
              taxa de 6% (seis porcento) que é incidente sobre o Saldo bruto
              arrecadado.
            </p>
          </li>

          <li>
            <p>
              Tarifa de Saque: cobrada dos Participantes Arrecadadores por cada
              Saque realizado. Tarifa cobrada pela Instituição de Pagamento.
            </p>
          </li>

          <li>
            <p>
              Taxas de Pagamento: Taxas cobradas dos Participantes Arrecadadores
              em vista das operações financeiras realizadas para concretização
              da doação, correspondentes a R$ 3,49 (três reais e quarenta e nove
              centavos) para pagamentos recebidos por doação por meio de boleto,
              1,19% (um vírgula dezenove por cento) do valor recebido de cada
              doação realizada por pix e 3,36% (três vírgula trinta e seis por
              cento) do valor recebido de cada doação realizada por cartão de
              crédito.
            </p>
          </li>
        </ul>

        <p>
          A MeuCofrinho poderá reajustar ou alterar as taxas e as formas de
          cobrança a seu exclusivo critério, de modo que após o reajuste ou
          alterações serão aplicados para as Campanhas de Arrecadação iniciadas
          posteriormente ao reajuste ou alterações.
        </p>

        <p>
          Além das taxas informadas acima, em decorrência da doação ou da
          arrecadação, poderá haver a incidência de tributos e encargos legais.
          Os Participantes serão exclusivamente responsáveis pelo pagamento e
          recolhimento dos tributos incidentes nas operações que realizarem por
          meio da plataforma MeuCofrinho e deverão contratar consultoria
          especializada, em caso de dúvidas.
        </p>

        <h3>
          10. Garantias e Responsabilidades da MeuCofrinho e dos Participantes
        </h3>

        <p>
          O Participante declara estar ciente e concorda que a MeuCofrinho se
          exime de qualquer responsabilidade por quaisquer declarações ou
          garantias implícitas ou aqui não expressamente previstas, incluindo,
          sem limitação, quaisquer garantias de adequação da plataforma
          MeuCofrinho para uma finalidade específica. A MeuCofrinho não será, em
          nenhuma hipótese, responsável por danos indiretos, materiais ou
          morais, assim como lucros cessantes, ocasionados, ou relacionados, à
          existência ou uso da plataforma MeuCofrinho e/ou dos serviços.
        </p>

        <p>
          A MeuCofrinho não garante que a plataforma MeuCofrinho e/ou os
          serviços atenderão aos fins pretendidos pelos Participantes, nem que a
          plataforma MeuCofrinho e/ou os serviços estejam isentos de erros,
          possíveis interrupções ou falhas, ou que a plataforma MeuCofrinho e/ou
          os serviços serão compatíveis ou funcionarão com qualquer software,
          aplicações ou serviços de terceiros ou dos Participantes. Os
          Participantes reconhecem que a plataforma MeuCofrinho e/ou os serviços
          podem não estar disponíveis ou serem indisponibilizados por diversos
          fatores, incluindo, sem limitação, manutenções periódicas do sistema
          (programadas ou não), manifestações da natureza, falhas técnicas do
          software da plataforma, infraestrutura de telecomunicações ou atraso
          ou interrupção ocasionada por vírus, ataques de negação de serviços,
          aumento ou flutuação de demanda, ações e omissões de terceiros ou
          qualquer outra causa que esteja fora do controle da MeuCofrinho.
        </p>

        <p>
          Além disso, o Usuário declara ter conhecimento de que o acesso aos
          Serviços é feito de modo digital, sendo imprescindível, para tanto,
          possuir conexão com a internet. A MeuCofrinho não é responsável pela
          qualidade de sua conexão com a internet.
        </p>

        <p>
          A MeuCofrinho não será responsável por danos causados por atos de
          outros Participantes.
        </p>

        <p>
          Os Participantes serão exclusivamente responsáveis pelos danos de
          qualquer natureza causados para a MeuCofrinho e a terceiros e pelas
          práticas ilícitas que cometerem.
        </p>

        <h3>11. Propriedade Intelectual</h3>

        <p>
          As marcas, nomes, logotipos, nomes de domínio e demais sinais
          distintivos, bem como a plataforma MeuCofrinho são de propriedade
          exclusiva da MeuCofrinho.
        </p>

        <p>
          O Participante não deverá copiar, reproduzir, republicar, transmitir,
          distribuir ou de qualquer outra forma utilizar qualquer conteúdo ou
          sinais distintivos da MeuCofrinho, parcial ou integralmente, sem a
          prévia e expressa autorização da MeuCofrinho.
        </p>

        <p>
          Por meio do cadastro, a MeuCofrinho concederá uma licença de uso da
          plataforma MeuCofrinho, temporária, onerosa e integral para que o
          Participante utilize a plataforma MeuCofrinho e os serviços, enquanto
          o cadastro do Participante estiver ativo e regular. O Participante não
          poderá utilizar a plataforma para qualquer outra finalidade que não a
          estabelecida no presente instrumento.
        </p>

        <p>
          O Participante Arrecadador deverá utilizar dados, imagens e
          informações das quais seja proprietário e possui todas as autorizações
          para utilização de dados, imagens e informações de terceiros.
        </p>

        <p>
          O Participante Arrecadador autoriza que todos os dados, imagens e
          informações utilizados em uma Campanha de Arrecadação sejam mantidos
          por período indeterminado na plataforma MeuCofrinho, mesmo que a
          arrecadação tenha sido encerrada, a exclusivo critério da MeuCofrinho.
        </p>

        <h3>12. Conteúdo de Terceiros</h3>

        <p>
          Os serviços, produtos e conteúdo de terceiros disponibilizados na
          plataforma MeuCofrinho, ainda que tenham conexão com a MeuCofrinho,
          são de única e exclusiva responsabilidade do terceiro. O Participante
          reconhece e concorda que a MeuCofrinho não garante, não endossa, não
          assume e não terá qualquer responsabilidade com relação a produtos,
          serviços ou sites de terceiros.
        </p>

        <p>
          A MeuCofrinho não se responsabiliza e não oferece qualquer garantia da
          exatidão, efetividade, oportunidade e adequação de qualquer
          informação, conteúdo, produto ou serviço fornecido por terceiros,
          inclusive hyperlinks para páginas de terceiros.
        </p>

        <h3>13. Prazo e Rescisão</h3>

        <p>
          Estes Termos são celebrados entre cada Participante e a Cofrinho de
          forma individual por prazo indeterminado, com início de sua vigência a
          partir do seu aceite pelo Participante, que ocorrerá no momento da
          solicitação de cadastro na plataforma MeuCofrinho.
        </p>

        <p>
          Estes Termos permanecerão em vigor enquanto o cadastro do Participante
          estiver ativo e enquanto a plataforma MeuCofrinho estiver em
          funcionamento. Os presentes Termos serão automaticamente encerrados em
          caso de encerramento do cadastro do Participante por qualquer motivo
          ou em caso de cancelamento de funcionamento da plataforma MeuCofrinho,
          o que ocorrer primeiro.
        </p>

        <p>
          A MeuCofrinho se reserva no direito de interromper de forma temporária
          ou permanente o funcionamento da plataforma e/ou a prestação dos
          serviços, a seu exclusivo critério, sem qualquer indenização ao
          Participante nesse sentido, de modo que estes Termos serão
          automaticamente rescindidos.
        </p>

        <p>
          Em caso de rescisão destes Termos por qualquer motivo, a MeuCofrinho
          deverá realizar a liberação do Saldo correspondente às Campanhas de
          Arrecadação para os Participantes Arrecadadores e eventuais saldos
          remanescentes de titularidade dos Participantes Doadores, nos termos
          estabelecidos no presente instrumento.
        </p>

        <h3>14. Gestão da Plataforma MeuCofrinho</h3>

        <p>
          A plataforma MeuCofrinho é administrada pela empresa [MEU COFRINHO
          NEGOCIOS VIRTUAIS LTDA ], inscrita no CNPJ/ME sob nº
          [45.700.954/0001-45], com sede na [Al. Santos 200, Cerqueira Cesar,
          São Paulo/SP - CEP: 01418-000 ].
        </p>

        <p>
          Em caso de dúvidas e esclarecimentos a respeito destes Termos, o
          Participante poderá entrar com a MeuCofrinho por meio do
          [contato@meucofrinho.com].
        </p>

        <h3>15. Disposições Gerais</h3>

        <ul className="numeric">
          <li>
            <p>
              Acordo Integral. As disposições destes Termos, assim como as de
              seus anexos, consideradas partes integrantes e complementares
              deste instrumento, refletem a integridade dos entendimentos e
              acordos entre as Partes.
            </p>
          </li>

          <li>
            <p>
              Caráter vinculativo e sucessão. Estes Termos são celebrados em
              caráter irrevogável, e suas obrigações são legais, válidas e
              vinculativas para as Partes e seus sucessores e herdeiros a
              qualquer título e exequível de acordo com seus respectivos termos.
            </p>
          </li>

          <li>
            <p>
              Independência. No caso de qualquer Cláusula, termo ou disposição
              destes Termos ser considerada nula ou não aplicável, tal nulidade
              ou inexequibilidade não afetará quaisquer outras Cláusulas, termos
              ou disposições aqui contidas, que permanecerão em pleno vigor e
              efeito.
            </p>
          </li>

          <li>
            <p>
              Renúncia. O não exercício de quaisquer direitos ou a concordância
              com o não cumprimento de quaisquer termos ou condições sob estes
              Termos não configurará renúncia de quaisquer direitos sob estes
              Termos nem impedirá a referida Parte de executar ou exercer
              quaisquer destes direitos a qualquer tempo.
            </p>
          </li>
          <li>
            <p>
              Tolerância. Nenhuma tolerância ou atraso de qualquer das Partes em
              fazer cumprir ou exigir o cumprimento dos direitos e obrigações
              convencionados nestes Termos, constituirá novação nem precedente
              de qualquer natureza. Tal tolerância não prejudicará ou
              restringirá o exercício dos mesmos direitos e obrigações em
              situações futuras semelhantes, bem como não isentará, em nenhum
              caso, qualquer das Partes do integral cumprimento de suas
              obrigações de acordo com o aqui convencionado e previsto.
            </p>
          </li>

          <li>
            <p>
              Legislação Aplicável. Estes Termos serão regidos pelas leis da
              República Federativa do Brasil.
            </p>
          </li>

          <li>
            <p>
              Foro. As Partes elegem o Foro da Comarca de São Paulo, Estado de
              São Paulo para dirimir qualquer dúvida ou controvérsia relativa
              aos presentes Termos, com renúncia expressa de qualquer outro, por
              mais privilegiado que seja ou que venha a ser.
            </p>
          </li>
        </ul>
      </Container>
      <Footer />
    </>
  );
};
