import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog/";

export const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(77, 76, 77, 0.5) !important;
  }

  .MuiPaper-root {
    max-width: 450px;
  }

  .MuiDialogTitle-root {
    padding-top: 24px;
    padding-bottom: 8px;

    .MuiTypography-root {
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #343877;
    }
  }

  .MuiDialogContent-root {
    padding: 24px;

    #alert-dialog-description {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      margin: 0;
    }
  }

  .MuiDialogActions-root {
    justify-content: center;
    padding: 0;
    gap: 24px;
    margin: 8px 24px 24px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 145px;
  margin: 0 !important;
  border-radius: 4px;
  border: 1px solid #343877;
  background: ${({ color }) =>
    color === "primary" ? "#343877" : "transparent"};

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ color }) => (color === "primary" ? "#fff" : "#343877")};

  box-shadow: ${({ color }) =>
    color === "primary"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;
