import axios from 'axios';

const BASE_URL = process.env.REACT_APP_PAYMENT_GATEWAY_URL;
const PUBLIC_KEY = process.env.REACT_APP_PAYMENT_GATEWAY_PUBLIC_KEY ?? '';

export const generateCardToken = async (payload) => {
  const headers = {
    'Content-Type': 'application/json',
  };

  return await axios.post(BASE_URL + `/tokens?appId=${PUBLIC_KEY}`, payload, {
    headers,
  });
};
