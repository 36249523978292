import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';

export default class UploadSpreadsheetErrorsModal extends React.Component {
  render() {
    return (
      <Modal
        size='lg'
        show={this.props.show}
        className='bulk-import-errors'
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Erros de upload</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Col md='12'>
            <Portlet>
              <PortletBody className="p-0">
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          ERRO
                      </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.errors && Array.isArray(this.props.errors) && this.props.errors.length
                        ? (
                          this.props.errors.map(e => (
                            <TableRow key={Math.random()}>
                              <TableCell align="center">
                                {e}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              Problema ao carregar a planilha, contate o administrador.
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                  </Table>
                </Paper>
              </PortletBody>
            </Portlet>
          </Col>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={this.props.handleClose}>Ok</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
