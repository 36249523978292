import React from "react";
import { Form, Col, Card, Tabs, Tab } from "react-bootstrap";
import styled from "styled-components";
import Swal from "sweetalert2";
import Moment from "moment";
import { AppAPIService } from "../../../../services/AppAPIService";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidCpfCnpj from "../../../../utils/validators/IsValidCpfCnpj";
import { generateCardToken } from "../../../../utils/generateCardToken";
import IsValidYear from "../../../../utils/validators/IsValidYear";
import IsValidCardLength from "../../../../utils/validators/IsValidCardLength";
import { AdminTitle } from "../../components/AdminTitle";
import { connect } from "react-redux";
import { TabsContainer } from "../../components/StyledTabs/styles";
import Datatable from "../../../../partials/datatable/Datatable";
import { BiDonateHeart } from "react-icons/bi";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import { CardTitle } from "./styles";

const Button = styled.button`
  position: relative;
  width: 120px;
  padding: 14px 0;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #343877;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

const initialState = {
  // Assinatura
  billing_date: "",

  // Pagamento
  value: "",
  name_card: "",
  number_card: "",
  cpf: "",
  expire_month: "",
  expire_year: "",
  cvv: "",

  idUser: 0,
  accessKey: "",
  donations: [],

  id: 0,
  idOwner: 0,

  isLoading: false
};

class RecurrencePaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.api = AppAPIService.getInstance();

    this.handleChange = this.handleChange.bind(this);
    this.paymentAttemptsRowFormatter = this.paymentAttemptsRowFormatter.bind(
      this
    );
    this.setTransactions = this.setTransactions.bind(this);
    this.inactiveRecurrence = this.inactiveRecurrence.bind(this);
  }

  formatToCurrency(value) {
    const valueWithoutLetters = value.replace(/\D/g, "");

    if (!valueWithoutLetters || Number(valueWithoutLetters) === 0) {
      return "";
    }

    const valueDecimal = Number(valueWithoutLetters) / 100;
    const valueMoneyMask = new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2
    }).format(valueDecimal);

    return valueMoneyMask;
  }

  handleStatusRecurrenceDonation(status) {
    switch (status) {
      case "PENDING": {
        return "PENDENTE";
      }
      case "CONFIRMED": {
        return "CONFIRMADO";
      }
      case "REFUNDED": {
        return "ESTORNADO";
      }
      default:
        return "-";
    }
  }

  handleStatusRecurrence(status) {
    switch (status) {
      case 0: {
        return "PENDENTE";
      }
      case 1: {
        return "PAGO";
      }
      case 2: {
        return "FALHOU";
      }
      default:
        return "-";
    }
  }

  async loadRecurrence() {
    this.setState({ isLoading: true });

    try {
      const recurrence = await this.api.makeHttpRequest({
        url: `/donation/recurrence/${this.props.match.params.id}`
      });

      this.setState({
        idRecurrence: recurrence?.idRecurrence,
        accessKey: recurrence?.campaign?.accessKey,
        status: recurrence?.status,
        tabKey: recurrence?.status === 4 ? "donations" : "form",
        idUser: recurrence?.idUser,
        billing_date: recurrence.billingDay,
        value: this.formatToCurrency(String(recurrence?.value * 100))
      });

      this.setState({ isLoading: false });
      return recurrence;
    } catch (error) {
      Swal.fire({
        title: "Erro!",
        text:
          "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        icon: "error"
      });
    }

    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    await this.loadRecurrence();
  }

  setTransactions = donations => {
    this.setState({ donations });
  };

  afterSubmit = async () => {
    const recurrence = await this.loadRecurrence();

    this.setState({
      ...initialState,
      idRecurrence: recurrence?.idRecurrence,
      accessKey: recurrence?.campaign?.accessKey,
      status: recurrence?.status,
      idUser: recurrence?.idUser,
      billing_date: recurrence.billingDay,
      value: this.formatToCurrency(String(recurrence?.value * 100))
    });
  };

  submitPayment = async e => {
    e.preventDefault();

    if (!this.formPaymentIsValid()) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      const {
        value,
        name_card,
        number_card,
        cpf,
        expire_month,
        expire_year,
        cvv,
        idRecurrence
      } = this.state;

      const creditCardInfo = {
        type: "card",
        card: {
          number: number_card.replace(/\D/g, "").trim(),
          holder_name: name_card.toUpperCase(),
          holder_document: cpf.replace(/\D/g, "").trim(),
          exp_month: Number(expire_month),
          exp_year: Number(expire_year),
          cvv: cvv
        }
      };

      const cardTokenResponse = await generateCardToken(creditCardInfo);
      const valueToNumber = Number(value.replace(/\D/g, "")) / 100;

      const payload = {
        idUser: this.state?.idUser,
        accessKey: this.state?.accessKey,
        paymentMethod: {
          alias: name_card.toUpperCase(),
          idUser: this.state?.idUser,
          paymentType: 2, // CREDIT_CARD
          cardToken: cardTokenResponse.data.id,
          cvv: cvv
        },
        value: valueToNumber,
        billingDay: this.state.billing_date
      };

      await this.api.makeHttpRequest({
        url: `/donation/recurrence/${idRecurrence}`,
        method: "PUT",
        data: payload
      });

      Swal.fire({
        title: "Assinatura",
        text: "Assinatura atualizada com sucesso!",
        icon: "success"
      }).then(() => {
        this.afterSubmit();
      });
    } catch (e) {
      Swal.fire({
        title: "Erro!",
        text: "Erro ao atualizar a assinatura",
        icon: "error"
      });
    }

    this.setState({ isLoading: false });
  };

  handleChange = e => {
    if (e.target.name === "value") {
      this.setState({
        [e.target.name]: this.formatToCurrency(e.target.value)
      });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  isValidMonth() {
    const date = new Date();
    const isValidMonth =
      date.getMonth() + 1 === Number(this.state.expire_month) &&
      Number(this.state.expire_year) === date.getFullYear();
    return isValidMonth;
  }

  isInvalidValue(value) {
    return value && Number(value.replace(/\D/g, "")) / 100 < 10;
  }

  isInvalidName(name) {
    return name && !IsValidName(name);
  }

  isInvalidCardNumber(number_card) {
    return (
      number_card &&
      (isNaN(Number(number_card)) || !IsValidCardLength(number_card))
    );
  }

  isInvalidCPF(cpf) {
    return cpf && (isNaN(Number(cpf)) || !IsValidCpfCnpj(cpf)?.isValid);
  }

  isInvalidMonthExpire(expire_month) {
    return (
      expire_month &&
      (isNaN(Number(expire_month)) ||
        expire_month > 12 ||
        expire_month <= 0 ||
        this.isValidMonth())
    );
  }

  isInvalidYearExpire(expire_year) {
    return expire_year && !IsValidYear(Number(expire_year));
  }

  isInvalidCVV(cvv) {
    return cvv && isNaN(Number(cvv));
  }

  formPaymentIsValid = () => {
    const isValid =
      this.state.value !== "" &&
      !this.isInvalidValue(this.state.value) &&
      this.state.name_card !== "" &&
      !this.isInvalidName(this.state.name_card) &&
      this.state.number_card !== "" &&
      !this.isInvalidCardNumber(this.state.number_card) &&
      this.state.cpf !== "" &&
      !this.isInvalidCPF(this.state.cpf) &&
      this.state.expire_month !== "" &&
      !this.isInvalidMonthExpire(this.state.expire_month) &&
      this.state.expire_year !== "" &&
      !this.isInvalidYearExpire(this.state.expire_year) &&
      this.state.cvv !== "" &&
      !this.isInvalidCVV(this.state.cvv) &&
      this.state.billing_date !== "" &&
      !this.isInvalidBillingDate(this.state.billing_date);

    return isValid;
  };

  isInvalidBillingDate(billing_date) {
    return (
      billing_date &&
      (isNaN(Number(billing_date)) ||
        Number(billing_date) == 0 ||
        Number(billing_date) > 31)
    );
  }

  paymentAttemptsRowFormatter = r => {
    return (
      <StyledTableRow hover tabIndex={-1} key={r.idDonation}>
        <StyledTableCell scope="row">
          {Moment(r.billingDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.donation.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.donation.liquidValue)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">
            {this.handleStatusRecurrence(r.status)}
          </span>
        </StyledTableCell>
        <StyledTableCell scope="row">{r.attemptNumber}</StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">
            {this.handleStatusRecurrenceDonation(r.donation.status)}
          </span>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  async inactiveRecurrence() {
    Swal.fire({
      title: "Assinatura",
      icon: "warning",
      text: "Você deseja realmente cancelar essa assinatura?",
      confirmButtonText: "Sim, cancelar!",
      showCancelButton: true,
      cancelButtonText: "Não"
    }).then(async value => {
      if (value.value) {
        this.setState({ isLoading: true });

        try {
          await this.api.makeHttpRequest({
            url: `/donation/recurrence/cancel/${this.state.idRecurrence}`,
            method: "delete"
          });

          Swal.fire({
            title: "Assinatura",
            icon: "success",
            text: "Assinatura cancelada!"
          }).then(() => {
            this.props.history.push("/admin/assinaturas");
          });
        } catch (e) {
          Swal.fire({
            title: "Assinatura",
            icon: "error",
            text: e?.response?.data
              ? e.response.data
              : "Ocorreu um erro ao cancelar assinatura, tente novamente mais tarde"
          });
        }

        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    window.setPageTitle("Atualizar assinatura - Admin");

    const transactionHeadRows = [
      { column: "Transaction.billingDate", label: "Data da cobrança" },
      { column: "Donation.Value", label: "Valor" },
      { column: "Donation.LiquidValue", label: "Valor Liquido" },
      { column: "Recurrence.Status", label: "Status" },
      { column: "AttemptNumber", label: "Número da tentativa" },
      { column: "Donation.Status", label: "Status da doação" }
    ];

    const {
      donations,
      isLoading,
      tabKey,
      billing_date,
      value,
      name_card,
      number_card,
      cpf,
      expire_month,
      expire_year,
      cvv
    } = this.state;

    const formCard = (
      <>
        <Card>
          <StyledCardBody>
            <Form className="form-admin">
              <>
                <Form.Row>
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      name="billing_date"
                      label="Dia de pagamento"
                      value={billing_date}
                      placeholder="Dia de pagamento"
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidBillingDate(billing_date)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
              </>
            </Form>
          </StyledCardBody>
        </Card>
      </>
    );

    const formPayment = (
      <>
        <div className="mt-2"></div>

        <Card>
          <CardTitle>Pagamento</CardTitle>
          <StyledCardBody>
            <Form className="form-payment w-100">
              <>
                <Form.Row>
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      name="value"
                      label="Valor da assinatura"
                      value={value}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidValue(value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      name="name_card"
                      label="Nome Titular"
                      value={name_card}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidName(name_card)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      name="number_card"
                      label="Número do Cartão"
                      value={number_card}
                      maxLength={19}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidCardNumber(number_card)}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      name="cpf"
                      label="CPF"
                      value={cpf}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidCPF(cpf)}
                      required
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      name="expire_month"
                      label="Mês de expiração"
                      value={expire_month}
                      maxLength={2}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidMonthExpire(expire_month)}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      name="expire_year"
                      label="Ano de expiração"
                      maxLength={4}
                      value={expire_year}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidYearExpire(expire_year)}
                      required
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      name="cvv"
                      label="Cód. de segurança"
                      value={cvv}
                      onChange={this.handleChange}
                      isInvalid={this.isInvalidCVV(cvv)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
              </>
            </Form>
          </StyledCardBody>
        </Card>
      </>
    );

    const donationsListing = (
      <Card className="flex-grow-1">
        <StyledCardBody>
          <Datatable
            title="Doações"
            icon={<BiDonateHeart color="#4d4d4d" size="26px" />}
            endpoint={`/donation/recurrence/${this.props.match.params.id}/payed/paged`}
            paramsId={this.props.match.params.id}
            className="h-100"
            headRows={transactionHeadRows}
            formatRow={this.paymentAttemptsRowFormatter}
            rows={donations}
            setRows={this.setTransactions}
            reload={this.state.reload}
            width={this.props.width}
            hiddenReload
          />
        </StyledCardBody>
      </Card>
    );

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da assinatura" disableHeader />
        <TabsContainer>
          <Tabs
            activeKey={tabKey}
            transition={false}
            onSelect={k => this.setState({ tabKey: k })}
            className="mt-3"
          >
            {this.state.status === 1 && (
              <Tab eventKey="form" title="Assinatura">
                {formCard}
                {formPayment}
                <div className="text-right" style={{ marginTop: "24px" }}>
                  {this.state.status !== 4 && (
                    <Button
                      variant="secondary"
                      color="#343877"
                      type="button"
                      onClick={this.inactiveRecurrence}
                      style={{
                        width: "fit-content",
                        padding: "14px 8px",
                        marginRight: "8px"
                      }}
                    >
                      Desativar assinatura
                    </Button>
                  )}

                  <Button
                    variant="primary"
                    type="submit"
                    color="#343877"
                    onClick={this.submitPayment}
                    disabled={!this.formPaymentIsValid()}
                    title={
                      this.formPaymentIsValid()
                        ? "Salvar dados"
                        : "Verifique os campos"
                    }
                  >
                    <span className="text-light">Salvar</span>
                  </Button>
                </div>
              </Tab>
            )}
            <Tab eventKey="donations" title="Cobranças">
              {donationsListing}
            </Tab>
          </Tabs>
        </TabsContainer>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(RecurrencePaymentForm);
