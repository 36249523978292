/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import ModalAlterPassword from "./components/ModalAlterPassword/index";
import Swal from "sweetalert2";
import "./style.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import EnterIcon from "../../assets/icons/icon-enter.svg";
import ConfigIcon from "../../assets/icons/icon-settings.svg";
import LockerIcon from "../../assets/icons/icon-locker.svg";
import ExitIcon from "../../assets/icons/icon-exit.svg";
import { bindActionCreators } from "redux";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      statusModal: false,
      viewModal: false,
      sucess: false,
      disabledClickOut: false
    };
  }

  showMensage(sucess, error) {
    if (error) {
      Swal.fire(
        "Erro!",
        error ? error : "Erro ao alterar o status, tente novamente mais tarde.",
        "error"
      );
    } else if (sucess) {
      Swal.fire({
        title: "Sucesso!",
        text: "Senha alterada com sucesso.",
        icon: "success",
        showCancelButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Sair"
      });
    }
  }

  render() {
    const { user, showHi, showBadge, history } = this.props;
    return (
      <>
        <ModalAlterPassword
          open={this.state.statusModal}
          setOpen={e => this.setState({ statusModal: e })}
          bg="white"
          result={(sucess, error) => {
            this.showMensage(sucess, error);
            this.setState({ statusModal: false });
          }}
        />

        <Dropdown
          className="kt-header__topbar-item kt-header__topbar-item--user"
          drop="down"
          alignRight
          style={{ cursor: "pointer" }}
        >
          <Dropdown.Toggle
            as={HeaderDropdownToggle}
            id="dropdown-toggle-user-profile"
          >
            <div className="kt-header__topbar-user">
              {showHi && (
                <span className="kt-header__topbar-welcome kt-hidden-mobile">
                  Olá,
                </span>
              )}

              {showHi && (
                <span className="kt-header__topbar-username kt-hidden-mobile">
                  {user?.name}
                </span>
              )}

              {showBadge && (
                <span className="kt-badge kt-badge--username kt-badge--unified-mainBlue kt-badge--lg kt-badge--rounded kt-badge--bold">
                  {user && user.name[0].toUpperCase()}
                </span>
              )}
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl"
            style={{
              width: "329px",
              boxShadow:
                "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
            }}
          >
            {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
            <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
              <div className="kt-user-card__avatar">
                <BsPerson strokeWidth=".4px" size="30px" color="#343877" />
              </div>
              <div className="kt-user-card__name">Olá, {user?.name}</div>
            </div>
            <div className="kt-notification">
              {user && user?.role?.name !== "PROSPECT" && (
                <>
                  {!history.location.pathname.includes("/admin") && (
                    <>
                      <div className="dropdown-divider m-0"></div>

                      <div
                        className="col-12 p-4 option-user-profile"
                        onClick={() => history.push("/admin")}
                      >
                        <img src={EnterIcon} className="mr-2" alt="" />
                        <div className="col-10 kt-font-inverse-optionSelected">
                          <div>
                            <strong className="kt-font-boldest">
                              Acesso interno
                            </strong>
                          </div>
                          <div>Acessar a área administrativa</div>
                        </div>
                        <div className="ml-2">
                          <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="dropdown-divider m-0"></div>

                  <div
                    className="col-12 p-4 option-user-profile"
                    onClick={() => history.push("/admin/meus-dados")}
                  >
                    <img src={ConfigIcon} className="mr-2" alt="" />
                    <div className="col-10 kt-font-inverse-optionSelected">
                      <div>
                        <strong>Editar Conta</strong>
                      </div>
                      <div>Alterar informações da conta</div>
                    </div>
                    <div className="ml-2">
                      <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                    </div>
                  </div>
                </>
              )}

              <div className="dropdown-divider m-0"></div>

              <div
                className="col-12 p-4 option-user-profile"
                onClick={() => this.setState({ statusModal: true })}
              >
                <img src={LockerIcon} className="mr-2" alt="" />
                <div className="col-10 kt-font-inverse-optionSelected">
                  <div>
                    <strong>Minha Senha</strong>
                  </div>
                  <div>Alterar a senha de acesso</div>
                </div>
                <div className="ml-2">
                  <NavigateNextIcon style={{ color: "#4C4C4C" }} />
                </div>
              </div>

              <div className="dropdown-divider m-0"></div>

              <div
                className="col-12 p-4 option-user-profile"
                onClick={() => {
                  this.setState({ statusModal: true });
                  this.props.logout();
                }}
              >
                <img src={ExitIcon} className="mr-2" alt="" />
                <div>
                  <strong className="kt-font-mainRed">Sair</strong>
                </div>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const mapActionsToProps = dispatch =>
  bindActionCreators({ logout: auth.actions.logout }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(UserProfile)
);
