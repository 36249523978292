import React from "react";
import Footer from "../../components/Footer";
import { Container } from "./styles";

export const PrivacyPolicyPage = () => {
  return (
    <>
      <Container>
        <h1 className="heading__title">Política de Privacidade</h1>

        <p>
          A MeuCofrinho está comprometida com a privacidade e confidencialidade
          das informações compartilhadas pelos Participantes para utilização da
          plataforma MeuCofrinho e dos serviços.
        </p>

        <p>
          Todo o processamento de dados pessoais realizado pela MeuCofrinho
          cumprirá todos os requisitos e obrigações da legislação aplicável,
          incluindo, mas sem limitação, a Lei nº 13.709 de 14 de agosto de 2018
          – Lei Geral de Proteção de Dados.
        </p>

        <p>
          Ao utilizar a plataforma MeuCofrinho e/ou os serviços, o Participante
          automaticamente concorda com a forma de processamento de dados
          pessoais estabelecida no presente instrumento.
        </p>

        <p>
          A MeuCofrinho realizará o processamento dos dados pessoais dos
          Participantes de acordo com o estabelecido no presente instrumento.
        </p>

        <table>
          <thead>
            <tr>
              <th>Fonte de Coleta</th>
              <th>Tipo de Dados Pessoal e Finalidade</th>
              <th>Base Legal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Cadastro na Plataforma MeuCofrinho</p>
              </td>

              <td>
                <p>
                  Dados Cadastrais: (i) nome completo; (ii) e-mail; (iii) CPF;
                  (v) data de nascimento; (vi) telefone; (vii) localização;
                  (viii) senha; e, (viii) dados bancários.
                </p>

                <p>
                  Finalidades: i) Dados essenciais para o cadastro do
                  Participante na plataforma MeuCofrinho para utilização dos
                  serviços; (ii) verificação de banco de dados de terceiros e
                  prevenção contra fraudes e lavagem de dinheiro; e (iii) envio
                  de mailing e newsletter.
                </p>
              </td>

              <td>
                <p>
                  i) cumprimento de um contrato ou de procedimento preliminar;
                  ii) proteção do crédito; e, iii) consentimento.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Utilização da plataforma MeuCofrinho e/ou dos serviços</p>
              </td>
              <td>
                <p>
                  Dados Bancários: (i) nome, (ii) CPF; (iii) conta corrente;
                  (iv) agência bancária; (v) instituição financeira; (vi) valor
                  doado ou valor recebido.
                </p>

                <p>
                  Finalidades: (i) realizar ou receber doações; (ii) prevenção à
                  fraude; e (iii) restituição de valores doados.
                </p>
              </td>

              <td>
                <p>
                  i) cumprimento de um contrato ou de procedimento preliminar;
                </p>

                <p>ii) proteção ao crédito; e,</p>

                <p>iii) cumprimento de obrigação legal ou regulatória.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Utilização da plataforma MeuCofrinho e/ou dos serviços</p>
              </td>

              <td>
                <p>Dados de Navegação: (i) cookies;</p>

                <p>
                  Finalidades: (i) viabilizar a utilização da Plataforma
                  MeuCofrinho pelo Participante; e ii) melhorar a experiência do
                  Participante.
                </p>
              </td>

              <td>
                <p>
                  i) cumprimento de um contrato ou de procedimento preliminar;
                </p>

                <p>ii) consentimento.</p>
              </td>
            </tr>

            <tr>
              <td>
                <p>Cadastro na Plataforma MeuCofrinho</p>
              </td>
              <td>
                <p>
                  Dados de Marketing: (i) Nome; (ii) telefone; e (iii) e-mail;
                </p>

                <p>
                  Finalidades: (i) envio de informações sobre a Plataforma a
                  título de marketing e prospecção de doadores.
                </p>
              </td>

              <td>
                <p>(i) consentimento.</p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          A MeuCofrinho coletará apenas os dados essenciais para viabilizar a
          utilização da plataforma MeuCofrinho, de modo que sem referidos dados
          pessoais não é possível utilizar a plataforma MeuCofrinho. Para os
          dados que não são essenciais, a MeuCofrinho coletará seu
          consentimento.
        </p>

        <p>
          Os dados pessoais dos Participantes não serão utilizados pela
          MeuCofrinho para finalidades diversas das estabelecidas no presente
          instrumento.
        </p>

        <p>
          A MeuCofrinho poderá compartilhar os dados pessoais com instituições
          financeiras, instituições de meio de pagamento e órgãos públicos que
          devam ter acesso aos dados pessoais para viabilizar a utilização da
          plataforma ou para cumprimento de obrigação legal ou regulatória.
        </p>

        <p>
          O compartilhamento dos dados pessoais com terceiros será realizado nos
          termos e limites estabelecidos pela Lei Geral de Proteção de Dados e
          todos os terceiros estarão vinculados ao cumprimento de medidas não
          menos restritivas do que as estabelecidas no presente instrumento.
        </p>

        <p>
          Os dados pessoais dos Participantes serão mantidos pela MeuCofrinho
          somente pelo tempo que for necessário para cumprir com as finalidades
          para as quais os coletamos, inclusive para fins de cumprimento de
          quaisquer obrigações legais, contratuais, ou requisição de autoridades
          competentes.
        </p>

        <p>
          A MeuCofrinho não aluga, vende ou transfere os dados pessoais dos
          Participantes de forma onerosa ou para finalidade diversa da
          estabelecida no presente instrumento.
        </p>

        <p>
          A MeuCofrinho se esforça para proteger a privacidade da conta e dos
          dados pessoais dos Participantes. Contudo, entrada ou uso não
          autorizado de conta, falha de hardware ou software e outros fatores
          podem comprometer a segurança dos seus dados pessoais mesmo com a
          utilização das medidas de segurança, por isso, o Participante deve
          adotar medidas de segurança. Além de adotar boas práticas de segurança
          em relação a sua conta e aos seus dados, caso o Participante
          identifique ou tome conhecimento de algo que comprometa a segurança
          dos seus dados, o Participante pode entrar em contato com a
          MeuCofrinho por meio das formas de contato estabelecidas neste
          instrumento.
        </p>

        <p>
          Cada Participante Arrecadador terá um perfil público, com informações
          como nome, localização, tempo de cadastro na plataforma MeuCofrinho,
          quantidade de Campanhas de Arrecadação realizadas e quantidade de
          redes sociais, o qual será vinculado à Campanha de Arrecadação, de
          modo que concorda que referidos dados pessoais sejam disponibilizados
          de forma pública na plataforma MeuCofrinho.
        </p>

        <p>
          O Participante se declara ciente que nenhum dado pessoal, com exceção
          dos dados constantes no perfil público, será compartilhado de forma
          pública na plataforma pela MeuCofrinho. A plataforma MeuCofrinho
          possui funcionalidades que permitem que o Participante disponibilize
          de forma pública informações gerais a respeito da Campanha de
          Arrecadação. Eventuais dados pessoais do perfil público e do
          Participante ou de terceiros disponibilizados pelo Participante nas
          informações gerais da Campanha de Arrecadação não estarão submetidos à
          confidencialidade e privacidade estabelecidas no presente instrumento.
          O Participante será exclusivamente responsável pelos dados pessoais e
          informações que compartilhar na plataforma MeuCofrinho, de modo que a
          MeuCofrinho não poderá ser responsabilizada por qualquer uso de
          referidos dados pessoais e informações por terceiros.
        </p>

        <p>
          Os Participantes poderão exercer seus direitos previstos na LGPD em
          relação aos dados pessoais compartilhados com a MeuCofrinho, quais
          sejam:
        </p>

        <ul>
          <li>
            <p>Direito de acesso;</p>
          </li>
          <li>
            <p>Direito de retificação;</p>
          </li>
          <li>
            <p>Direito de exclusão;</p>
          </li>
          <li>
            <p>Direito de confirmação sobre a existência de tratamento;</p>
          </li>
          <li>
            <p>Direito de solicitar o bloqueio ou eliminação;</p>
          </li>
          <li>
            <p>Direito à informação das entidades públicas ou privadas;</p>
          </li>
          <li>
            <p>Direito de restringir o processamento;</p>
          </li>
          <li>
            <p>Direito à revisão de decisão automatizada;</p>
          </li>
          <li>
            <p>Direito de oposição a um tratamento;</p>
          </li>
          <li>
            <p>
              Direito à explicação da lógica por trás da coleta dos seus dados;
            </p>
          </li>
          <li>
            <p>
              Direito à informação sobre a possibilidade de não fornecer
              consentimento e sobre as consequências da negativa;
            </p>
          </li>
          <li>
            <p>Direito de retirar o seu consentimento.</p>
          </li>
        </ul>

        <p>
          Para exercícios dos direitos, esclarecimentos e contato em geral a
          respeito da privacidade de dados pessoais, o Participante poderá
          entrar em contato com a MeuCofrinho por meio do e-mail: [
          <a href="mailto:contato@meucofrinho.com">contato@meucofrinho.com</a>
          ].
        </p>
      </Container>
      <Footer />
    </>
  );
};
