import React from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import axios from "axios";
import { AppAPIService } from "../../../../services/AppAPIService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import formatTel from "../../../../utils/FormatTel";
import { AdminTitle } from "../../components/AdminTitle";
import verifyCpfCnpj, {
  formatCPF
} from "../../../../utils/validators/IsValidCpfCnpj";
import formatCEP from "../../../../utils/FormatCep";

const initialState = {
  id: 0,
  status: "",

  profile: "",
  tipoPessoa: "",

  nome: "",
  razaoSocial: "",
  cpf: "",
  cnpj: "",
  telefone: "",
  email: "",

  CEP: "",
  endereco: "",
  estado: "",
  cidade: "",
  bairro: "",

  codigoPais: "+55",

  resetInput: false,
  isLoading: false
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.userAvatar = React.createRef();
    this.state = {
      ...initialState,
      profiles: [],
      estados: [],
      cidades: [],
      statusOptions: [],
      profileLogged: "",
      userLogged: "",
      disableProfile: false,
      isEditing: false
    };

    this.api = AppAPIService.getInstance();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadUFs = this.loadUFs.bind(this);
    this.loadCidades = this.loadCidades.bind(this);
    this.loadProfiles = this.loadProfiles.bind(this);
    this.loadStatus = this.loadStatus.bind(this);
    this.getAddresByZipcode = this.getAddresByZipcode.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    const user = this.props.user;
    this.setState({
      isLoading: true,
      isEditing,
      profileLogged: user?.role?.name,
      userLogged: user
    });

    try {
      const reqs = [
        this.loadProfiles(),
        this.loadStatus(),
        // this.loadRegimes(),
        this.loadUFs()
      ];

      if (this.props.match.params.id) {
        reqs.push(this.loadUser(this.props.match.params.id));
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadUser = async id => {
    const user = await this.api.makeHttpRequest({
      url: `/user/${id}`
    });

    if (user.state) {
      this.loadCidades(user.state);
    }

    this.setState({
      id: id,
      status: user.profileStatus,

      profile: user.idUserRole,
      tipoPessoa: user.type ? `${user.type}` : "",

      nome: user.name,
      razaoSocial: user.type === 2 ? user.companyName : "",
      cnpj:
        user.type === 2 && user.documentResponsable
          ? formatCPF(user.documentResponsable)
          : "",
      cpf: user.document ? formatCPF(user.document) : "",
      telefone: user.phone ? formatTel(user.phone) : "",
      email: user.email,
      CEP: user.zipCode ? formatCEP(user.zipCode) : "",
      endereco: user.address ?? "",
      estado: user.state ?? "",
      cidade: user.city ?? "",
      bairro: user.neighborhood ?? "",

      resetInput: false,
      isLoading: false
    });
  };

  loadProfiles = async () => {
    const profiles = await this.api.makeHttpRequest({
      url: "/user/roles"
    });

    this.setState({
      profiles
    });

    if (profiles.length === 1) {
      this.setState({
        profile: profiles.name,
        disableProfile: true
      });
    }
  };

  loadUFs = async () => {
    const { data } = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
    );

    this.setState({ estados: data });
  };

  loadCidades = async estado => {
    const { data } = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios?orderBy=nome`
    );

    this.setState({ cidades: data });
  };

  loadStatus = async () => {
    const statusList = await this.api.makeHttpRequest({
      url: "/user/status"
    });

    this.setState({
      statusOptions: statusList ?? []
    });
  };

  async getAddresByZipcode(zipcode) {
    try {
      const { data } = await axios.get(
        "https://ws.apicep.com/cep.json?code=" + zipcode
      );

      if (data.status === 200) {
        this.loadCidades(data.state);

        this.setState({
          endereco: data.address,
          estado: data.state,
          bairro: data.district,
          cidade: data.city
        });
      } else {
        Swal.fire(
          "Erro!",
          data?.message ? data.message : "Erro ao fazer busca via CEP",
          "error"
        );
      }
    } catch (e) {
      console.log("Erro ao fazer busca via CEP: ", e);
    }
  }

  submit = async e => {
    e.preventDefault();

    const { isEditing } = this.state;

    if (!isEditing) {
      const { value } = await Swal.fire({
        title: "Atenção!",
        html:
          "<p>Ao confirmar, você estará concordando com os <a href='/terms' target='_blank' rel='noopener noreferrer'><strong>termos e condições</strong></a> e confirmando que o usuário ter <strong>no mínimo 18 anos</strong> ou estar <strong>legalmente representado</strong>.</p>",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Sair"
      });

      if (!value) {
        return;
      }
    }

    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/user/${id}` }
        : { method: "POST", url: "/user" };

      const rawPhone =
        this.state.telefone && this.state.telefone.replace(/\D/g, "");

      const rawCNPJ = this.state.cnpj ? this.state.cnpj.replace(/\D/g, "") : "";
      const rawCPF = this.state.cpf ? this.state.cpf.replace(/\D/g, "") : "";

      const document = this.state.tipoPessoa === "2" ? rawCNPJ : rawCPF;

      const rawZipcode = this.state.CEP
        ? this.state.CEP.replace(/\D/g, "")
        : "";

      const payload = {
        idUserRole: this.state.profile,
        type: this.state.tipoPessoa,
        name: this.state.nome,
        email: this.state.email,
        phone: rawPhone,
        phoneCountryCode: this.state.codigoPais,
        zipcode: rawZipcode,
        address: this.state.endereco,
        state: this.state.estado,
        city: this.state.cidade,
        neighborhood: this.state.bairro,
        document: document,
        documentResponsable: this.state.tipoPessoa === "2" ? rawCPF : null,
        razaoSocial: "",
        companyName:
          this.state.tipoPessoa === "2" ? this.state.razaoSocial : "",

        // só serão enviados na criação do usuário
        legalAgeConirmation: !isEditing ? true : false,
        termsAcceptanceDate: !isEditing ? new Date() : null,

        profileStatus: isEditing ? this.state.status : "",

        isFromAdmin: true
      };

      // console.log(payload);

      await this.api.makeHttpRequest({
        ...reqBase,
        data: payload
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar usuário, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/usuarios");
    }
  };

  handleChange = e => {
    // eslint-disable-next-line default-case
    switch (e.target.name) {
      case "tipoPessoa":
        this.setState({ [e.target.name]: e.target.value, documento: "" });
        return;
      case "telefone":
        this.setState({
          [e.target.name]: formatTel(e.target.value)
        });
        return;
      case "cpf":
        this.setState({
          [e.target.name]: formatCPF(e.target.value)
        });
        return;
      case "cnpj":
        this.setState({
          [e.target.name]: formatCPF(e.target.value)
        });
        return;
      case "estado":
        this.loadCidades(e.target.value);
        break;
      case "CEP":
        const rawValue = e.target.value.replace(/\D/g, "");
        if (rawValue.length === 8) this.getAddresByZipcode(rawValue);
        this.setState({
          [e.target.name]: formatCEP(e.target.value)
        });
        return;
    }

    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formIsValid = () => {
    const validFirst =
      !!this.state.profile &&
      !!this.state.tipoPessoa &&
      !!this.state.nome &&
      IsValidName(this.state.nome) &&
      !!this.state.telefone &&
      IsValidPhone(this.state.telefone) &&
      !!this.state.email &&
      IsValidEmail(this.state.email) &&
      !!this.state.cpf &&
      this.state.cpf.replace(/\D/g, "").length === 11 &&
      verifyCpfCnpj(this.state.cpf).isValid &&
      !!this.state.CEP &&
      this.state.CEP.replace(/\D/g, "").trim().length === 8 &&
      !!this.state.bairro &&
      !!this.state.cidade &&
      !!this.state.endereco &&
      !!this.state.estado &&
      (this.state.isEditing ? !!this.state.status : true);

    const validPj =
      validFirst &&
      !!this.state.cnpj &&
      this.state.cnpj.replace(/\D/g, "").length === 14 &&
      verifyCpfCnpj(this.state.cnpj).isValid &&
      !!this.state.razaoSocial &&
      IsValidName(this.state.razaoSocial);

    return this.state.tipoPessoa === "2" ? validPj : validFirst;
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadUser(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Usuário - Admin");
    } else {
      window.setPageTitle("Cadastro de Usuário - Admin");
    }

    const {
      id,
      status,

      profile,
      tipoPessoa,
      nome,
      razaoSocial,
      cpf,
      cnpj,
      telefone,
      email,

      CEP,
      endereco,
      estado,
      cidade,
      bairro,

      profiles,
      estados,
      cidades,
      statusOptions,

      profileLogged,
      userLogged,
      disableProfile,
      isEditing,
      isLoading
    } = this.state;

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Profissional" disableHeader />
        <Card className="mt-3">
          <StyledCardBody>
            <Form className="form-admin">
              <h1 className="card-title">Identificação</h1>
              {isEditing && profileLogged === "ADMIN" && (
                <Form.Row>
                  {/* Status */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      as="select"
                      name="status"
                      label="Status"
                      value={status}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Selecione o status</option>
                      {statusOptions?.length > 0 &&
                        statusOptions.map(p => (
                          <option key={p.index} value={p.index}>
                            {p.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                </Form.Row>
              )}

              {/* row 1 */}
              <Form.Row>
                {/* Perfil */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select"
                    name="profile"
                    label="Perfil"
                    value={profile}
                    disabled={
                      (profileLogged !== "ADMIN" && isEditing) ||
                      (profileLogged !== "ADMIN" &&
                        userLogged?.idUser !== id) ||
                      disableProfile
                    }
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione o perfil</option>
                    {profiles?.length > 0 &&
                      profiles.map(p => (
                        <option key={p.id} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {/* Tipo de conta */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    as="select"
                    name="tipoPessoa"
                    label="Tipo de conta"
                    value={tipoPessoa}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione o tipo de conta</option>
                    <option value="1">Pessoa Física</option>
                    <option value="2">Pessoa Jurídica</option>
                  </InputField>
                </Form.Group>

                {/* Pessoa Jurídica */}
                {tipoPessoa && tipoPessoa === "2" && (
                  <>
                    {/* CNPJ */}
                    <Form.Group as={Col} lg="3" xs="12">
                      <InputField
                        type="text"
                        name="cnpj"
                        label="CNPJ"
                        value={cnpj}
                        maxLength={18}
                        placeholder="Digite o CNPJ"
                        onChange={this.handleChange}
                        isInvalid={
                          cnpj &&
                          (!verifyCpfCnpj(cnpj).isValid ||
                            cnpj.replace(/\D/g, "").length !== 14)
                        }
                        required
                      />
                    </Form.Group>

                    {/* Razão Social */}
                    <Form.Group as={Col} lg="3" xs="12">
                      <InputField
                        type="text"
                        name="razaoSocial"
                        label="Razão Social"
                        value={razaoSocial}
                        placeholder="Digite a razão social"
                        onChange={this.handleChange}
                        isInvalid={razaoSocial && !IsValidName(razaoSocial)}
                        required
                      />
                    </Form.Group>
                  </>
                )}
              </Form.Row>

              {/* row 2 */}
              <Form.Row>
                {/* Nome */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="nome"
                    label="Nome"
                    value={nome}
                    placeholder="Digite o nome"
                    onChange={this.handleChange}
                    isInvalid={nome && !IsValidName(nome)}
                    required
                  />
                </Form.Group>

                {/* CPF */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="text"
                    name="cpf"
                    label="CPF"
                    value={cpf}
                    maxLength={14}
                    placeholder="Digite o CPF"
                    onChange={this.handleChange}
                    isInvalid={
                      cpf &&
                      (!verifyCpfCnpj(cpf).isValid ||
                        cpf.replace(/\D/g, "").length !== 11)
                    }
                    required
                  />
                </Form.Group>

                {/* E-mail */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="email"
                    name="email"
                    label="E-mail"
                    value={email}
                    placeholder="Digite o email"
                    onChange={this.handleChange}
                    isInvalid={email && !IsValidEmail(email)}
                    disabled={isEditing}
                    required
                  />
                </Form.Group>

                {/* Telefone */}
                <Form.Group as={Col} lg="3" xs="12">
                  <InputField
                    type="tel"
                    name="telefone"
                    label="Telefone"
                    value={telefone}
                    maxLength={15}
                    placeholder="Digite o telefone com DDD"
                    onChange={this.handleChange}
                    isInvalid={telefone && !IsValidPhone(telefone)}
                    required
                    prefix="+55"
                  />
                </Form.Group>
              </Form.Row>

              {/* row 3 */}
              <Form.Row>
                {/* CEP */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="CEP"
                    label="CEP"
                    value={CEP}
                    placeholder="Digite o CEP"
                    onChange={this.handleChange}
                    maxLength={10}
                    isInvalid={
                      !!CEP ? CEP.replace(/\D/g, "").trim().length !== 8 : false
                    }
                    required
                  />
                </Form.Group>

                {/* Endereço */}
                <Form.Group as={Col} lg="8" xs="12">
                  <InputField
                    type="text"
                    name="endereco"
                    label="Endereço"
                    value={endereco}
                    placeholder="Digite o endereço (rua, avenida, praça etc)"
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
              </Form.Row>

              {/* row 4 */}
              <Form.Row>
                {/* Estado */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select"
                    name="estado"
                    label="Estado"
                    value={estado}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione o estado</option>
                    {estados?.length > 0 &&
                      estados.map(p => (
                        <option key={p.id} value={p.sigla}>
                          {p.sigla}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {/* Cidade */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    as="select"
                    name="cidade"
                    label="Cidade"
                    value={cidade}
                    onChange={this.handleChange}
                    required
                  >
                    <option value="">Selecione a cidade</option>
                    {cidades?.length > 0 &&
                      cidades.map(p => (
                        <option key={p.id} value={p.nome}>
                          {p.nome}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>

                {/* Bairro */}
                <Form.Group as={Col} lg="4" xs="12">
                  <InputField
                    type="text"
                    name="bairro"
                    label="Bairro"
                    value={bairro}
                    placeholder="Digite o bairro"
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          module="usuarios"
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(UserForm);
