import React from "react";
import Swal from "sweetalert2";
import { IconButton } from "@material-ui/core";
import { Col, Form, Modal } from "react-bootstrap";
import { BiCategoryAlt } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";
import Datatable from "../../../../partials/datatable/Datatable";
import { AppAPIService } from "../../../../services/AppAPIService";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Loading from "../../../home/components/Loading";
import { AdminButton } from "../../components/AdminButton";
import { InputField } from "../../components/InputField";
import { EditIcon } from "../../../../components/IconsSVG";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import {
  ContainerFormCategory,
  StyledModalEdit,
  PreviewColor,
  Separator,
  TableActions
} from "./styles";

const initialState = {
  reload: false,
  loading: false,
  categories: [],
  categoryText: "",
  categoryColor: "",
  categoryTextError: false,
  categoryColorError: false,
  showModalEditCategory: false,
  categoryEditText: "",
  categoryEditColor: "",
  categoryEditId: null,
  categoryEditTextError: false,
  categoryEditColorError: false
};

class CategoriesPage extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.formatRow = this.formatRow.bind(this);
  }

  handleConfirmDelete = async id => {
    try {
      this.setState({ loading: true });

      await this.api.makeHttpRequest({
        method: "DELETE",
        url: `/campaign/category/${id}`
      });

      this.setState({ loading: false });

      Swal.fire({
        title: "Sucesso!",
        text: "Categoria deletada com sucesso.",
        icon: "success",
        cancelButtonText: "Sair"
      }).then(e => {
        this.setState({ reload: !this.state.reload });
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire(
        "Categoria",
        e?.response?.data || "Erro ao deletar categoria",
        "error"
      );
    }
  };

  setCategories = categories => {
    this.setState({ categories });
  };

  handleSubmitCategoryCreate = async e => {
    e.preventDefault();

    const { categoryColor, categoryText, categories } = this.state;

    if (!categoryColor || !categoryText) {
      Swal.fire({
        title: "Categoria",
        text: "Por favor preencha cor e/ou nome da categoria",
        icon: "warning",
        cancelButtonText: "Sair"
      });

      this.setState({
        categoryColorError: !categoryColor,
        categoryTextError: !categoryText
      });
      return;
    }

    const categoryNameExists = categories.find(
      category => category?.name.trim() === categoryText.trim()
    );

    if (categoryNameExists) {
      Swal.fire({
        title: "Categoria",
        text: "Nome da categoria já existe.",
        icon: "error",
        cancelButtonText: "Sair"
      });

      this.setState({
        categoryTextError: true
      });
      return;
    }

    try {
      this.setState({ loading: true });

      const payload = {
        Name: categoryText,
        Color: categoryColor
      };

      await this.api.makeHttpRequest({
        method: "POST",
        url: "/campaign/category",
        data: payload
      });

      this.setState({ loading: false });
      Swal.fire({
        title: "Categoria",
        text: "Categoria criada com sucesso!",
        icon: "success"
      }).then(e => {
        this.setState({
          categoryText: "",
          categoryColor: "",
          reload: !this.state.reload
        });
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire({
        title: "Categoria",
        text: e?.response?.data || "Ocorreu um erro ao criar categoria.",
        icon: "error",
        cancelButtonText: "Sair"
      });
    }
  };

  handleSubmitCategoryEdit = async e => {
    e.preventDefault();

    const { categoryEditColor, categoryEditText, categoryEditId } = this.state;

    if (!categoryEditColor || !categoryEditText) {
      Swal.fire({
        title: "Categoria",
        text: "Por favor preencha cor e/ou nome da categoria",
        icon: "warning",
        cancelButtonText: "Sair"
      });

      this.setState({
        categoryEditColorError: !categoryEditColor,
        categoryEditTextError: !categoryEditText
      });
      return;
    }

    try {
      this.setState({ showModalEditCategory: false, loading: true });

      const payload = {
        name: categoryEditText,
        color: categoryEditColor,
        idCampaignCategory: categoryEditId
      };

      await this.api.makeHttpRequest({
        method: "PUT",
        url: "/campaign/category",
        data: payload
      });

      this.setState({ loading: false });
      Swal.fire({
        title: "Categoria",
        text: "Categoria atualizada com sucesso!",
        icon: "success"
      }).then(e => {
        this.setState({
          showModalEditCategory: false,
          reload: !this.state.reload
        });
      });
    } catch (e) {
      this.setState({ loading: false });
      Swal.fire({
        title: "Categoria",
        text: e?.response?.data || "Ocorreu um erro ao editar categoria",
        icon: "error",
        cancelButtonText: "Sair"
      }).then(e => {
        this.setState({ showModalEditCategory: true });
      });
    }
  };

  handleEditCategory = category => {
    this.setState({
      showModalEditCategory: true,
      categoryEditText: category.name,
      categoryEditColor: category.color,
      categoryEditId: category.idCampaignCategory
    });
  };

  formatRow = r => {
    return (
      <StyledTableRow hover tabIndex={-1} key={r.idCampaignCategory}>
        <StyledTableCell scope="row">
          <TableActions>
            <IconButton
              size="small"
              title="Editar registro"
              onClick={() => this.handleEditCategory(r)}
            >
              <EditIcon />
            </IconButton>
            <CrudActions
              actions={["delete"]}
              module="campaignCategories"
              onConfirmDelete={this.handleConfirmDelete}
              id={r.idCampaignCategory}
              obj={r}
            />
          </TableActions>
        </StyledTableCell>
        <StyledTableCell scope="row">{r.name}</StyledTableCell>
        <StyledTableCell scope="row">
          <span
            style={{
              color: r.color
            }}
          >
            {r.color}
          </span>
          <PreviewColor
            style={{
              backgroundColor: r.color
            }}
          />
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  render() {
    window.setPageTitle("Listagem de Categorias - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "category", label: "Categoria" },
      { column: "color", label: "Cor" }
    ];

    const {
      reload,
      loading,
      categories,
      categoryText,
      categoryColor,
      categoryTextError,
      categoryColorError,
      showModalEditCategory,
      categoryEditText,
      categoryEditColor,
      categoryEditTextError,
      categoryEditColorError
    } = this.state;

    return (
      <>
        <Loading isLoading={loading} />

        <Datatable
          title="Categorias"
          icon={<BiCategoryAlt color="#4d4d4d" size="26px" />}
          endpoint="/campaign/list-categories"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={categories}
          setRows={this.setCategories}
          reload={reload}
          placeholderSearch="Busque por categoria"
          width={this.props.width}
          hiddenReload
        />

        <Separator />

        <ContainerFormCategory onSubmit={this.handleSubmitCategoryCreate}>
          <h2>Criar uma nova categoria</h2>
          <Form.Row className="w-100">
            <Form.Group as={Col} xs="12" md="8">
              <InputField
                type="text"
                name="Categoria"
                label="Categoria"
                value={categoryText}
                placeholder="Digite um nome para categoria"
                onChange={e => {
                  this.setState({
                    categoryTextError: false,
                    categoryText: e.target.value
                  });
                }}
                maxLength={25}
                isInvalid={categoryTextError}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs="12" md="2">
              <InputField
                type="color"
                name="color"
                label="Cor"
                value={categoryColor}
                placeholder="Digite a cor para categoria."
                onChange={e => {
                  this.setState({
                    categoryColorError: false,
                    categoryColor: e.target.value
                  });
                }}
                isInvalid={categoryColorError}
                required
              />
            </Form.Group>
            <Form.Group as={Col} xs="4" md="2">
              <AdminButton
                type="submit"
                variant="filled"
                disabled={!categoryText || !categoryColor}
              >
                <IoMdAdd />
                <span>Cadastrar</span>
              </AdminButton>
            </Form.Group>
          </Form.Row>
        </ContainerFormCategory>

        <StyledModalEdit
          size="lg"
          show={showModalEditCategory}
          centered
          onHide={() => this.setState({ showModalEditCategory: false })}
        >
          <Modal.Body>
            <form onSubmit={this.handleSubmitCategoryEdit}>
              <h2>Editar categoria</h2>
              <Form.Row className="w-100">
                <Form.Group as={Col} xs="8">
                  <InputField
                    type="text"
                    name="Categoria"
                    label="Categoria"
                    value={categoryEditText}
                    placeholder="Digite um nome para categoria"
                    onChange={e => {
                      this.setState({
                        categoryEditTextError: false,
                        categoryEditText: e.target.value
                      });
                    }}
                    maxLength={25}
                    isInvalid={categoryEditTextError}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xs="4">
                  <InputField
                    type="color"
                    name="color"
                    label="Cor"
                    value={categoryEditColor}
                    placeholder="Digite a cor para categoria."
                    onChange={e => {
                      this.setState({
                        categoryEditColorError: false,
                        categoryEditColor: e.target.value
                      });
                    }}
                    isInvalid={categoryEditColorError}
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <div className="btn-container">
                  <AdminButton
                    type="button"
                    onClick={() =>
                      this.setState({ showModalEditCategory: false })
                    }
                  >
                    <span>Cancelar</span>
                  </AdminButton>
                  <AdminButton
                    type="submit"
                    variant="filled"
                    disabled={!categoryEditText || !categoryEditColor}
                  >
                    <EditIcon />
                    <span>Editar</span>
                  </AdminButton>
                </div>
              </Form.Row>
            </form>
          </Modal.Body>
        </StyledModalEdit>
      </>
    );
  }
}

export default CategoriesPage;
