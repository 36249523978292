import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import { Modal, Button, Col } from 'react-bootstrap';
import { AppAPIService } from '../../../../services/AppAPIService';
import { Portlet, PortletBody } from '../../../../partials/content/Portlet';
import Loading from '../../../home/components/Loading';
import Swal from 'sweetalert2';

class SpreadsheetRowErrorsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: false
    };

    this.service = AppAPIService.getInstance();
  }

  async componentDidMount() {
    if (this.props.id) {
      this.setState({ loading: true });

      try {
        const spreadsheet = await this.service.makeHttpRequest({
          url: '/importacao/' + this.props.id
        });

        this.setState({ errors: spreadsheet.erros, loading: false });
      } catch (e) {
        Swal.fire({
          title: 'Erro!',
          text: 'Erro ao carregar os erros de importação, tente novamente mais tarde!',
          icon: 'error'
        });

        this.setState({ loading: false });
      }
    }
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    return (
      <>
        <Loading isLoading={this.state.loading} />
        {!this.state.loading && (
          <Modal
            size='lg'
            show={true}
            className='bulk-import-errors'
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={this.props.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Erros de importação</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Col md='12'>
                <Portlet>
                  <PortletBody className="p-0">
                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              LINHA
                      </TableCell>
                            <TableCell align="center">
                              ERRO
                      </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.errors.map(e => (
                            <TableRow key={e.id}>
                              <TableCell align="center">
                                {e.numeroLinha}
                              </TableCell>
                              <TableCell align="center">
                                {e.descricao}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </PortletBody>
                </Portlet>
              </Col>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={this.props.handleClose}>Ok</Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

export default SpreadsheetRowErrorsModal;
