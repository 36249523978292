import React, { useState } from "react";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { AppAPIService } from "../../../services/AppAPIService";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { Container, StyledInput, ButtonForm, StyledModal } from "./style";
import { CreatePassword } from "./component/CreatePassword";

const ForgotPassword = ({ open, setOpen }) => {
  const api = AppAPIService.getInstance();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [emailSent, setEmailSent] = useState(false);
  const [hasCode, setHasCode] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const reloadForm = () => {
    setEmail("");
    setCode("");
    setEmailSent(false);
    setHasCode(false);
    setError("");
    setDisabledButton(false);
  };

  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (!IsValidEmail(value)) {
      setError("Este email é invalido!");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleCodeChange = e => {
    const value = e.target.value;
    setCode(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (isNaN(Number(value)) || value.length !== 6) {
      setError("Código inválido");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleEmailSend = async () => {
    if (error !== "") return;

    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/user/forgot-password",
        method: "POST",
        data: {
          email
        }
      });

      setEmailSent(true);
      Swal.fire(
        "Sucesso",
        "Um código de verificação foi enviado ao seu email.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao enviar o email de recuperação. Por favor, tente novamente.",
        "error"
      );
    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  const handleCodeVerification = async () => {
    if (error !== "") return;

    setHasCode(true);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        centered
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          {!emailSent ? (
            <Container>
              <h1 className="forgot-title">Esqueceu sua senha?</h1>
              <p className="forgot-subtitle">
                Insira seu email para iniciar a recuperação da senha:
              </p>

              <StyledInput
                placeholder="Insira seu email"
                value={email}
                error={error !== "" ? true : false}
                onChange={handleEmailChange}
                helperText={error !== "" && error}
              />

              <ButtonForm>
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={handleEmailSend}
                >
                  <span className="text-light">Enviar</span>
                </Button>
              </ButtonForm>
            </Container>
          ) : !hasCode ? (
            <Container>
              <h1 className="forgot-title">Código de verificação</h1>
              <p className="forgot-subtitle">
                Insira o codigo de 6 digitos enviado ao seu email:
              </p>
              <StyledInput
                placeholder="Insira o código de verificação"
                value={code}
                error={error !== "" ? true : false}
                onChange={handleCodeChange}
                helperText={error !== "" && error}
              />

              <ButtonForm>
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={handleCodeVerification}
                >
                  <span className="text-light">Enviar</span>
                </Button>
              </ButtonForm>
            </Container>
          ) : (
            <CreatePassword
              email={email}
              code={code}
              close={() => setOpen(false)}
              resetAll={reloadForm}
            />
          )}
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ForgotPassword;
