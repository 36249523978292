import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { EditorContainer } from "./styles";

export class EditorText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      text: ""
    };
  }

  modules = {
    toolbar: {
      container: [["bold", "italic", "link", { list: "bullet" }]]
    },
    clipboard: { matchVisual: false }
  };

  formats = ["header", "bold", "italic", "link", "list", "bullet"];

  render() {
    return (
      <EditorContainer isInvalid={this.props.isInvalid}>
        <ReactQuill
          theme="bubble"
          modules={this.modules}
          formats={this.formats}
          placeholder="Ex: ..."
          value={this.props.value}
          onChange={(value, _, __, editor) => {
            this.props.onChange(value, editor.getText().trim());
          }}
        />
        {this.props.label && (
          <span className="label">
            {this.props.label}{" "}
            {this.props.required && <span className="required">*</span>}
          </span>
        )}
      </EditorContainer>
    );
  }
}
