import React from "react";
import { Form, Col, Card, Tabs, Tab, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Moment from "moment";
import { AppAPIService } from "../../../../services/AppAPIService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidTextArea from "../../../../utils/validators/IsValidTextArea";
import formatCurrency from "../../../../utils/FormatCurrency";
import { AdminTitle } from "../../components/AdminTitle";
import iconChecked from "../../../../assets/icons/icon-checked.svg";
import iconNotChecked from "../../../../assets/icons/icon-not-checked.svg";
import placeholder from "../../../../assets/images/placeholder.png";
import documentPlaceholder from "../../../../assets/images/document-placeholder.png";
import { connect } from "react-redux";
import { FiCamera, FiEdit3, FiX } from "react-icons/fi";
import { EditorText } from "../../components/EditorText";
import { TabsContainer } from "../../components/StyledTabs/styles";
import Datatable from "../../../../partials/datatable/Datatable";
import { BiDonateHeart } from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { AdminButton } from "../../components/AdminButton";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import {
  OpenButton,
  ButtonContainer,
  Container,
  DocumentCard,
  DocumentModal
} from "./styles";
import CrudActions from "../../../../partials/datatable/CrudActions";

const initialState = {
  id: 0,
  idOwner: 0,

  nome: "",
  categoria: "",
  status: "",
  newStatus: "",
  alvo: "",
  outroAlvo: "",
  descricao: "",
  descricaoHTML: "",
  meta: "",
  temMeta: true,
  imagem: null,
  currentImagem: "",
  donations: [],
  cashouts: [],

  documentModal: null,

  resetInput: false,
  reload: false,
  isLoading: false
};

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.userAvatar = React.createRef();
    this.state = {
      ...initialState,
      categoryOptions: [],
      targetOptions: [],
      statusOptions: [],
      documentList: [],
      isEditing: false,
      tabKey: "form"
    };

    this.api = AppAPIService.getInstance();

    this.handleChange = this.handleChange.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.loadCampaign = this.loadCampaign.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
    this.loadTarget = this.loadTarget.bind(this);
    this.loadStatus = this.loadStatus.bind(this);
    this.validateFileType = this.validateFileType.bind(this);
    this.handleUserAvatar = this.handleUserAvatar.bind(this);
    this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.onActivateOrReject = this.onActivateOrReject.bind(this);
    this.submit = this.submit.bind(this);
    this.donationFormatRow = this.donationFormatRow.bind(this);
    this.cashoutFormatRow = this.cashoutFormatRow.bind(this);
    this.isPdfOrDoc = this.isPdfOrDoc.bind(this);
    this.setDonations = this.setDonations.bind(this);
    this.setCashouts = this.setCashouts.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    this.setState({ isLoading: true, isEditing });

    try {
      const reqs = [this.loadCategories(), this.loadTarget()];

      if (this.props.match.params.id) {
        reqs.push(this.loadCampaign(this.props.match.params.id));
        reqs.push(this.loadStatus());
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        "Erro!",
        "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadCampaign = async id => {
    const campaign = await this.api.makeHttpRequest({
      url: `/campaign/${id}`
    });

    this.setState({
      id: id,
      idOwner: campaign.idOwner,

      nome: campaign.name,
      status: campaign.status,
      newStatus: campaign.newStatus,
      categoria: campaign.idCampaignCategory,
      descricaoHTML: campaign.description,
      meta: campaign.goal ? formatCurrency(campaign.goal + "00") : "",
      temMeta: campaign.hasGoal,
      currentImagem: campaign.imageURL,
      alvo: `${campaign.idCampaignTarget}`,
      outroAlvo: campaign.otherTargetDescription
        ? campaign.otherTargetDescription
        : "",

      documentList: campaign.documents ? campaign.documents : [],

      resetInput: false,
      isLoading: false
    });
  };

  loadCategories = async () => {
    const categories = await this.api.makeHttpRequest({
      url: "/campaign/category"
    });

    this.setState({
      categoryOptions: categories ?? []
    });
  };

  loadTarget = async () => {
    const target = await this.api.makeHttpRequest({
      url: "/campaign/target"
    });

    this.setState({
      targetOptions: target ?? []
    });
  };

  loadStatus = async () => {
    const statusList = await this.api.makeHttpRequest({
      url: "/campaign/status"
    });

    this.setState({
      statusOptions: statusList ?? []
    });
  };

  validateFileType = () => {
    var fileName = this.fileInput.current.value;
    var dotFileIndex = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(dotFileIndex, fileName.length).toLowerCase();

    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      return extFile;
    } else {
      return false;
    }
  };

  handleUserAvatar = () => {
    if (this.fileInput.current && this.fileInput.current.files[0]) {
      const isValid = this.validateFileType();
      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos JPEG/PNG/JPG",
          confirmButtonColor: "#343877",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.onload = e => {
        this.userAvatar.current.src = e.target.result;
      };
      file.readAsDataURL(this.fileInput.current.files[0]);
      this.setState({
        imagem: this.fileInput.current.files[0],
        currentImagem: this.fileInput.current.files[0]
      });
    }
  };

  handleRemoveAvatar = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover avatar",
      text: "Você tem certeza que quer remover seu avatar?",
      confirmButtonColor: "#343877",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }

    if (isForRemove.value) {
      if (this.fileInput.current?.files[0]) {
        this.userAvatar.current.src = "";
        this.fileInput.current.value = "";
      }

      this.setState({
        imagem: null,
        currentImagem: ""
      });
    }
  };

  setDonations = donations => {
    this.setState({ donations });
  };

  setCashouts = cashouts => {
    this.setState({ cashouts });
  };

  onActivateOrReject = async (activate = true) => {
    try {
      await this.api.makeHttpRequest({
        url: `campaign/activate/${this.props.match.params?.id}`,
        method: "PUT",
        data: {
          activate: activate
        }
      });

      const value = await Swal.fire({
        title: "Sucesso!",
        text: `Campanha ${activate ? "ativada" : "recusada"} com sucesso.`,
        icon: "success"
      });

      if (value) {
        this.props.history.push("/admin/campanhas");
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : `Erro ao ${
              activate ? "ativar" : "recusar"
            } campanha, tente novamente mais tarde.`,
        "error"
      );
    }
  };

  submit = async e => {
    e.preventDefault();

    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/campaign/form/${id}` }
        : { method: "POST", url: "/campaign/form" };

      var goalDecimal = "0.00";

      if (this.state.temMeta) {
        const goalValue = this.state.meta.replace(/\D/g, "");
        const decimal = goalValue.replace(/(\d)(\d{2})$/, "$1.$2");
        goalDecimal = parseFloat(decimal);
      }

      const formData = new FormData();
      formData.append("Name", this.state.nome);
      formData.append("IdCampaignCategory", this.state.categoria);
      formData.append("IdCampaignTarget", this.state.alvo);
      formData.append("OtherTargetDescription", this.state.outroAlvo);
      formData.append("HasGoal", this.state.temMeta);
      formData.append("Description", this.state.descricaoHTML);
      formData.append("Goal", goalDecimal);
      formData.append(
        "NewStatus",
        this.state.newStatus ? this.state.newStatus : ""
      );
      formData.append(
        "IdOwner",
        this.state.isEditing ? this.state.idOwner : this.props.user.idUser
      );
      if (this.state.imagem !== null) {
        formData.append("ImageFile.File", this.state.imagem);
      } else {
        formData.append("ImageURL", this.state.currentImagem);
      }

      // console.table([...formData]);

      await this.api.makeHttpRequest({
        ...reqBase,
        data: formData
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar campanha, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/campanhas");
    }
  };

  handleChange = e => {
    if (e.target.name === "meta") {
      this.setState({
        [e.target.name]: formatCurrency(e.target.value)
      });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value
    });
  };

  formIsValid = () => {
    const isValid =
      !!this.state.currentImagem &&
      !!this.state.nome &&
      IsValidName(this.state.nome) &&
      !!this.state.categoria &&
      !!this.state.descricao &&
      IsValidTextArea(this.state.descricao) &&
      !!this.state.alvo &&
      (this.state.temMeta ? !!this.state.meta : true) &&
      (this.state.alvo === "6" ? !!this.state.outroAlvo : true) &&
      (this.state.isEditing ? !!this.state.newStatus : true);

    return isValid;
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadCampaign(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  handleRefund = async obj => {
    try {
      await this.api.makeHttpRequest({
        method: "DELETE",
        url: `/donation/cancel-donation/${obj.transactionHash}`
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Pedido de estorno realizado com sucesso.",
        icon: "success"
      });

      this.setState({ reload: true });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Ocorreu um erro ao realizar estrorno.",
        "error"
      );
    }
  };

  donationFormatRow = r => {
    let crudActions = [];

    if (
      r.status === "PENDING" &&
      r.transactionStatus === "paid" &&
      !r.removalDate
    ) {
      crudActions.push("refund");
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idDonation}>
        <StyledTableCell scope="row">
          {crudActions.length ? (
            <CrudActions
              actions={crudActions}
              module="doacoes"
              onConfirmRefund={this.handleRefund}
              id={r.idDonation}
              obj={r}
            />
          ) : (
            "--"
          )}
        </StyledTableCell>
        <StyledTableCell scope="row">{r.transactionHash}</StyledTableCell>
        <StyledTableCell scope="row">{r.donator?.name}</StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.liquidValue)}
        </StyledTableCell>
        <StyledTableCell scope="row">{r.paymentType}</StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">{r.status}</span>
        </StyledTableCell>
        <StyledTableCell>
          {Moment(r.creationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell>
          {r.removalDate ? Moment(r.removalDate).format("DD/MM/YYYY") : "--"}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  cashoutFormatRow = r => {
    return (
      <StyledTableRow hover tabIndex={-1} key={r.idCashout}>
        <StyledTableCell scope="row">{r.transactionHash}</StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.liquidValue)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">{r.status}</span>
        </StyledTableCell>
        <StyledTableCell>
          {Moment(r.creationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  isPdfOrDoc = name => {
    return (
      name.includes(".pdf") ||
      name.includes(".PDF") ||
      name.includes(".doc") ||
      name.includes(".DOC")
    );
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Campanha - Admin");
    } else {
      window.setPageTitle("Cadastro de Campanha - Admin");
    }

    const donationsHeadRows = [
      { label: "Ações" },
      { column: "Transaction.TransactionHash", label: "Código" },
      { column: "Donator.Name", label: "Doador" },
      { column: "Transaction.Value", label: "Valor" },
      { column: "Transaction.LiquidValue", label: "Valor Liquido" },
      { column: "Transaction.PaymentMethod", label: "Método" },
      { column: "Transaction.Status", label: "Status" },
      { column: "Transaction.CreationDate", label: "Data de Doação" },
      { column: "Transaction.RemovalDate", label: "Data de Estono" }
    ];

    const cashoutsHeadRows = [
      { column: "Transaction.TransactionHash", label: "Código" },
      { column: "Transaction.Value", label: "Valor" },
      { column: "Transaction.LiquidValue", label: "Valor Liquido" },
      { column: "Transaction.Status", label: "Status" },
      { column: "Transaction.CreationDate", label: "Data de Doação" }
    ];

    const {
      nome,
      status,
      newStatus,
      categoria,
      alvo,
      outroAlvo,
      descricao,
      descricaoHTML,
      meta,
      temMeta,
      currentImagem,

      categoryOptions,
      targetOptions,
      statusOptions,
      donations,

      documentList,
      documentModal,

      isEditing,
      isLoading,
      tabKey
    } = this.state;

    const formCard = (
      <>
        <Card>
          <StyledCardBody>
            <div className="avatar">
              <input
                type="file"
                accept="image/*"
                name="avatar"
                ref={this.fileInput}
                onChange={this.handleUserAvatar}
                className="file-input"
              />
              {currentImagem ? (
                <>
                  <div
                    className="remove-button"
                    onClick={this.handleRemoveAvatar}
                  >
                    <FiX size="25px" />
                  </div>
                  <img
                    src={currentImagem}
                    alt="Imagem da campanha"
                    ref={this.userAvatar}
                  />
                </>
              ) : (
                <img src={placeholder} alt="Avatar" />
              )}
              <div
                className="upload-button"
                onClick={() => this.fileInput.current.click()}
              >
                <FiCamera size="18px" />
              </div>
            </div>
            <Form className="form-admin">
              {/* row 1 */}
              {isEditing ? (
                <>
                  <Form.Row>
                    {/* Nome */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        name="nome"
                        label="Nome"
                        value={nome}
                        placeholder="Digite o nome"
                        onChange={this.handleChange}
                        isInvalid={nome && !IsValidName(nome)}
                        required
                      />
                    </Form.Group>

                    {/* Meta */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        name="meta"
                        label="Meta"
                        value={meta}
                        placeholder="Digite a meta"
                        onChange={this.handleChange}
                        disabled={!temMeta}
                        prefix="R$"
                        required
                        endAdornment={
                          <div
                            onClick={() =>
                              isEditing
                                ? null
                                : this.setState({
                                    meta: "",
                                    temMeta: !temMeta
                                  })
                            }
                          >
                            {temMeta ? (
                              <img
                                src={iconChecked}
                                alt="Desativar meta"
                                title="Desativar meta"
                              />
                            ) : (
                              <img
                                src={iconNotChecked}
                                alt="Ativar meta"
                                title="Ativar meta"
                              />
                            )}
                          </div>
                        }
                      />
                    </Form.Group>

                    {/* Categoria */}
                    <Form.Group as={Col} lg="4" xs="12">
                      <InputField
                        as="select"
                        name="categoria"
                        label="Categoria"
                        value={categoria}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">Selecione a categoria</option>
                        {categoryOptions.length > 0 &&
                          categoryOptions.map(c => (
                            <option
                              key={c.idCampaignCategory}
                              value={c.idCampaignCategory}
                            >
                              {c.name}
                            </option>
                          ))}
                      </InputField>
                    </Form.Group>
                  </Form.Row>

                  {/* row 2 */}
                  <Form.Row>
                    {/* Alvo */}
                    {alvo !== "6" ? (
                      <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                          as="select"
                          name="alvo"
                          label="Alvo"
                          value={alvo}
                          onChange={this.handleChange}
                          required
                        >
                          <option value="">Selecione o alvo da campanha</option>
                          {targetOptions.length > 0 &&
                            targetOptions.map(t => (
                              <option
                                key={t.idCampaignTarget}
                                value={t.idCampaignTarget}
                              >
                                {t.name}
                              </option>
                            ))}
                        </InputField>
                      </Form.Group>
                    ) : (
                      <Form.Group as={Col} lg="6" xs="12">
                        <InputField
                          name="outroAlvo"
                          label="Outro alvo"
                          value={outroAlvo}
                          placeholder="Digite o outro alvo"
                          onChange={this.handleChange}
                          required
                          endAdornment={
                            <div
                              onClick={() =>
                                this.setState({
                                  alvo: "",
                                  outroAlvo: ""
                                })
                              }
                            >
                              <FiEdit3
                                color="#807E80"
                                size="20px"
                                title="Mudar alvo"
                              />
                            </div>
                          }
                        />
                      </Form.Group>
                    )}

                    {/* status */}
                    <Form.Group as={Col} lg="6" xs="12">
                      <InputField
                        as="select"
                        name="newStatus"
                        label="Status"
                        value={newStatus}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">Selecione o status</option>
                        {statusOptions.length > 0 &&
                          statusOptions.map(s => (
                            <option key={s.index} value={s.index}>
                              {s.name}
                            </option>
                          ))}
                      </InputField>
                    </Form.Group>
                  </Form.Row>
                </>
              ) : (
                <Form.Row>
                  {/* Nome */}
                  <Form.Group as={Col} lg="3" xs="12">
                    <InputField
                      name="nome"
                      label="Nome"
                      value={nome}
                      placeholder="Digite o nome"
                      onChange={this.handleChange}
                      isInvalid={nome && !IsValidName(nome)}
                      required
                    />
                  </Form.Group>

                  {/* Meta */}
                  <Form.Group as={Col} lg="3" xs="12">
                    <InputField
                      name="meta"
                      label="Meta"
                      value={meta}
                      placeholder="Digite a meta"
                      onChange={this.handleChange}
                      disabled={!temMeta}
                      prefix="R$"
                      required
                      endAdornment={
                        <div
                          onClick={() =>
                            isEditing
                              ? null
                              : this.setState({
                                  meta: "",
                                  temMeta: !temMeta
                                })
                          }
                        >
                          {temMeta ? (
                            <img
                              src={iconChecked}
                              alt="Desativar meta"
                              title="Desativar meta"
                            />
                          ) : (
                            <img
                              src={iconNotChecked}
                              alt="Ativar meta"
                              title="Ativar meta"
                            />
                          )}
                        </div>
                      }
                    />
                  </Form.Group>

                  {/* Categoria */}
                  <Form.Group as={Col} lg="3" xs="12">
                    <InputField
                      as="select"
                      name="categoria"
                      label="Categoria"
                      value={categoria}
                      onChange={this.handleChange}
                      required
                    >
                      <option value="">Selecione a categoria</option>
                      {categoryOptions.length > 0 &&
                        categoryOptions.map(c => (
                          <option
                            key={c.idCampaignCategory}
                            value={c.idCampaignCategory}
                          >
                            {c.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>

                  {/* Alvo */}
                  {alvo !== "6" ? (
                    <Form.Group as={Col} lg="3" xs="12">
                      <InputField
                        as="select"
                        name="alvo"
                        label="Alvo"
                        value={alvo}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">Selecione o alvo da campanha</option>
                        {targetOptions.length > 0 &&
                          targetOptions.map(t => (
                            <option
                              key={t.idCampaignTarget}
                              value={t.idCampaignTarget}
                            >
                              {t.name}
                            </option>
                          ))}
                      </InputField>
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} lg="3" xs="12">
                      <InputField
                        name="outroAlvo"
                        label="Outro alvo"
                        value={outroAlvo}
                        placeholder="Digite o outro alvo"
                        onChange={this.handleChange}
                        required
                        endAdornment={
                          <div
                            onClick={() =>
                              this.setState({
                                alvo: "",
                                outroAlvo: ""
                              })
                            }
                          >
                            <FiEdit3
                              color="#807E80"
                              size="20px"
                              title="Mudar alvo"
                            />
                          </div>
                        }
                      />
                    </Form.Group>
                  )}
                </Form.Row>
              )}

              {/* row 3 */}
              <Form.Row style={{ height: "fit-content" }}>
                {/* Descrição */}
                <Form.Group as={Col} lg="12" xs="12">
                  <EditorText
                    value={descricaoHTML}
                    label="Descrição"
                    onChange={(html, text) =>
                      this.setState({ descricao: text, descricaoHTML: html })
                    }
                    isInvalid={descricao && !IsValidTextArea(descricao)}
                    required
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </StyledCardBody>
        </Card>
        <FormActions
          module="campanhas"
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
        />
      </>
    );

    const reviewCampaign = (
      <>
        <Card className="flex-grow-1">
          <StyledCardBody>
            <Container>
              <p className="description">
                Aqui estão listados todos os documentos enviados para que esta
                campanha seja validada.
              </p>
              <div className="cards">
                {documentList?.length > 0 &&
                  documentList.map(doc => (
                    <DocumentCard>
                      <Card.Img
                        variant="top"
                        src={
                          this.isPdfOrDoc(doc.name)
                            ? documentPlaceholder
                            : doc.tempUri
                        }
                      />
                      <Card.Body>
                        <Card.Text>
                          {doc.fileType
                            .toLowerCase()
                            .replace(/^\w/, c => c.toUpperCase())}
                        </Card.Text>
                        <OpenButton
                          href={doc.tempUri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Abrir
                        </OpenButton>
                      </Card.Body>
                    </DocumentCard>
                  ))}
              </div>
            </Container>
          </StyledCardBody>
        </Card>
        <ButtonContainer>
          <AdminButton
            type="button"
            onClick={() => this.onActivateOrReject(false)}
          >
            Recusar
          </AdminButton>
          <AdminButton
            type="button"
            variant="filled"
            onClick={() => this.onActivateOrReject(true)}
          >
            Aprovar
          </AdminButton>
        </ButtonContainer>
        <DocumentModal
          show={documentModal}
          onHide={() => this.setState({ documentModal: null })}
          centered
        >
          <Modal.Body>
            <div className="modal-image">
              <img src={documentModal} alt="Documento da campanha" />
            </div>
          </Modal.Body>
        </DocumentModal>
      </>
    );

    const donationsListing = (
      <Card className="flex-grow-1">
        <StyledCardBody>
          <Datatable
            title="Doações"
            icon={<BiDonateHeart color="#4d4d4d" size="26px" />}
            endpoint="/donation"
            paramsId={this.props.match.params.id}
            className="h-100"
            headRows={donationsHeadRows}
            formatRow={this.donationFormatRow}
            rows={donations}
            setRows={this.setDonations}
            reload={this.state.reload}
            placeholderSearch="Busque por camapanha, doador, valor ou método de pagamento"
            width={this.props.width}
            hiddenReload
          />
        </StyledCardBody>
      </Card>
    );

    const cashoutListing = (
      <Card className="flex-grow-1">
        <StyledCardBody>
          <Datatable
            title="Saques"
            icon={<FaRegMoneyBillAlt color="#4d4d4d" size="24px" />}
            endpoint="/campaign/withdrawals"
            paramsId={this.props.match.params.id}
            className="h-100"
            headRows={cashoutsHeadRows}
            formatRow={this.cashoutFormatRow}
            rows={donations}
            setRows={this.setCashouts}
            reload={this.state.reload}
            placeholderSearch="Busque por camapanha"
            width={this.props.width}
            hiddenReload
          />
        </StyledCardBody>
      </Card>
    );

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados da Campanha" disableHeader />
        {isEditing ? (
          <TabsContainer>
            <Tabs
              activeKey={tabKey}
              transition={false}
              onSelect={k => this.setState({ tabKey: k })}
              className="mt-3"
            >
              <Tab eventKey="form" title="Campanha">
                {formCard}
              </Tab>
              {(status === "CREATED" || status === "ANALYZING") && (
                <Tab eventKey="review" title="Revisar">
                  {reviewCampaign}
                </Tab>
              )}
              {status !== "CREATED" && status !== "ANALYZING" && (
                <Tab eventKey="donations" title="Doações">
                  {donationsListing}
                </Tab>
              )}
              {status !== "CREATED" && status !== "ANALYZING" && (
                <Tab eventKey="cashouts" title="Saques">
                  {cashoutListing}
                </Tab>
              )}
            </Tabs>
          </TabsContainer>
        ) : (
          formCard
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(CampaignForm);
