import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const PreviewColor = styled.span`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-left: 10px;
  display: inline-block;
`;

export const ContainerFormCategory = styled.form`
  display: flex;
  flex-direction: column;

  padding: 20px 0;
  margin: 0 12px 0 12px;

  h2 {
    color: rgb(77, 77, 77);
    margin-bottom: 24px;
  }

  .form-group {
    margin-bottom: 0;
  }
`;

export const TableActions = styled.div`
  display: flex;

  svg {
    display: inline-block;
    fill: #4d4c4d;
  }
`;

export const StyledModalEdit = styled(Modal)`
  .modal-content {
    max-width: 570px;
    margin: 0 auto;
  }

  .modal-body {
    padding: 24px;

    svg {
      fill: #fff;
    }

    h2 {
      color: #333;
      margin-bottom: 2.2rem;

      text-align: center;
    }

    .btn-container {
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;

      button {
        max-width: 30%;

        :first-child {
          margin-right: 24px;
        }
      }
    }
  }
`;

export const Separator = styled.hr`
  width: 80%;
  margin: 30px auto 0;
`;
