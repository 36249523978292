import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
  max-width: 450px;

  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`;

export const ContainerWithIcon = styled.div`
  width: 100%;
`;

export const ContainerInputs = styled.div`
  > h1 {
    color: #031d3d;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  > h3 {
    color: #031d3d;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .subtitle {
    text-align: center;
    color: #031d3d;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;

    margin-bottom: 5px;
  }

  form {
    display: flex;
    flex-direction: column;

    & > .MuiFormControl-root.MuiTextField-root:first-child {
      margin: 5px 0 15px;
    }
  }

  a {
    display: flex;
    flex: 1;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: none;
    color: #031d3d;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;

    :hover {
      transition: opacity 0.4s;
      opacity: 0.8;
      text-decoration: underline !important;
    }
  }
`;

export const ContainerReCaptcha = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const StyledInput = styled(TextField)`
  margin-bottom: 5px;

  input {
    font-size: 18px;
    padding: 10px 5px;
  }
`;
