import React, { useState } from "react";
import Loading from "../../home/components/Loading";
import { AppAPIService } from "../../../services/AppAPIService";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { Container, StyledInput, ButtonForm, StyledModal } from "./style";

const ActivateAccount = ({ email, open, setOpen }) => {
  const api = AppAPIService.getInstance();
  const [verificationCode, setVerificationCode] = useState("");

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCodeChange = e => {
    const value = e.target.value;
    setVerificationCode(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (isNaN(Number(value)) || value.length !== 6) {
      setError("Código inválido");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleResendCode = async () => {
    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/user/resend-token",
        method: "POST",
        data: { email }
      });

      Swal.fire(
        "Sucesso",
        "Um novo código de verificação foi enviado ao seu email.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao reenviar o código ao seu email. Por favor, tente novamente.",
        "error"
      );
    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  const handleCodeVerification = async () => {
    if (error !== "") return;

    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/user/confirm-email",
        method: "PUT",
        data: {
          email,
          code: verificationCode
        }
      });

      Swal.fire(
        "Sucesso",
        "Código de verificação validado com sucesso.",
        "success"
      );

      setOpen(false);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao validar o seu código de verificação. Por favor, tente novamente.",
        "error"
      );
    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        centered
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          <Container>
            <h1 className="container-title">Ativação da conta</h1>
            <p className="container-subtitle">
              Para sua segurança, antes de liberarmos seu acesso precisamos que
              você valide seu email. Para isso insira o codigo de 6 digitos
              enviado ao seu email:
            </p>
            <StyledInput
              placeholder="Insira o código de verificação"
              value={verificationCode}
              error={error !== "" ? true : false}
              onChange={handleCodeChange}
              helperText={error !== "" && error}
            />

            <ButtonForm>
              <span onClick={handleResendCode}>Reenviar Código</span>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={disabledButton}
                onClick={handleCodeVerification}
              >
                <span className="text-light">Ativar conta</span>
              </Button>
            </ButtonForm>
          </Container>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ActivateAccount;
