import styled from "styled-components";
import { Card } from "react-bootstrap";

export const StyledCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    width: 212px;
    height: 134px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #eff0f6;

    .file-input {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .upload-button {
      position: absolute;
      bottom: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #343877;
      border-radius: 4px;
      color: #fff;
      width: 30px;
      height: 30px;

      cursor: pointer;
    }

    .remove-button {
      position: absolute;
      top: 8px;
      left: 8px;
      width: 20px;
      height: 20px;
      
      display: flex;
      align-items: center;
      justify-content: center;
      background: #4c4c4c;
      color: #fff;
      border-radius: 3px;

      cursor: pointer;
    }
  }

  .form-admin {
    flex: 1;
    & > .card-title {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: #4d4c4d;
      margin-bottom: 37px;
    }
  }

  .form-row {
    height: fit-content;
    margin-bottom: 0px;
    
    @media screen and (min-width: 1024px) {
      height: 48px;
      margin-bottom: 37px;
    }

    &:last-child {
      margin-bottom: 0px;

      & .form-group:last-child {
        margin-bottom: 0px;
      }
    }

    .form-group {
      margin-bottom: 24px;
    }

  }
`;
