import { TableCell, TableRow } from "@material-ui/core";
import styled from "styled-components";

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: #807e80;
    line-height: 150%;

    white-space: nowrap;
  }

  &.MuiTableCell-head:first-child {
    border-top-left-radius: 4px;
  }

  &.MuiTableCell-head:last-child {
    border-top-right-radius: 4px;
  }

  &.MuiTableCell-root.MuiTableCell-body {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #4d4c4d;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 250px;
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }

  .link {
    text-decoration: underline;
  }
`;
