import React, { useState } from "react";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { Form, Formik } from "formik";
import { IconButton, InputAdornment } from "@material-ui/core";
import { RiCloseLine, RiLockPasswordLine } from "react-icons/ri";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import Loading from "../../home/components/Loading";
import { AppAPIService } from "../../../services/AppAPIService";
import WindowSize from "../../../constants/WindowSize";
import useGetWindowWidth from "../../../utils/useGetWindowWidth";
import { isValidNewPassword } from "../../../utils/validators/isValidYup";
import {
  ContainerInputs,
  Container,
  ContainerWithIcon,
  StyledInput,
  StyledModal
} from "./style";
import Button from "../../../components/Button";

const ForceChangePassword = ({ idUser, open, setOpen }) => {
  const api = AppAPIService.getInstance();
  const width = useGetWindowWidth();

  const [isLoading, setIsLoading] = useState(false);

  const [newPswVisibility, setNewPswVisibility] = useState(false);
  const [confirmPswVisibility, setConfirmPswVisibility] = useState(false);

  const handleSubmit = async (values, resetForm) => {
    setIsLoading(true);

    if (!idUser) {
      Swal.fire(
        "Error!",
        "Não foi possivel encontrar a referência do usuário obrigatório.",
        "error"
      );
      return;
    }

    try {
      await api.makeHttpRequest({
        url: "/user/forced-password-change/" + idUser,
        method: "PUT",
        data: {
          newPassword: values.password
        }
      });

      Swal.fire("Sucesso", "Nova senha criada com sucesso.", "success");
      setOpen(false);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao criar sua nova senha. Por favor, tente novamente.",
        "error"
      );
    }

    setIsLoading(false);
    resetForm();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <Container width={width}>
            <div className="close-icon" onClick={() => setOpen(false)}>
              <RiCloseLine size="30px" />
            </div>
            <ContainerWithIcon width={width}>
              <ContainerInputs>
                {width >= WindowSize.SM ? (
                  <h3>Definir senha permanente</h3>
                ) : (
                  <h1>Definir senha permanente</h1>
                )}
                <p className="subtitle">
                  Você está utilizando uma senha temporária que será expirada em
                  alguns dias. Por favor crie uma nova senha para sua conta.
                </p>
                <Formik
                  initialValues={{ password: "", confirmePassword: "" }}
                  validationSchema={isValidNewPassword}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    handleSubmit(values, resetForm);
                    setSubmitting(false);
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur }) => (
                    <Form>
                      <StyledInput
                        placeholder="Senha"
                        type={newPswVisibility ? "text" : "password"}
                        name="password"
                        value={values.password}
                        error={
                          touched.password && errors.password ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.password && errors.password
                            ? errors.password
                            : ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RiLockPasswordLine size="18px" opacity=".70" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Alterar visibilidade da senha"
                                onClick={() =>
                                  setNewPswVisibility(!newPswVisibility)
                                }
                                onMouseDown={e => e.preventDefault()}
                                edge="end"
                              >
                                {newPswVisibility ? (
                                  <VisibilityOffOutlined />
                                ) : (
                                  <VisibilityOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                      <StyledInput
                        className="mb-5"
                        placeholder="Confirme sua senha"
                        type={confirmPswVisibility ? "text" : "password"}
                        name="confirmePassword"
                        value={values.confirmePassword}
                        error={
                          touched.confirmePassword && errors.confirmePassword
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.confirmePassword && errors.confirmePassword
                            ? errors.confirmePassword
                            : ""
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RiLockPasswordLine size="18px" opacity=".70" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Alterar visibilidade da confirmação da senha"
                                onClick={() =>
                                  setConfirmPswVisibility(!confirmPswVisibility)
                                }
                                onMouseDown={e => e.preventDefault()}
                                edge="end"
                              >
                                {confirmPswVisibility ? (
                                  <VisibilityOffOutlined />
                                ) : (
                                  <VisibilityOutlined />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />

                      <Button
                        text="Criar"
                        disabled={
                          (!values.password || !values.confirmePassword) && true
                        }
                        opacity={
                          (!values.password || !values.confirmePassword) && 0.5
                        }
                        cursor={
                          (!values.password || !values.confirmePassword) &&
                          "not-allowed"
                        }
                        loading={isLoading}
                        type="submit"
                      />
                    </Form>
                  )}
                </Formik>
              </ContainerInputs>
            </ContainerWithIcon>
          </Container>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ForceChangePassword;
