import React from "react";
import Datatable from "../../../../partials/datatable/Datatable";
import { connect } from "react-redux";
import { AppAPIService } from "../../../../services/AppAPIService";
import { BiDonateHeart } from "react-icons/bi";
import CrudActions from "../../../../partials/datatable/CrudActions";
import Moment from "moment";
import Loading from "../../../home/components/Loading";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../partials/datatable/styles";
import Swal from "sweetalert2";
import { FilterContainer, SelectContainer } from "./styles";
import ReactSelect from "react-select";

const options = [
  { value: "pending", label: "PENDENTE" },
  { value: "paid", label: "PAGO" },
  { value: "canceled", label: "CANCELADO" },
  { value: "expired", label: "EXPIRADO" },
  { value: "", label: "TODOS" }
];

const initialState = {
  donations: [],
  profile: "",
  reload: false,
  loading: false,
  showExpired: false,
  selectedOptions: options.at(-1)
};

class DonationListing extends React.Component {
  constructor(props) {
    super(props);

    this.api = AppAPIService.getInstance();

    this.state = {
      ...initialState
    };

    this.handleLinkCampaign = this.handleLinkCampaign.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.formatRow = this.formatRow.bind(this);
    this.setDonations = this.setDonations.bind(this);
  }

  componentDidMount = async () => {
    const profile = this.props.user.role?.name;
    this.setState({ profile });
  };

  setDonations = donations => {
    this.setState({ donations });
  };

  handleLinkCampaign = obj => {
    this.props.router.history.push(
      `/admin/campanhas/${obj.campaign.idCampaign}/editar/`
    );
  };

  handleRefund = async obj => {
    try {
      await this.api.makeHttpRequest({
        method: "DELETE",
        url: `/donation/cancel-donation/${obj.transactionHash}`
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Pedido de estorno realizado com sucesso.",
        icon: "success"
      });

      this.setState({ reload: true });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Ocorreu um erro ao realizar estrorno.",
        "error"
      );
    }
  };

  getTypeTransaction = type => {
    const types = {
      pending: "PENDENTE",
      paid: "PAGO",
      canceled: "CANCELADO",
      expired: "EXPIROU"
    };

    return types[type] || types["pending"];
  };

  formatRow = r => {
    let crudActions = ["link-campaign"];

    if (
      r.status === "PENDING" &&
      r.transactionStatus === "paid" &&
      !r.removalDate
    ) {
      crudActions.push("refund");
    }

    return (
      <StyledTableRow hover tabIndex={-1} key={r.idDonation}>
        <StyledTableCell scope="row">
          <CrudActions
            actions={crudActions}
            module="doacoes"
            onLink={this.handleLinkCampaign}
            onConfirmRefund={this.handleRefund}
            id={r.idDonation}
            obj={r}
          />
        </StyledTableCell>
        <StyledTableCell scope="row">{r.transactionHash}</StyledTableCell>
        <StyledTableCell scope="row">{r.campaign?.name}</StyledTableCell>
        <StyledTableCell scope="row">{r.donator?.name}</StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.value)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.adminTax)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.gatewayFee)}
        </StyledTableCell>
        <StyledTableCell scope="row">
          {new Intl.NumberFormat("pt-br", {
            style: "currency",
            currency: "BRL"
          }).format(r.liquidValue)}
        </StyledTableCell>
        <StyledTableCell scope="row">{r.paymentType}</StyledTableCell>
        <StyledTableCell scope="row">
          <span className="text-uppercase">
            {this.getTypeTransaction(r.transactionStatus)}
          </span>
        </StyledTableCell>
        <StyledTableCell>
          {Moment(r.creationDate).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell>
          {r.removalDate ? Moment(r.removalDate).format("DD/MM/YYYY") : "--"}
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  setSelectedOptions = e => {
    this.setState({
      selectedOptions: e,
      reload: !this.state.reload
    });
  };

  setShowExpired = () => {
    this.setState({
      showExpired: !this.state.showExpired,
      reload: !this.state.reload
    });
  };

  render() {
    window.setPageTitle("Listagem de Doações - Admin");

    const headRows = [
      { label: "Ações" },
      { column: "Transaction.TransactionHash", label: "Código" },
      { column: "Campaign.Name", label: "Campanha" },
      { column: "Donator.Name", label: "Doador" },
      { column: "Transaction.Value", label: "Valor" },
      { label: "Taxa" },
      { label: "Tarífa" },
      { column: "Transaction.LiquidValue", label: "Valor Liquido" },
      { column: "Transaction.PaymentMethod", label: "Método" },
      { column: "Transaction.Status", label: "Status" },
      { column: "Transaction.CreationDate", label: "Data de Doação" },
      { column: "Transaction.RemovalDate", label: "Data de Estono" }
    ];

    return (
      <>
        <Loading isLoading={this.state.loading} />
        <Datatable
          title="Doações"
          icon={<BiDonateHeart color="#4d4d4d" size="26px" />}
          endpoint="/donation"
          className="h-100"
          headRows={headRows}
          formatRow={this.formatRow}
          rows={this.state.donations}
          setRows={this.setDonations}
          reload={this.state.reload}
          placeholderSearch="Busque por campanha ou doador"
          width={this.props.width}
          hiddenReload
          paramsExtra={{
            showExpired: this.state.selectedOptions.value.length
              ? false
              : this.state.showExpired,
            status: this.state.selectedOptions.value
          }}
          filtersSection={
            <FilterContainer>
              <SelectContainer>
                <ReactSelect
                  options={options}
                  value={this.state.selectedOptions}
                  onChange={this.setSelectedOptions}
                  placeholder="Selecione um status"
                />
              </SelectContainer>

              {this.state.selectedOptions &&
                this.state.selectedOptions.value === "" && (
                  <>
                    <input
                      type="checkbox"
                      name="show--expired"
                      id="show--expired"
                      value={this.state.showExpired}
                      onChange={this.setShowExpired}
                    />
                    <label htmlFor="show--expired">
                      Exibir doações que expiraram
                    </label>
                  </>
                )}
            </FilterContainer>
          }
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(DonationListing);
