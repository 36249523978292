import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #4d4c4d;

  label {
    margin: 0;
    margin-left: 5px;
  }

  label,
  input {
    cursor: pointer;
  }
`;

export const SelectContainer = styled.div`
  width: 200px;
  margin-right: 10px;
`;
