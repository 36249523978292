import React from 'react';
import { Link } from "react-router-dom";
import { Button, Icon } from '@material-ui/core';

class HeaderButton extends React.Component {
  render() {
    const { label, onClick, path, icone, disabled } = this.props;
    return (
      <div>
        {path ?
          <Link to={path}>
            <Button variant="contained" className="kt-button-adicionar" disabled={disabled}>
              {icone ? icone : <Icon className="mr-2 kt-icons">add</Icon>} {label ? label : "Adicionar"}
            </Button>
          </Link>
          :
          <div style={{ marginRight: '5px' }}>
            <Button variant="contained" className="kt-button-adicionar" disabled={disabled} onClick={onClick}>
            {icone ? icone : <Icon className="mr-2 kt-icons">add</Icon>} {label ? label : "Adicionar"}
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default HeaderButton;