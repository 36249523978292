/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState, useEffect, useCallback } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { getUserByToken } from "./app/crud/auth.crud";
import { I18nProvider, LayoutSplashScreen } from "./_metronic";
import bsCustomFileInput from 'bs-custom-file-input';


import { ClearCacheProvider, useClearCache } from 'react-clear-cache';

bsCustomFileInput.init();
//ReactGA.initialize("UA-167151233-1"); TODO: colocar código do GA da devaria

const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

history.listen((location) => {
  const path = buildPath(location);
  ReactGA.set({ page: path }); // Update the user's current page
  ReactGA.pageview(path); // Record a pageview for the given page
});

export default function App({ store, persistor, basename }) {
  const [isLoading, setIsLoading] = useState(true);

  const refreshToken = useCallback(async (token) => {
    if(token){
      await getUserByToken();
    }
    setIsLoading(false)
  }, [])

  let auth;
  let token;
  if (window.localStorage.getItem('auth:meucofrinho')) {
    auth = JSON.parse(window.localStorage.getItem('auth:meucofrinho'))?.authData;
    if (auth && JSON.parse(auth)) {
      token = JSON.parse(auth)?.accessToken;
    }
  }

  useEffect(() => {
      const path = buildPath(history.location);
      ReactGA.set({ page: path });
      ReactGA.pageview(path);
      refreshToken(token);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  if (!isLatestVersion && emptyCacheStorage) {
    emptyCacheStorage();
  }

  return (
    /* Provide Redux store */
    <ClearCacheProvider auto={true}>
      <Provider store={store}  loading={<LayoutSplashScreen />}>
          <PersistGate persistor={persistor}>
            <Router basename={basename} history={history}>
              <LastLocationProvider>
                <I18nProvider>
                  {!isLoading && <Routes />}
                </I18nProvider>
              </LastLocationProvider>
            </Router>
          </PersistGate>
      </Provider>
    </ClearCacheProvider>
  );
}
