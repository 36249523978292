/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles/";
import Swal from "sweetalert2";
import { RiSearchLine } from "react-icons/ri";
import { debounce } from "lodash";
import { AppAPIService } from "../../services/AppAPIService";
import HeaderButton from "../../pages/admin/components/headerButton";
import RefreshButton from "../../pages/admin/components/RefreshButton";
import Loading from "../../pages/home/components/Loading";
import EditorConvertToHTML from "../../pages/admin/components/EditorConvertToHTML";
import { StyledTableCell, StyledTableRow } from "./styles";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableFooter,
  Paper,
  TableCell
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AdminTitle } from "../../pages/admin/components/AdminTitle";

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort, headRows } = props;

  const createSortHandler = property => event => {
    if (!property) {
      return;
    }

    onRequestSort(event, property);
  };

  const generateRow = headRows => (
    <TableRow>
      {(headRows || []).map((row, i) => (
        <TableCell
          key={row.column || i}
          align={row.align ? row.align : "left"}
          padding={row.disablePadding ? "none" : "default"}
          sortDirection={orderBy === row.column ? order : false}
          size={row.size || null}
          width={row.width || undefined}
          variant="head"
          component={StyledTableCell}
        >
          {row.column ? (
            <TableSortLabel
              active={orderBy === row.column}
              direction={order}
              onClick={createSortHandler(row.column)}
            >
              {row.label}
            </TableSortLabel>
          ) : (
            row.label
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <TableHead>
      {Array.isArray(headRows[0]) &&
        headRows.map(row => {
          return generateRow(row);
        })}
      {!Array.isArray(headRows[0]) && generateRow(headRows)}
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired
};

const api = AppAPIService.getInstance();

function Datatable(props) {
  const { widthTable } = props;
  const useStyles = makeStyles(() => {
    return {
      root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
      },
      paper: {
        width: "100%",
        flex: "1"
      },
      table: {
        minWidth: widthTable ? widthTable : 400,
        overflowX: "scroll"
      },
      tableWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        overflowX: "auto",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        boxShadow: props.removeShadow
          ? "none"
          : "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      },
      search: {
        backgroundColor: "#ffffff",
        display: "flex",
        flexWrap: "wrap"
      },
      addBtn: {
        padding: "24px 0",
        marginLeft: "16px"
      }
    };
  });

  const classes = useStyles();
  const currentState = loadPaginationState();

  const rows = props.rows;
  const [order, setOrder] = React.useState(currentState.order || "desc");
  const [orderBy, setOrderBy] = React.useState(
    currentState.orderBy || props.orderBy || props.headRows[0].column || "Id"
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.rowsPerPage || currentState.perPage || 10
  );
  const [count, setCount] = React.useState(rows.length);
  const [inputSearch, setInputSearch] = React.useState(props.search || "");
  const [isLoading, setIsLoading] = React.useState("");
  const [isLoadingExport, setIsLoadingExport] = React.useState("");
  const [reload, setReload] = React.useState(props.reload || false);
  const pageCurrentState = currentState.page;

  const onSearch = useRef(
    debounce((e, searchLoad) => {
      if (e.length >= 3 || e.length === 0) {
        searchLoad(e);
      }
    }, 500)
  ).current;

  function search(e, select) {
    setInputSearch(e.target.value);
    props.localSearch || select
      ? onSearch(e.target.value, e => props.localFilter(select, e, setCount))
      : onSearch(e.target.value, e => searchLoad(e));
  }

  function searchLoad(e) {
    loadRecords({
      page: 0,
      search: e
    });
  }

  async function exportRecords(config = {}) {
    setIsLoadingExport(true);

    let params = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      orderBy: config.orderBy || orderBy,
      orderByDirection: config.orderByDirection || order,
      FilterString: config.search,
      status: config.status ? config.status.join(", ") : ""
    };

    await api.download(
      {
        url: config.isEndpointFull
          ? config.endpoint || ""
          : props.endpoint + (config.endpoint || ""),
        params
      },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    setIsLoadingExport(false);
  }

  async function loadRecords(config = {}) {
    setIsLoading(true);
    let params = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      orderBy: config.orderBy || orderBy,
      orderByDirection: config.orderByDirection || order,
      FilterString: config.search,
      Id: props.paramsId ?? null,
      ...props.paramsExtra
      // startPeriod: props.dateRange ? props.dateRange.startPeriod : null,
      // endPeriod: props.dateRange ? props.dateRange.endPeriod : null,
      // hasPeriod: Boolean(props.dateRange)
    };

    try {
      const response = await api.makeHttpRequest({
        url: props.endpoint,
        params
      });

      if (props.setCountAndFilter) {
        props.setCountAndFilter(response.total, {
          ...params,
          page: 1,
          perPage: response.total,
          status: config.status ? config.status : []
        });
      }

      setCount(response.total);
      props.setRows(response.data);
      setPage(
        params.page < response.totalPages
          ? params.page - 1 > 0
            ? params.page - 1
            : 0
          : response.totalPages - 1 > 0
          ? response.totalPages - 1
          : 0
      );
      savePaginationState(params);

      if (inputSearch !== config.search) {
        setInputSearch(config.search);
      }
    } catch (e) {
      console.log(e);

      Swal.fire(
        "Erro!",
        "Erro ao carregar os dados, tente novamente mais tarde.",
        "error"
      );
    } finally {
      setIsLoading(false);
      setReload(false);
    }
  }

  useEffect(() => {
    if (!props.cancelReload) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload]);

  useEffect(() => {
    if (Boolean(props.dateRange)) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange]);

  function loadPaginationState() {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (paginationState[props.endpoint]) {
      return paginationState[props.endpoint];
    }

    return {};
  }

  function savePaginationState(state) {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    state.page--;
    paginationState[props.endpoint] = state;

    localStorage.setItem("pagination", JSON.stringify(paginationState));
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    const direction = isDesc ? "asc" : "desc";
    setOrder(direction);
    setOrderBy(property);
    loadRecords({
      orderByDirection: direction,
      orderBy: property === "Perfil" ? "Perfil.Nome" : property,
      search: inputSearch
    });
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
    loadRecords({ page: newPage, search: inputSearch });
  }

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(+event.target.value);
    loadRecords({
      perPage: event.target.value,
      page: 0,
      search: inputSearch
    });
  }

  function refreshAction() {
    loadRecords({
      page: pageCurrentState || page,
      search: inputSearch
    });
  }

  if (reload) {
    refreshAction();
  }

  const generateMobileButtons = () => {
    return (
      <>
        {!props.hiddenReload && (
          <li className="kt-nav__item">
            <a href={void 0} className="kt-nav__link" onClick={refreshAction}>
              <i className="kt-nav__link-icon flaticon2-reload"></i>
              <span className="kt-nav__link-text">Atualizar</span>
            </a>
          </li>
        )}
        {props.buttons.length > 0
          ? props.buttons.map((btn, i) => {
              const btnOnClick = btn.endpoint
                ? () =>
                    exportRecords({
                      orderByDirection: order,
                      orderBy: orderBy,
                      search: inputSearch,
                      endpoint: btn.endpoint,
                      isEndpointFull: btn.isEndpointFull
                    })
                : btn.onClick;

              return btn.path ? (
                <Link to={btn.path} className="kt-nav__item" key={i}>
                  <span className="kt-nav__link" onClick={btnOnClick}>
                    <i
                      className={`kt-nav__link-icon flaticon-${btn.icone}`}
                    ></i>
                    <span className="kt-nav__link-text">{btn.label}</span>
                  </span>
                </Link>
              ) : (
                <li className="kt-nav__item" key={i}>
                  <a
                    href={void 0}
                    className="kt-nav__link"
                    onClick={btnOnClick}
                  >
                    <i
                      className={`kt-nav__link-icon flaticon-${btn.icone}`}
                    ></i>
                    <span className="kt-nav__link-text">{btn.label}</span>
                  </a>
                </li>
              );
            })
          : null}
      </>
    );
  };

  return (
    <div
      className={`col-xl-12 ${props.className}`}
      style={{ marginTop: "10px", minHeight: "max-content" }}
    >
      <Loading isLoading={isLoading || isLoadingExport} />
      <Portlet
        fluidHeight={true}
        style={{ backgroundColor: "transparent", boxShadow: "none" }}
      >
        {props.title && props.icon ? (
          <AdminTitle
            icon={props.icon}
            title={props.title}
            disableHeader={props.disableHeader}
            width={props.width}
            mobile={props.mobile}
            actions={props.actions}
            refreshAction={props.refreshAction}
            generateMobileButtons={generateMobileButtons}
          />
        ) : null}

        <PortletBody fit={true} fluid={true}>
          <div className={classes.root}>
            {props.formView ? props.formView : null}

            {props.placeholderSearch ? (
              <div className={classes.search}>
                <div
                  className="kt-input-icon kt-input-icon--left"
                  style={{ flex: 1, minWidth: "235px", padding: "24px 0" }}
                >
                  <input
                    style={{
                      height: "48px",
                      borderColor: "#4D4C4D",
                      fontFamily: "'Quicksand', sans-serif",
                      paddingLeft: "56px"
                    }}
                    type="text"
                    className="form-control"
                    placeholder={props.placeholderSearch}
                    onChange={search}
                    value={inputSearch}
                  />
                  <span
                    className="kt-input-icon__icon kt-input-icon__icon--left"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 8px"
                    }}
                  >
                    <RiSearchLine size="24px" color="#4D4C4D" />
                  </span>
                </div>
                {props.width >= 768 || !props.mobile ? (
                  props.buttons && props.buttons.length > 0 ? (
                    <>
                      {props.buttons.map((item, index) => (
                        <div className={classes.addBtn} key={index}>
                          <HeaderButton
                            path={item.path}
                            label={item.label}
                            onClick={
                              item.endpoint
                                ? () =>
                                    exportRecords({
                                      orderByDirection: order,
                                      orderBy: orderBy,
                                      search: inputSearch,
                                      endpoint: item.endpoint,
                                      isEndpointFull: item.isEndpointFull
                                    })
                                : item.onClick
                            }
                            icone={item.icone}
                            disabled={item.disabled}
                          />
                        </div>
                      ))}
                      {!props.hiddenReload && (
                        <RefreshButton refreshAction={refreshAction} />
                      )}
                    </>
                  ) : null
                ) : null}
              </div>
            ) : null}

            {props.editorEmail ? (
              <EditorConvertToHTML
                resetEditor={props.resetEditor}
                getEmail={(html, assunto) => props.setEmail(html, assunto)}
              />
            ) : null}

            {props.filtersSection ? props.filtersSection : null}

            <Paper className={classes.paper}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headRows={props.headRows}
                    styledTableCell={props.styledTableCell}
                  />
                  <TableBody style={{ height: "100%" }}>
                    {isLoading ? (
                      <TableRow component={StyledTableRow}>
                        <TableCell
                          colSpan={props.headRows.length}
                          className="text-center"
                          component={StyledTableCell}
                        >
                          Aguarde...
                        </TableCell>
                      </TableRow>
                    ) : count > 0 ? (
                      rows.map(row => props.formatRow(row))
                    ) : (
                      <TableRow component={StyledTableRow}>
                        <TableCell
                          colSpan={props.headRows.length}
                          className="text-center"
                          component={StyledTableCell}
                        >
                          Nenhum registro encontrado.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!props.hiddenFooterPagination ? (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 20, 50]}
                          colSpan={10}
                          count={count || 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          backIconButtonProps={{
                            "aria-label": "Anterior"
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima"
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : null}
              </div>
            </Paper>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
}

export default Datatable;
