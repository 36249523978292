import React from 'react'
import store from "../../app/store/store";
import { AiOutlineTeam, AiOutlineFileDone } from "react-icons/ai";
import { BiDonateHeart } from "react-icons/bi";
import { FaChevronCircleRight, FaRegMoneyBillAlt, FaMoneyCheck } from "react-icons/fa";

export default () => {
  const { auth } = store.getState();

  const menu = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: []
    }
  };

  let idMenuItem = [];

  if (auth?.user && auth?.user?.role?.name === "ADMIN") {
    idMenuItem.push(1, 2, 3, 4, 5);
  }

  if (idMenuItem.includes(1)) {
    menu.aside.items.push({
      title: "Usuários",
      root: true,
      icon: AiOutlineTeam,
      bullet: "dot",
      page: "admin/usuarios"
    });
  }

  if (idMenuItem.includes(2)) {
    menu.aside.items.push({
      title: "Campanhas",
      root: true,
      icon: AiOutlineFileDone,
      bullet: "none",
      page: "admin/campanhas",
      submenu: [
        {
          title: "Categorias",
          page: "admin/categorias",
          icon: () => <FaChevronCircleRight size={16} />
        }
      ]
    });
  }

  if (idMenuItem.includes(3)) {
    menu.aside.items.push({
      title: "Doações",
      root: true,
      icon: BiDonateHeart,
      bullet: "dot",
      page: "admin/doacoes"
    });
  }

  if (idMenuItem.includes(4)) {
    menu.aside.items.push({
      title: "Saques",
      root: true,
      icon: FaRegMoneyBillAlt,
      bullet: "dot",
      page: "admin/saques"
    });
  }

  if (idMenuItem.includes(5)) {
    menu.aside.items.push({
      title: "Assinaturas",
      root: true,
      icon: FaMoneyCheck,
      bullet: "dot",
      page: "admin/assinaturas"
    });
  }

  return menu;
};
